// Renderers
Handsontable.renderers.registerRenderer('PriceRenderer', function (instance, TD, col, prop, value, cellProperties) {
  Handsontable.renderers.NumericRenderer.apply(this, arguments)

  if (cellProperties) {
    TD.innerHTML = '¥' + cellProperties
  }
})

Handsontable.renderers.registerRenderer('WeightRenderer', function (instance, TD, col, prop, value, cellProperties) {
  Handsontable.renderers.NumericRenderer.apply(this, arguments)

  if (cellProperties) {
    TD.innerHTML = cellProperties + 'g'
  }
})

// Validators
Handsontable.validators.registerValidator('presence', function (value, callback) {
  if (value === null || typeof(value) == undefined) {
    callback(false)
  } else if (typeof(value) === 'string' && value.length === 0) {
    callback(false)
  } else {
    callback(true)
  }
})

Handsontable.validators.registerValidator('number', function (value, callback) {
  if (value === null || typeof(value) == undefined) {
    callback(false)
  } else if (typeof(value) === 'string' && value.length === 0) {
    callback(false)
  } else if (!Number.isNaN(parseFloat(value))){
    callback(true)
  } else {
    callback(false)
  }
})

Handsontable.validators.registerValidator('url', function (value, callback) {
  regexp =  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  if (regexp.test(value)) {
    callback(true);
  } else {
    callback(false);
  }
})
