<template>
  <div class="card mb-4">
    <div class="card-body pb-3 d-flex">
      <div class="d-flex flex-wrap">
        <oms-filter-statuses
          title="Product Request Status"
          icon="ri-ship-line"
          :statuses="productRequestStatuses"
          v-model="filters.product_request_statuses"
          color="secondary"
        />
        <oms-filter-statuses
          title="Stock destination type"
          icon="ri-ship-line"
          :statuses="stockDestinationTypes"
          v-model="filters.stock_destination_types"
          color="secondary"
        />
        <oms-filter-date-range
          title="Requested delivery at"
          icon="ri-map-pin-time-line"
          v-model="filters.requested_delivery_at"
        />
        <oms-filter-radio-buttons
          title="Paid status"
          icon="ri-links-fill"
          :options="paidStatuses"
          v-model="filters.paid_statuses"
        />
        <oms-filter-radio-buttons
          title="Payment type"
          icon="ri-links-fill"
          :options="paymentTypes"
          v-model="filters.payment_types"
        />
      </div>
      <div>
        <div
          class="form-inline mr-2 mb-2 ml-auto"
          title="Search by product name, box brand, supplier name."
        >
          <input
            class="form-control"
            v-model="filters.keyword"
            @keyup.enter="submit"
            type="text"
            placeholder="Search ..."
          />
        </div>

        <button class="btn btn-primary mr-2 mb-2" @click="submit" :disabled="isLoading || !isDirty">
          <span class="ri-search-line"></span> Search
        </button>
      </div>
    </div>

    <div class="card-body pt-0 pb-1">
      <oms-filter-content-statuses
        title="Product Request Status"
        :statuses="productRequestStatuses"
        :modelValue="filters.product_request_statuses"
        color="secondary"
      />
      <oms-filter-content-statuses
        title="Stock destination type"
        :statuses="stockDestinationTypes"
        :modelValue="filters.stock_destination_types"
        color="secondary"
      />
      <oms-filter-content-date-range
        title="Requested Delivery at"
        :modelValue="filters.requested_delivery_at"
      />
      <oms-filter-content-basic
        title="Paid status"
        default-value="all"
        :mapping="paidStatuses"
        :modelValue="filters.paid_statuses"
      />
      <oms-filter-content-basic
        title="Payment type"
        default-value="all"
        :mapping="paymentTypes"
        :modelValue="filters.payment_types"
      />
    </div>
  </div>
</template>

<script>
import buildQueryString from '../../../helpers/query-builder'
import OmsFilterStatuses from '../filter/statuses.vue'
import OmsFilterRadioButtons from '../filter/radio-buttons.vue'
import OmsFilterDateRange from '../filter/date-range.vue'
import OmsFilterContentDateRange from '../filter-content/date-range.vue'
import OmsFilterContentStatuses from '../filter-content/statuses.vue'
import OmsFilterContentBasic from '../filter-content/basic.vue'

export default {
  props: {
    initialData: Object
  },

  data() {
    this.initialData.filters = {
      ...this.initialData.filters,
      // This is 2 lines are here because the backend sends a `[]`, probably it's better to fix the backend
      paid_statuses:
        typeof this.initialData.filters?.paid_statuses == 'string'
          ? this.initialData.filters.paid_statuses
          : 'all',
      payment_types:
        typeof this.initialData.filters?.payment_types == 'string'
          ? this.initialData.filters.payment_types
          : 'all'
    }

    return {
      ...this.initialData,
      isLoading: false,
      isDirty: false
    }
  },

  computed: {
    searchUrl() {
      return `${this.baseUrl}?${this.filterQueryString}`
    },

    filterQueryString() {
      return buildQueryString(this.filters, 'q')
    }
  },

  watch: {
    filters: {
      deep: true,
      handler() {
        this.isDirty = true
      }
    }
  },

  methods: {
    submit() {
      this.isDirty = false
      this.isLoading = true

      window.location.href = this.searchUrl
    }
  },

  components: {
    OmsFilterStatuses,
    OmsFilterRadioButtons,
    OmsFilterDateRange,
    OmsFilterContentDateRange,
    OmsFilterContentStatuses,
    OmsFilterContentBasic
  }
}
</script>
