$.onmount('[data-component=addon-group-redemptions]', function () {
  if (!this.dataset.stats) {
    this.style.display = 'none'
    return
  }

  var stats = JSON.parse(this.dataset.stats)

  this.height = 300
  this.style.maxHeight = '300px'

  new Chart(this.getContext('2d'), {
    type: 'bar',
    data: {
      labels: stats.labels,
      datasets: [{
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        borderColor: 'rgb(54, 162, 235)',
        borderWidth: 1,
        data: stats.counts
      }]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: { display: false },
      scales: {
        yAxes: [{
          ticks: {
            stepSize: 1,
            beginAtZero: true
          }
        }]
      }
    }
  })
})
