<template>
  <tr :class="`bg-light-${dashedStatus}`" :data-id="mutableOrder.id">
    <!-- Checkbox -->
    <td v-show="canShipOrders">
      <div
        v-if="mutableOrder.status != 'cancelled' && mutableOrder.status != 'printing'"
        class="checkbox"
      >
        <input
          type="checkbox"
          name="orders[id][]"
          id="orders_id_"
          :value="mutableOrder.id"
          data-toggle="order-select"
        />
        <span></span>
      </div>
    </td>
    <!-- ID -->
    <td>
      <a :href="`/orders/${mutableOrder.id}`">{{ mutableOrder.id }}</a>
      <br />
      <high-risk-label :order="mutableOrder" />
      <label v-if="mutableOrder.blacklisted" class="badge badge-secondary">blacklisted</label>
    </td>
    <!-- Customer -->
    <td>
      <span class="text text-primary">
        <a target="_blank" :href="customerChargebeeLink">
          {{ mutableOrder.email }}
        </a>
      </span>
      <div>
        <u>
          <strong>{{ fullName }}</strong>
        </u>
      </div>
      <div class="address">{{ address }}</div>
    </td>
    <!-- Source -->
    <td>
      <strong>
        <u>{{ mutableOrder.source.name }}</u>
      </strong>
      <br />

      <span v-if="mutableOrder.invoice" class="text text-primary">
        <a :href="`/invoices/${mutableOrder.invoice.id}`">{{ mutableOrder.invoice_id }}</a>
      </span>

      <span v-else class="text text-primary">
        <a :href="invoiceChargebeeLink">{{ mutableOrder.invoice_id }}</a>
      </span>

      <template v-if="mutableOrder.first_invoice">
        <br />
        <span class="badge badge-secondary">1ST INVOICE</span>
      </template>

      <template v-if="mutableOrder.gift">
        <br />
        <span class="badge badge-dark">GIFT</span>
      </template>

      <template v-if="mutableOrder.addon_order_items.length > 0">
        <br />
        <span class="badge badge-info">Addon</span>
      </template>
    </td>
    <!-- Plan & Box -->
    <td>
      {{ titleize(mutableOrder.plan_id) }}
      <br />
      <small class="text-uppercase font-weight-bold">{{ mutableOrder.box }}</small>

      <template v-if="mutableOrder.printed_at">
        <br />
        <span class="ri-printer-line mr-1"></span>
        <strong class="text-success" title="Last Printed At">
          {{ formatTimestamp(mutableOrder.printed_at) }}
        </strong>
      </template>

      <template v-if="mutableOrder.latest_transaction_date">
        <br />
        <span class="ri-check-double-fill mr-1"></span>
        <strong class="text-blue" title="Transaction Date">
          {{ mutableOrder.latest_transaction_date }}
        </strong>
      </template>
    </td>
    <!-- Shippable At -->
    <td>{{ formatTimestamp(mutableOrder.shippable_at) }}</td>
    <!-- Shipping Invoice & Tags -->
    <td>
      <span class="d-flex align-items-center">
        <template v-if="mutableOrder.shipping_invoice">
          <i
            class="ri-money-dollar-circle-fill h5 mb-0 mr-1"
            :class="mutableOrder.shipping_invoice.status == 'paid' ? 'text-success' : 'text-danger'"
            :title="mutableOrder.shipping_invoice.status"
          ></i>
          {{ mutableOrder.shipping_invoice_id }}
        </template>

        <template v-else>
          <i class="ri-creative-commons-nc-line h5 mb-0 mr-1 text-muted"></i>
          No invoice
        </template>
      </span>
      <span class="tag badge badge-secondary" v-for="tag in mutableOrder.tags" :key="tag.id">
        {{ tag.name }}
      </span>
    </td>
    <!-- Status -->
    <td>
      <span class="badge" :class="`badge-${dashedStatus}`">{{ mutableOrder.status }}</span>

      <template v-if="mutableOrder.shipment_token">
        <div class="mt-1">{{ mutableOrder.shipment_token }}</div>
      </template>
    </td>
    <!-- Actions -->
    <td class="text-right font-size-md">
      <div :id="`dropdown-${mutableOrder.id}`" class="dropdown">
        <a data-toggle="dropdown" class="actionicon" href="#">
          <i class="ri-more-fill icon"></i>
        </a>
        <div class="dropdown-menu">
          <template v-if="processing_or_holding">
            <template v-if="holding">
              <a class="dropdown-item" href="#" @click.prevent.stop="updateOrder('release')">
                <i class="icon ri-stop-fill text-success"></i>
                Release for printing
              </a>
            </template>

            <a class="dropdown-item" href="#" @click.prevent.stop="updateOrder('cancel')">
              <i class="icon ri-close-circle-line text-danger"></i>
              Cancel
            </a>
          </template>

          <template v-if="!isSupport">
            <template
              v-if="
                mutableOrder.status === 'printed' ||
                mutableOrder.status === 'reshipped' ||
                mutableOrder.status === 'transacted'
              "
            >
              <div class="p-2" :class="showReturnBox ? '' : 'd-none'" @click.prevent.stop>
                <div class="form-group">
                  <label class="col-form-label">Return Box Reason</label>
                  <select class="form-control" v-model="returnReason">
                    <option value="">Select here..</option>
                    <option
                      v-for="(key, value, index) in returnReasons"
                      :key="index"
                      :value="value"
                    >
                      {{ key }}
                    </option>
                  </select>
                </div>
                <div class="return-actions">
                  <a
                    class="btn btn-light btn-sm btn-block"
                    href="#"
                    @click.prevent.stop="toggleReturnBox"
                  >
                    Back
                  </a>
                  <a
                    class="btn btn-primary btn-sm btn-block"
                    href="#"
                    @click.prevent.stop="returnBoxSave"
                  >
                    Save
                  </a>
                  <div class="clearfix"></div>
                </div>
              </div>
              <a
                class="dropdown-item"
                href="#"
                v-show="!showReturnBox"
                @click.prevent.stop="toggleReturnBox"
              >
                <i class="icon text-info ri-reply-fill"></i>
                Returned to Office
              </a>
              <div class="dropdown-divider"></div>
            </template>
          </template>

          <template v-if="!isSupport || mutableOrder.subscription">
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#" @click.prevent.stop="updateOrder('blacklist')">
              <span
                data-toggle="tooltip"
                title="NOTE: Blacklisting customer will cancel related orders from same email, and EXACT shipping address."
              >
                <i class="icon ri-subtract-fill text-danger"></i>
                Blacklist Customer
              </span>
            </a>
          </template>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import HighRiskLabel from './high-risk-label.vue'

import { mapState } from 'pinia'
import { useOrdersStore } from '../../stores/orders'

export default {
  props: {
    order: Object,
    chargebeeAdminUrl: String,
    returnReasons: Object
  },

  components: {
    HighRiskLabel
  },

  data() {
    return {
      mutableOrder: this.order,
      returnReason: '',
      showReturnBox: false,
      warning: {
        blacklist: `WARNING! You are about to *BLACKLIST* customer ${this.order.email}, any related orders to this customer will be cancelled, unshipped orders will be REFUNDED.`,
        release: `Are you sure to RELEASE order from ${this.order.email}`,
        cancel: `Are you sure to CANCEL order from ${this.order.email}`
      }
    }
  },

  computed: {
    ...mapState(useOrdersStore, ['canShipOrders', 'isSupport']),

    processing_or_holding() {
      return this.mutableOrder['processing_or_holding?']
    },

    holding() {
      return this.mutableOrder['holding?']
    },

    dashedStatus() {
      return this.mutableOrder.status.replace(/_/g, '-')
    },

    customerChargebeeLink() {
      return `${this.chargebeeAdminUrl}customers/${this.mutableOrder.remote_customer_id}`
    },

    invoiceChargebeeLink() {
      return `${this.chargebeeAdminUrl}invoices/${this.mutableOrder.invoice_id}`
    },

    hasShipmentBoxesWithTracking() {
      return this.mutableOrder.shipment_boxes.some((box) => box.tracking_number)
    },

    fullName() {
      return `${this.mutableOrder.first_name} ${this.mutableOrder.last_name}`
    },

    address() {
      return [
        this.mutableOrder.line1,
        this.mutableOrder.line2,
        this.mutableOrder.line3,
        this.mutableOrder.city,
        this.mutableOrder.state,
        this.mutableOrder.zip,
        this.mutableOrder.country,
        this.mutableOrder.phone
      ]
        .filter(Boolean)
        .join(', ')
    }
  },

  methods: {
    titleize(string) {
      return string
        .replace(/-/g, ' ')
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    },

    formatTimestamp(timestamp) {
      return timestamp.replace(/T/, ' ').replace(/.000/, ' ')
    },

    toggleReturnBox() {
      this.showReturnBox = !this.showReturnBox
    },

    closeDropdown() {
      $(`#dropdown-${this.mutableOrder.id}`).dropdown('toggle')
    },

    returnBoxSave() {
      fetch(`/orders/${this.mutableOrder.id}/return_box`, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
        },
        body: JSON.stringify({
          reason: this.returnReason
        })
      })
        .then((response) => response.json())
        .then((json) => {
          this.toggleReturnBox()
          this.closeDropdown()
          this.mutableOrder = json.order
        })
    },

    updateOrder(action_type) {
      const isAgreed = confirm(this.warning[action_type])
      if (isAgreed) {
        fetch(`/orders/${this.mutableOrder.id}/update_order`, {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
          },
          body: JSON.stringify({
            action_type: action_type
          })
        })
          .then((response) => response.json())
          .then((json) => {
            this.mutableOrder = json.order
            this.closeDropdown()
          })
      }
    }
  }
}
</script>

<style scoped>
.tag {
  margin-right: 2px;
}
</style>
