<script setup>
import { startCase } from 'lodash'
import { computed, toRefs } from 'vue'

const props = defineProps({
  order: {
    type: Object
  }
})

const { order } = toRefs(props)

const isFraud = computed(
  () => order.value.order_risks.filter((risk) => risk.fraudster_id !== null).length > 0
)

const humanizedRisks = computed(() =>
  order.value.order_risks
    .map((risk) => startCase(risk.risk))
    .filter((risk, index, array) => array.indexOf(risk) === index)
    .join(', ')
)
</script>

<template>
  <div>
    <a v-if="order.order_risks.length > 0" target="_blank" :href="`/orders/${order.id}/risks`">
      <span class="badge" :class="isFraud ? 'badge-danger' : 'badge-warning'">
        {{ isFraud ? 'High risk of fraud' : 'Suspicious' }}
      </span>
      <br />
      <small class="text-muted">{{ humanizedRisks }}</small>
    </a>
    <div v-else-if="order.card_origin && order.card_origin != order.country">
      <span class="badge badge-warning">Suspicious</span>
      <br />
      <small class="text-muted">Card country not matched</small>
    </div>
  </div>
</template>
