$.onmount('[data-toggle=shopify-clear-selected]', function () {
  var $el = $(this)

  $el.on('click', function (e) {
    e.preventDefault()
    sessionStorage.shopifyOrders = JSON.stringify([])
    $('[data-role=shopify-actions]').addClass('d-none')
    $('.orders th input[type=checkbox]').prop('checked', false)
    $('.orders tr > td:first-child input[type=checkbox]').prop('checked', false)
    $('[role=shopify-search-list] [component="shopify/order_table"] tr').removeClass('active')
  })
})
