<template>
  <oms-filter-content
    :title="title"
    :is-filtering="modelValue && Boolean(modelValue.starts_at || modelValue.ends_at)"
  >
    {{ this.startsAt() || 'Beginning of time' }} ~ {{ this.endsAt() || 'End of time' }}
  </oms-filter-content>
</template>

<script>
import OmsFilterContent from '../filter-content.vue'

export default {
  props: {
    title: String,
    modelValue: Object
  },

  components: {
    OmsFilterContent
  },

  methods: {
    startsAt() {
      if (!this.modelValue.starts_at) return

      return this.modelValue ? `${this.modelValue.starts_at} JST` : new Date()
    },
    endsAt() {
      if (!this.modelValue.ends_at) return

      return this.modelValue ? `${this.modelValue.ends_at} JST` : new Date()
    }
  }
}
</script>
