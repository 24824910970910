$.onmount(
  "[data-component='product-deliveries/remove-product-delivery-item']",
  function() {
    var $el = $(this)

    function removeProuctDeliveryItem() {
      var confirmed = confirm(this.dataset.confirmationText)
      if (!confirmed) return

      var id = $el.data().value
      var $item = $(`#existing_items_${id}_removed`)
      $item.val(1)

      $(`#product_delivery_items_${id}`).hide()
    }

    $el.on("click", removeProuctDeliveryItem)
  }
)
