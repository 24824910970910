<script setup>
import { truncate } from 'lodash'
import { toRefs } from 'vue'

const props = defineProps({
  initialData: {
    type: Object,
    required: true
  }
})

const { initialData } = toRefs(props)

const { comments, newestFirst, commentable } = initialData.value

const authenticityToken = document.querySelector('meta[name=csrf-token]')?.content
</script>

<template>
  <div>
    <div class="card d-flex">
      <h4 class="card-header">Comments</h4>
      <div class="list-group list-group-flush" :class="newestFirst ? 'order-3' : ''">
        <template v-for="comment in comments" :key="comment.id">
          <div v-if="!comment.isArchived" class="list-group-item pb-0">
            <div class="mb-2">
              <span class="font-weight-bold">{{ comment.user || '(System)' }}</span>
              <span class="text-muted">
                &mdash; {{ comment.createdAt }} ({{ comment.timeAgo }})
              </span>
            </div>
            <div class="show" :data-show="`comment-edit-body-${comment.id}`">
              <template v-if="comment.body.length < 300">
                <p class="pre-formatted" v-html="comment.body"></p>
              </template>
              <template v-else>
                <div class="collapse show" :data-show="`comment-body-${comment.id}`">
                  <p class="pre-formatted">{{ truncate(comment.body, { length: 300 }) }}</p>
                  <a
                    data-toggle="collapse"
                    class="d-block mb-4"
                    :href="`*[data-show=comment-body-${comment.id}]`"
                  >
                    View full
                  </a>
                </div>
                <div class="collapse" :data-show="`comment-body-${comment.id}`">
                  <p class="pre-formatted" v-html="comment.body"></p>
                </div>
              </template>
            </div>
            <template v-if="comment.isCurrentUser">
              <div class="collapse" :data-show="`comment-edit-body-${comment.id}`">
                <form :action="comment.url.edit" accept-charset="UTF-8" method="post">
                  <input type="hidden" name="_method" value="put" /><input
                    type="hidden"
                    name="authenticity_token"
                    :value="authenticityToken"
                  />
                  <textarea class="form-control" rows="5" name="comment[body]" id="comment_body">{{
                    comment.body
                  }}</textarea>
                  <input
                    type="submit"
                    name="commit"
                    value="Save"
                    class="btn btn-primary mt-3"
                    data-disable-with="Save"
                  />
                  <a
                    data-toggle="collapse"
                    class="btn btn-outline-secondary ml-2 mt-3"
                    :href="`*[data-show=comment-edit-body-${comment.id}]`"
                    aria-expanded="true"
                  >
                    Cancel
                  </a>
                </form>
              </div>
              <div class="d-flex justify-content-end mb-3">
                <a
                  class="btn btn-sm btn-outline-secondary"
                  data-confirm="Are you sure you want to archive this comment?"
                  rel="nofollow"
                  data-method="put"
                  :href="comment.url.archive"
                >
                  Archive
                </a>
                <a
                  data-toggle="collapse"
                  class="btn btn-sm btn-outline-primary ml-2"
                  :href="`*[data-show=comment-edit-body-${comment.id}]`"
                >
                  Edit
                </a>
              </div>
            </template>
          </div>
        </template>
      </div>
      <div class="card-body">
        <form
          class="new_comment"
          id="new_comment"
          action="/comments"
          accept-charset="UTF-8"
          method="post"
        >
          <input type="hidden" name="authenticity_token" :value="authenticityToken" />
          <input
            type="hidden"
            :value="commentable.id"
            name="comment[commentable_id]"
            id="comment_commentable_id"
          />
          <input
            type="hidden"
            :value="commentable.type"
            name="comment[commentable_type]"
            id="comment_commentable_type"
          />
          <div class="form-group">
            <label for="comment_body">Comment</label>
            <textarea class="form-control" name="comment[body]" id="comment_body"></textarea>
          </div>
          <input
            type="submit"
            name="commit"
            value="Add comment"
            class="btn btn-primary"
            data-disable-with="Add comment"
          />
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>
.pre-formatted {
  white-space: pre-wrap;
}
</style>
