import {createApp} from 'vue'
import ProductRequestNew from './product-request-new.vue'
import i18nPlugin, {i18n, loadTranslations} from "./i18n";
import {createPinia} from "pinia";

$.onmount('[data-vue=product-request-new]', async function () {
    const app = createApp(ProductRequestNew)
    const pinia = createPinia()
    app.config.unwrapInjectedRef = true
    app.use(pinia)
    app.use(i18nPlugin)
    // Mount app after translation loaded.
    await loadTranslations(i18n)
    app.mount(this)
})
