import selectedOrderIds from "javascripts/stores/orders/selected-ids"

$.onmount('[data-toggle=order-table]', function () {
  var $table = $(this)
  var url = this.dataset.url

  $table.on('change', '[data-toggle=order-select]', function (e) {
    var $el = $(this)
    var $print = $('[data-toggle=print-orders]')
    var id = $el.val()

    // Push / Remove id
    if ($el.is(':checked')) {
      addOrder(id)
    } else {
      removeOrder(id)
    }

    // Button Show
    if (selectedOrderIds.size() > 0 || $print.parents('.dropdown-menu').find('li').length > 1) {
      $print.parents('.dropdown').removeClass('d-none')
    } else {
      $print.parents('.dropdown').addClass('d-none')
    }

    // data
    $print.find('.count').text(selectedOrderIds.size())
    $print.attr('href', function () {
      return $(this).attr('data-url') + '&' + jQuery.param({ ids: selectedOrderIds.toArray() })
    })
  })

  var addOrder = function (id) {
    if (id === 'all') {
      toggleTable(true)
    } else {
      selectedOrderIds.add(id)
    }
  }

  var removeOrder = function (id) {
    if (id === 'all') {
      toggleTable(false)
    } else {
      selectedOrderIds.remove(id)
    }
  }

  var toggleTable = function (isChecked) {
    var elements = $('[role=search-list]').find('td [data-toggle=order-select]')
    var ids = _.map(elements, function (el) { return el.value } )

    _.each(elements, function (el) {
      $(el).prop('checked', isChecked)
    })

    if (isChecked) {
      ids.forEach(id => selectedOrderIds.add(id))
    } else {
      ids.forEach(id => selectedOrderIds.remove(id))
    }
  }

  $table.on('click', '[data-toggle=update-order]', function (e) {
    e.preventDefault()
    var isAgreed = confirm(this.dataset.warning)

    if (isAgreed) {
      update(this.dataset.type, [this.dataset.id])
    }
  })

  // Utility function
  var update = function (actionType, orderIds) {
    $.ajax({
      url: url,
      method: 'PUT',
      data: {
        order_ids: orderIds,
        action_type: actionType
      },
      success: onSuccess
    })
  }

  var onSuccess = function (orders) {
    var $el = $('[data-toggle=order-table]')

    _.each(orders, function (order, i) {
      const $html = $(order.html).css({ opacity: 0.1 })
      $el.find('tr[data-id=' + order.id + ']').replaceWith($html)
      $html.animate({ opacity: 1 })
    })
  }
})
