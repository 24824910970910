function updateFieldCountText() {
  var fields = $('[data-field]')
  if(fields.length != 0) {
    fields.each(function(index) {
      var fieldName = $(this).data('field')
      var fieldPosition = index + 1
      $(this).text(fieldName + ' ' + fieldPosition)
    })
  }
}

$('body').on('cocoon:after-insert', function (e, element) {
  $.onmount()
  updateFieldCountText()
})

$('body').on('cocoon:after-remove', function (e, element) {
  $.onmount()
  updateFieldCountText()
})
