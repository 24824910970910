<template>
  <div>
    <multiselect v-model="selectedStockDestination" track-by="id" label="description" :preselect-first="true"
                 :show-labels="false" :searchable="true" :internal-search="true" :allow-empty="false"
                 :options="stockDestinations"
                 :loading="isLoading" @select="handleStockDestinationSelect">
    </multiselect>
    <div v-html="status"></div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import {omsCsrfHeader} from "../../fetch-utils";

export default {
  components: {Multiselect},

  props: {
    productRequestItem: {
      required: true,
      type: Object
    },
    productSupplierId: {
      required: false,
      type: Number
    },
    productVariantId: {
      required: false,
      type: Number
    },
    stockDestinationId: {
      required: false,
      type: [String, Number]
    },
    stockDestinationType: {
      required: false,
      type: String
    },
	  boxId: {
      required: false,
      type: Number
    }
  },

  computed: {},

  data() {
    return {
      selectedStockDestination: null,
      stockDestinations: this.stockDestinationId
        ? [
          {
            id: this.stockDestinationId,
            type: this.stockDestinationType,
            description: this.stockDestinationType
          }
        ]
        : [],
      isLoading: false,
      status: ''
    }
  },

  mounted() {
    this.fetchStockDestinations()
  },

  watch: {
    productSupplierId: function () {
      this.fetchStockDestinations()
    },
    productVariantId: function () {
      this.fetchStockDestinations()
    }
  },

  methods: {
    async fetchStockDestinations() {
      if (this.productSupplierId) {
        this.isLoading = true
        try {
          const response = await fetch(
            `/product_requests/stock_destinations?${new URLSearchParams(
              this.productVariantId
                ? {
                  product_supplier_id: this.productSupplierId,
                  product_variant_id: this.productVariantId,
				  box_id: this.boxId
                }
                : {
					product_supplier_id: this.productSupplierId,
					box_id: this.boxId
                }
            )}`,
            {
              headers: omsCsrfHeader()
            }
          )
          const stockDestinations = await response.json()
          this.stockDestinations = stockDestinations.map(this.splitResultForSelect)
          this.selectStockDestination()
        } catch (err) {
          console.error(err)
        } finally {
          this.isLoading = false
        }
      }
    },
    selectStockDestination() {
      let selectedStockDestination
      if (!this.stockDestinationId) {
        selectedStockDestination = this.stockDestinations[0] ?
          this.stockDestinations[0] : null
      } else {
        selectedStockDestination = this.stockDestinations.find(dest =>
          dest.id === this.stockDestinationId.toString())
      }
      this.selectedStockDestination = selectedStockDestination
      this.handleStockDestinationSelect(selectedStockDestination)
    },
    handleStockDestinationSelect(selectedStockDestination) {
      this.productRequestItem.stock_destination_id = selectedStockDestination?.id
      this.productRequestItem.stock_destination_type = selectedStockDestination?.type

      if (selectedStockDestination) {
        this.analyzeStockDestination(selectedStockDestination)
      }
    },
    async analyzeStockDestination(stockDestination) {
      this.isLoading = true
      try {
        const response = await fetch(
          `/product_requests/analyze_stock_destination?${new URLSearchParams({
            stock_destination_type: stockDestination.type,
            stock_destination_id: stockDestination.id
          })}`,
          {
            headers: omsCsrfHeader()
          }
        )
        const stockStatus = await response.json()
        this.status = stockStatus.html
      } catch (err) {
        console.error(err)
      } finally {
        this.isLoading = false
      }
    },
    splitResultForSelect(result) {
      return {
        id: result.id.split('#')[1],
        type: result.id.split('#')[0],
        description: result.description
      }
    }
  }
}
</script>
