$.onmount('[data-component=supplier-select]', function() {
  var $el = $(this);

  function onLoad(query, callback) {
    $.ajax({
      url: '/product_requests/search_suppliers',
      dataType: 'JSON',
      data: {
        keyword: query,
      },
      success: callback,
      error: callback,
    });
  }

  function onChange(value) {
    $('[data-component=insert-product]').attr('disabled', !value);
  }

  $el.selectize({
    load: onLoad,
    valueField: 'id',
    labelField: 'company_name',
    searchField: ['company_name'],
    options: [],
    create: false,
    preload: 'focus',
    placeholder: 'Search suppliers here..',
    allowEmptyOption: false,
    onChange: onChange,
    onInitialize: function() {
      if ($('[data-component=product-row]').length > 0) {
        $el[0].selectize.lock();
      } else {
        $el[0].selectize.unlock();
      }
    },
  });

  var preload = $el.data('preload');

  if (preload) {
    $el[0].selectize.addOption(preload);
    $el[0].selectize.setValue(preload.id);
  }
});
