<template>
  <div class="row p-2 m-2 align-items-center" :class="backGroundColor">
    <div class="col-sm-6">
      <label class="m-0">{{ t('features.product_request.planned_batch_items.attach_batch') }}</label>
      <multiselect v-model="plannedBatchItem.delivery_plan_batch" track-by="tmp_id" :show-labels="false"
                   :custom-label="batchLabel" :options="batches" :searchable="true" :internal-search="true"
                   :allow-empty="false"
                   :hide-selected="false" :disabled="!canChangeBatch">
      </multiselect>
      <h6 class="mt-2">
        {{
          t('features.product_request.planned_batch_items.will_be_delivered', {date_diff: expectedDeliveredAtDistance})
        }}
      </h6>
    </div>
    <div class="col-sm-5">
      <label>{{ t('activerecord.attributes.planned_batch_item.quantity') }}</label>
      <input class="form-control" v-model.number="plannedBatchItem.quantity" type="number" />
      <h6 class="mt-2">
        {{
          t('features.product_request.planned_batch_items.proportion_of_total',
            {percentage: Math.round((plannedBatchItem.quantity / totalQuantity) * 100).toString() + '%'})
        }}
      </h6>
      <tooltip :tooltip-text="validationErrors['quantity']" :hidden="!validationErrors['quantity']">
        <i class="ri-error-warning-fill text-danger" v-if="validationErrors['quantity']"></i>
      </tooltip>
    </div>
    <div class="col-sm-1">
      <button class="btn btn-light text-danger" @click.prevent="deletePlannedBatchItem(index, plannedBatchItem)">
        <i class="ri-close-line" />
      </button>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import {format, formatDistanceToNow} from 'date-fns'
import {mapStores} from "pinia";
import {useProductRequestStore} from "../../../stores/productRequestStore";
import Tooltip from '../../tooltip'

export default {
  inject: ['i18n'],
  components: {Tooltip, Multiselect},
  data() {
    return {
      t: (key, options) => this.i18n.t(key, options),
    }
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    batches: {
      type: Array,
      required: true
    },
    plannedBatchItem: {
      type: Object,
      required: false
    },
    totalQuantity: {
      type: Number,
      required: true
    },
    deletePlannedBatchItem: {
      type: Function,
      required: true
    },
    errors: {
      type: Object,
      required: false
    }
  },
  methods: {
    batchLabel(batch) {
      return `Batch (${format(new Date(batch.expected_delivery_at), "yyyy-MM-dd HH:mm")})`
    }
  },
  computed: {
    ...mapStores(useProductRequestStore, ['batchItemValidationErrors']),
    quantity() {
      return this.plannedBatchItem.quantity
    },
    expectedDeliveredAtDistance() {
      return formatDistanceToNow(new Date(this.plannedBatchItem.delivery_plan_batch.expected_delivery_at), {addSuffix: true})
    },
    canChangeBatch() {
      return !this.plannedBatchItem.id
    },
    isPersisted() {
      return !!this.plannedBatchItem.id
    },
    backGroundColor() {
      return this.isPersisted ? 'bg-light-blue-gray' : 'bg-lighter-gray'
    },
    validationErrors() {
      return this.productRequestStore.batchItemValidationErrors(this.errors, this.index)
    }
  }
}
</script>

<style lang="scss" scoped>
.bg-light-blue-gray {
  background-color: #dbeff0
}

.bg-lighter-gray {
  background-color: #e9ecef
}
</style>