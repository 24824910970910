<template>
  <oms-filter-dropdown :title='title' :icon='icon'
    :is-filtering='modelValue && (modelValue.include?.length > 0 || modelValue.exclude?.length > 0)'>
    <h6>Include</h6>
    <multiselect v-model='includedOptions' class='multiselect--inline mb-4' track-by='id' label='name' :multiple='true'
      :options='options' :searchable='true' :loading='isLoading' :internal-search='false' :clear-on-select='false'
      :show-no-results='false' :show-labels='false' @search-change='fetchOptions' />

    <h6>Exclude</h6>
    <multiselect v-model='excludedOptions' class='multiselect--inline' track-by='id' label='name' :multiple='true'
      :options='options' :searchable='true' :loading='isLoading' :internal-search='false' :clear-on-select='false'
      :show-no-results='false' :show-labels='false' @search-change='fetchOptions' />
  </oms-filter-dropdown>
</template>

<script>
import Multiselect from 'vue-multiselect'
import OmsFilterDropdown from '../filter-dropdown.vue'

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: 'ri-links-fill'
    },
    fetch: {
      type: Object,
      required: true,
      validator(modelValue) {
        return modelValue.url && modelValue.responseField
      }
    },
    modelValue: Object
  },

  data() {
    return {
      options: [],
      isLoading: false
    }
  },

  computed: {
    includedOptions: {
      get() {
        return (this.modelValue && this.modelValue.include) || []
      },

      set(options) {
        this.$emit('update:modelValue', { ...(this.modelValue || {}), include: options.map(({ id, name }) => ({ id, name })) })
      }
    },

    excludedOptions: {
      get() {
        return (this.modelValue && this.modelValue.exclude) || []
      },

      set(options) {
        this.$emit('update:modelValue', { ...(this.modelValue || {}), exclude: options.map(({ id, name }) => ({ id, name })) })
      }
    }
  },

  methods: {
    fetchOptions(search) {
      this.isLoading = true

      fetch(`${this.fetch.url}?keyword=${encodeURIComponent(search)}`)
        .then(response => response.json())
        .then(json => {
          this.options = json[this.fetch.responseField]
          this.isLoading = false
        })
    }
  },

  components: {
    OmsFilterDropdown,
    Multiselect
  }
}

</script>
