import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['applyFromContainer', 'applyImmediatelyCheckbox']

  toggleApplyImmediately() {
    this.applyFromContainerTarget.classList.toggle(
      'text-muted',
      this.applyImmediatelyCheckboxTarget.checked
    )
    this.applyFromContainerTarget.querySelector('input').disabled =
      this.applyImmediatelyCheckboxTarget.checked
  }
}
