<template>
  <div class="dropdown mr-2 mb-2 flex-1">
    <button class="btn d-flex align-items-center w-100 px-2 dropdown-toggle" :class="buttonClass"
      data-toggle="dropdown">
      <span class="mr-1" :class="{ [icon]: true, 'text-primary': isFiltering }"></span>
      <span class="mr-auto" :class="{ 'font-weight-bold': isFiltering }">{{ title }}</span>
    </button>
    <div class="dropdown-menu" :style='{ width }'>
      <form class="px-3 py-2">
        <slot></slot>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    icon: String,
    isFiltering: Boolean,
    width: {
      type: String,
      default: '300px'
    }
  },

  computed: {
    buttonClass() {
      return {
        'btn-light': !this.isFiltering,
        'btn-dark': this.isFiltering
      }
    }
  }
}
</script>
