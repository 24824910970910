import { Controller } from '@hotwired/stimulus'
import { reverse } from 'lodash'

export default class extends Controller {
  static targets = ["checkbox", "selectAllCheckbox", "csvTextArea"]

  toggleSelectAll() {
    const newState = !this._allSelected()

    this.checkboxTargets.forEach(target => {
      target.checked = newState
    })

    this.selectAllCheckboxTarget.checked = newState
  }

  checkboxChanged() {
    this.selectAllCheckboxTarget.checked = this._allSelected()
  }

  copyCsv(event) {
    this.csvTextAreaTarget.value = reverse(this.checkboxTargets).
      filter(target => target.checked).
      map(target => target.dataset.csvRow).
      join('\n')

    this.csvTextAreaTarget.select()

    document.execCommand('copy')

    this._flashButtonText(event.target, 'Copied!')
  }

  _allSelected() {
    return this.checkboxTargets.every(target => target.checked)
  }

  _flashButtonText(button, text) {
    const oldContent = button.innerHTML

    button.innerText = text

    setTimeout(() => button.innerHTML = oldContent, 2000)
  }
}
