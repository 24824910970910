$.onmount('[data-component=fill-product-request-items]', function() {
  $(document).on('change', '[data-component=supplier-select]', function(e) {
    var supplierId = $(this).val();
    $.ajax({
      url: `/suppliers/${supplierId}/boxes`,
      complete: function() {
        $.onmount()
      }
    });
  });

  $(document).on('click', '[data-component=fill-products-btn]', function(e) {
    var boxId = $('[data-component=fill-product-request-items]').find('select').val();
    var formData = $('#product-request-form').find('form').serialize().replace('&_method=patch&', '&');
    $.post({
      url: `/boxes/${boxId}/fill_product_request_items`,
      data: formData,
      success: function() {
        $.onmount()
      }
    });
  });
});
