import { Controller } from '@hotwired/stimulus'
import { omsCsrfHeader } from '../vue/fetch-utils'
import $bus from '../packs/event'

export default class extends Controller {
  static targets = ["checkbox", "selectAllCheckbox"]

  initialize() {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.addEventListener('change', () => {
        $bus.trigger('checkboxChanged', { id: checkbox.id, checked: checkbox.checked });
      });
    });
  }

  toggleSelectAll() {
    const newState = !this._allSelected()

    this.checkboxTargets.forEach(target => {
      target.checked = newState
      $bus.trigger('checkboxChanged', { id: target.id, checked: target.checked });
    })

    this.selectAllCheckboxTarget.checked = newState
  }

  checkboxChanged() {
    this.selectAllCheckboxTarget.checked = this._allSelected()
  }

  async deleteOrder(e) {
    const ids = this.checkboxTargets.filter(target => target.checked).map(element => element.id);
    if(!ids.length)return
    document.getElementById("remove-order-button").click()
  }

  async doDeleteOrder(e) {
    const ids = this.checkboxTargets.filter(target => target.checked).map(element => element.id);

    if(!ids.length)return

    const params = JSON.stringify({
      ids,
      shipment_name: e.target.getAttribute('data-shipment-name')
    });

    await fetch("/shipments/delete_orders", {method: 'POST', body: params, headers: omsCsrfHeader()})
    .then(response => response.json())
    .then(result => result?.error ? alert(result.error) : window.location.href = result?.redirect);
  }

  _allSelected() {
    return this.checkboxTargets.every(target => target.checked)
  }
}
