<template>
  <div class="info">
    <template v-if="total_count === 0">No orders found</template>
    <template v-else>
      Displaying <strong>{{ isLessThanLimit ? `all ${this.total_count}` : '' }}</strong> orders
      <template v-if="!isLessThanLimit">
        <strong>
          {{ this.from }} -
          {{ this.last_page ? this.total_count : this.to }}
        </strong>
        of
        <strong>{{ this.total_count }}</strong> in total
      </template>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    initialData: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      total_count: 0,
      current_page: 1,
      per_page: 25,
      last_page: false,
      from: 0,
      to: 0,
      ...this.initialData
    }
  },

  watch: {
    initialData: {
      handler(newVal) {
        Object.keys(newVal).forEach((key) => {
          this[key] = newVal[key]
        })
      },
      deep: true
    }
  },

  computed: {
    isLessThanLimit() {
      return this.total_count < this.per_page
    }
  }
}
</script>
