$.onmount('[data-component=product-request-form]', function() {
  $el = $(this);

  function toggleSupplierDropdown() {
    var $dropdown = $('#product_request_supplier_id');

    if ($el.find('[data-component=product-row]').length > 0) {
      $dropdown[0].selectize.lock();
    } else {
      $dropdown[0].selectize.unlock();
    }

    $.onmount();
  }

  $el.on('cocoon:after-insert', toggleSupplierDropdown);
  $el.on('cocoon:after-remove', toggleSupplierDropdown);
});
