$.onmount("[data-component=product-select-all]", function() {
  var $el = $(this)

  function onLoad(query, callback) {
    $.ajax({
      url: "/products/search_products_to_select",
      dataType: "JSON",
      data: {
        keyword: query
      },
      success: callback,
      error: callback
    })
  }

  $el.selectize({
    load: onLoad,
    valueField: "id",
    labelField: "name",
    searchField: ["name", "barcode", "sku"],
    options: [],
    create: false,
    preload: "focus",
    placeholder: "Search products here.."
  })

  var preload = $el.data("preload")
  var productId = $el.data('product-id')

  if (preload) {
    $el[0].selectize.addOption(preload)
  }
  if (productId)
    $el[0].selectize.setValue(productId, true)
})
