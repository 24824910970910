$.onmount('[data-component=product-variant-select]', function() {
  var $el = $(this)
  var $productSupplierId = $el.closest('[data-component=product-row]').find('[name*=product_supplier_id]')
  var $variantField = $el.closest('[data-component=product-row]').find('[name*=product_variant_id]')

  function setOptions(data) {
    $el[0].selectize.clearOptions()
    $el[0].selectize.addOption(data)

    if (data.length <= 1) {
      $el[0].selectize.setValue(data[0] && data[0].id)
      $el[0].selectize.disable()
    } else {
      $el[0].selectize.enable()
    }
  }

  function reloadOptions() {
    $.ajax({
      url: '/product_requests/product_variants',
      dataType: 'JSON',
      data: {
        product_supplier_id: $productSupplierId.val(),
      },
      success: setOptions
    })
  }

  $el.selectize({
    valueField: 'id',
    labelField: 'description',
    options: []
  })

  $el[0].selectize.on('change', function (id) {
    $variantField.val(id).trigger('change')
  })

  $productSupplierId.on('change', reloadOptions)
  reloadOptions()

  var preload = $el.data('preload')

  if (preload) {
    $el[0].selectize.addOption(preload)
    $el[0].selectize.setValue(preload.id, true)
  }
})
