// TODO: DEV-3362 Remove the order shippment deprecating warning sign after people are fully onboard
$.onmount('[data-component=order-shipment-deprecation]', function() {
  var $el = $(this)

  $el.find('[data-action=ignore]').on('click', function() {
    $el
      .parent()
      .find('section')
      .removeClass('gray-out')
    $el.hide()
  })
})
