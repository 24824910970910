import {I18n} from "i18n-js"
import en from "i18n-js/json/en.json"
import jp from "i18n-js/json/ja.json"
import {omsCsrfHeader} from "./fetch-utils";

export const i18n = new I18n({
    ...en,
    ...jp
});

export async function loadTranslations(i18n) {
    const translations = await fetch('/translations', {
        headers: omsCsrfHeader()
    })
    let locale = await fetch('/translations/locale', {
        headers: omsCsrfHeader()
    })
    locale = await locale.json()

    i18n.store(await translations.json())
    i18n.locale = locale.locale
}

export default {
    install: (app, options) => {
        i18n.defaultLocale = "en"
        i18n.enableFallback = true
        app.provide('i18n', i18n)
    }
}
