$.onmount('[role=shopify-order-search]', function () {
  sessionStorage.shopifyOrders = JSON.stringify([])

  // Some browsers will save the checked state between page refreshes; we want to reset it
  $('[data-toggle=shopify-order-select]').prop('checked', false)

  var orderIds = function () {
    return JSON.parse(sessionStorage.shopifyOrders)
  }

  $('body').on('click', '[data-toggle=shopify-order-row-select] a', function (e) {
    e.stopPropagation()
  })

  $('body').on('click', '[data-toggle=shopify-order-row-select] a[data-toggle=dropdown]', function (e) {
    e.stopPropagation()
    e.preventDefault()
    $(this).dropdown('toggle')
  })

  $('body').on('click', '[data-toggle=shopify-order-row-select]', function (e) {
    e.stopPropagation()
    var $el = $(this)
    $el.find('input[type=checkbox]').click()
  })

  $('body').on('click', 'table .checkbox', function (e) {
    e.stopPropagation()
  })

  $('body').on('change', '[data-toggle=shopify-order-select]', function (e) {
    var $el = $(this)
    var id = $el.val()
    var orders = orderIds()

    // Push / Remove id
    if ($el.is(':checked')) {
      orders = pushOrder(orders, id)
    } else {
      orders = removeOrder(orders, id)
    }

    sessionStorage.shopifyOrders = JSON.stringify(orders)
    refresh(orders)
  })

  $('body').on('shopify:order-cancelled', function (event, id) {
    var orders = removeOrder(orderIds(), id.toString())

    sessionStorage.shopifyOrders = JSON.stringify(orders)
    refresh(orders)
  })

  var pushOrder = function (orders, id) {
    if (id === 'all') {
      return toggleTable(orders, true)
    } else {
      orders.push(id)
      return orders
    }
  }

  var removeOrder = function (orders, id) {
    if (id === 'all') {
      return toggleTable(orders, false)
    } else {
      return _.reject(orders, function (i) { return i === id })
    }
  }

  var toggleTable = function (orders, isIncluded) {
    var elements = $('[role=shopify-search-list]').find('td [data-toggle=shopify-order-select]')
    var ids = _.map(elements, function (el) { return el.value } )

    _.each(elements, function (el) {
      $(el).prop('checked', isIncluded)
    })

    if (isIncluded) {
      return _.union(orders, ids)
    } else {
      return _.difference(orders, ids)
    }
  }

  var refresh = function (orders) {
    if (orders.length > 0) {
      $('[data-role=shopify-actions]').removeClass('d-none')
    } else {
      $('[data-role=shopify-actions]').addClass('d-none')
    }

    $('[data-role=shopify-actions] [role=shopify-orders-count]').text(orders.length)
    $('[data-component=shopify-perform-action]').attr('disabled', orders.length > $('[data-role=shopify-actions]').data('max-selected'))

    var link = $('[data-role=shopify-actions] [data-component=shopify-perform-action]')
    link.attr('href', link.data('url') + '?ids=' + orders.toString())

    $('[role=shopify-search-list]').find('[data-component="shopify/order_table"] tbody tr').each(function (i, el) {
      if (orders.indexOf(el.dataset.id) !== -1) {
        $(el).addClass('active')
      } else {
        $(el).removeClass('active')
      }
    })
  }
})
