window.Charts = window.Charts || {};

Charts.render = function ($el, options) {
  var el = $($el)[0]
  el.height = 300
  el.style.maxHeight = '300px'
  var doc = el.getContext('2d')

  var labels = []
  _.each(options.labels, function (item) {
    item = new Date(item * 1000)
    labels.push(item)
  })

  var config = {
    type: options.type || 'bar',
    data: {
      labels: labels,
      datasets: options.series
    },
    options: {
      legend: options.legend || false,
      title: options.title,
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          stacked: options.stacked || false,
          type: 'time',
          time: {
            unit: (labels.length > 150 ? 'month' : 'day')
          },
          offset: true,
        }],
        yAxes: [{
          stacked: options.stacked || false,
          ticks: {
            beginAtZero: true
          }
        }]
      },
      tooltips: {
        mode: options.tooltips && options.tooltips.mode || 'index',
        intersect: options.tooltips && options.tooltips.intersect,
        position: 'average',
        titleFontSize: 14,
        bodyFontSize: 14,
        bodySpacing: 7,
        footerFontSize: 14,
        titleMarginBottom: 15,
        callbacks: {
          title: function(items, data) {
            let total = 0;
            for (let i = 0; i < items.length; i++) {
                total += parseInt(items[i].yLabel, 10);
            }

            return moment(items[0].xLabel).format('MMM DD, YYYY') + "    Total: " + total
          }
        },

      }
    }
  }

  return new Chart(doc, config)
}
