import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['shipmentComponentSelectDropdown', 'shipmentRow']

  connect() {
    this.initializeSelectize()
  }

  removeRow(event) {
    event.preventDefault()
    this.shipmentRowTarget.parentNode.removeChild(this.shipmentRowTarget)
  }

  initializeSelectize() {
    const $el = $(this.shipmentComponentSelectDropdownTarget)
    $el.selectize({
      valueField: 'id',
      labelField: 'name',
      searchField: ['name'],
      options: [],
      create: false,
      preload: 'focus',
      placeholder: 'Search Shipments Here'
    });

    const preload = $el.data('preload');

    if (preload) {
      $el[0].selectize.addOption(preload);
      $el[0].selectize.setValue(preload.id, true);
    }
  }
}
