$.onmount('[data-component=csv-upload]', function () {
    var $form = $(this)
    var $filePreviewWrapper = $form.find('[data-component=file-preview]')
    var $filePreview = $filePreviewWrapper.find('code')
    var $uploadButton = $form.find('button[type=submit]')

    var previewContents = function (file) {
        $filePreviewWrapper.removeClass('d-none')
        $filePreview.text('Loading ...')

        var reader = new FileReader()
        reader.readAsText(file, 'SJIS')

        reader.onload = function (event) {
            $filePreview.text(event.target.result)
            $uploadButton.attr('disabled', false)
        }

        reader.onerror = function (event) {
            console.log(event)
            $filePreview.text('Error reading file. Check the logs for more details.')
        }
    }

    var hidePreview = function () {
        $filePreviewWrapper.addClass('d-none')
        $uploadButton.attr('disabled', true)
    }

    $form.find('input[name*="csv_file"]').on('change', function () {
        console.log('change!')
        var file = this.files[0]

        if (file) {
            previewContents(file)
        } else {
            hidePreview()
        }
    })
})
