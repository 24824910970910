<template>
  <oms-filter-content title='Plan' :is-filtering='isFilteringTypes || isFilteringPeriods'>
    <div class='mr-2' v-show='isFilteringTypes'>
      <span v-for='planType in selectedPlanTypes?.include' class='badge badge-info position-static mr-1'>
        {{ planTypes[planType] }}
      </span>
    </div>

    <div v-show='isFilteringPeriods'>
      <span v-for='duration in selectedPlanPeriods?.include' class='badge badge-secondary position-static mr-1'>
        {{ planPeriods[duration] }}
      </span>
    </div>
  </oms-filter-content>
</template>

<script>
import OmsFilterContent from '../filter-content.vue'

export default {
  props: {
    planTypes: Object,
    planPeriods: Object,
    selectedPlanTypes: Object,
    selectedPlanPeriods: Object
  },

  computed: {
    isFilteringTypes() {
      return this.selectedPlanTypes?.include?.length > 0
    },

    isFilteringPeriods() {
      return this.selectedPlanPeriods?.include?.length > 0
    }
  },

  components: {
    OmsFilterContent
  }
}
</script>
