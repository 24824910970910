import ProductInvoicesNew from '../../views/product-invoices/new.vue'
import i18nPlugin, { i18n, loadTranslations } from '../../i18n'
import { createApp } from 'vue'
import { createPinia } from 'pinia'

$.onmount('[data-vue=new-product-invoice]', async function () {
  const initialData = JSON.parse(this.attributes['data-initial-data'].value)

  const pinia = createPinia()
  const app = createApp(ProductInvoicesNew, { initialData })

  app.use(i18nPlugin)
  app.use(pinia)

  await loadTranslations(i18n)

  app.mount(this)
})
