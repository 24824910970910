$.onmount('[data-toggle=scroll-to]', function () {
  var $el =$(this)

  var to = $el.attr('href')

  $el.on('click', function (e) {
    e.preventDefault()
    var offset = $(this).data('offset') || 0

    $('html, body').animate({
      scrollTop: $(to).offset().top - 30 - offset
    }, 300)
  })
})
