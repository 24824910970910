import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["grafanaEmbed", "fromInput", "toInput", "searchButton", "grafanaLink"]

  static values = {
    baseUrl: String,
    dashboardPath: String,
    username: String,
    password: String
  }

  connect() {
    this.authenticateToGrafana().then(() => this.refreshGraph())
  }

  refreshGraph(event) {
    if (event) {
      event.preventDefault()
    }

    this.searchButtonTarget.setAttribute('disabled', 'disabled')
    this.searchButtonTarget.setAttribute('value', 'Loading ...')

    this.grafanaEmbedTarget.src = `${this.dashboardUrl}&theme=light&kiosk`
    this.grafanaLinkTarget.href = this.dashboardUrl
  }

  doneRefreshing() {
    this.searchButtonTarget.removeAttribute('disabled')
    this.searchButtonTarget.setAttribute('value', 'Search')
  }

  get dashboardUrl() {
    return `${this.baseUrlValue}${this.dashboardPathValue}?orgId=1&from=${this.fromParam}&to=${this.toParam}`
  }

  get fromParam() {
    return this.toJstDate(this.fromInputTarget.value).getTime()
  }

  get toParam() {
    return this.toJstDate(this.toInputTarget.value).getTime()
  }

  toJstDate(datePickerString) {
    return new Date(datePickerString.replace(' ', 'T') + ':00+09:00')
  }

  async authenticateToGrafana() {
    await fetch(`${this.baseUrlValue}/api/user`, { credentials: 'include'  }).then((response) =>
      response.status === 401 && this.attemptLogin()
    ).catch(() => this.attemptLogin())
  }

  async attemptLogin() {
    // If we're not logged in the API returns a CORS error. We can't be certain this is what we caught, but let's assume ...
    await fetch(`${this.baseUrlValue}/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json'  },
      body: JSON.stringify({ "User" :this.usernameValue, "Password" :this.passwordValue }),
      credentials: 'include'
    })
  }
}
