$.onmount('[data-toggle=add-addon-order-item]', function () {
  var $el = $(this)

  $el.on('click', function (e) {
    e.preventDefault()
    $template = $el.data('template')

    $('[role=addon-order-items]').append($template)
    $.onmount()
  })
})
