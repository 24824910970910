import {createApp} from 'vue'
import ProductRequest from './product-request-update.vue'
import i18nPlugin, {i18n, loadTranslations} from "./i18n";
import {createPinia} from 'pinia'

$.onmount('[data-vue=product-request-update]', async function () {
    const app = createApp(ProductRequest)
    const pinia = await createPinia()
    app.config.unwrapInjectedRef = true
    app.use(pinia)
    app.use(i18nPlugin)
    // Mount app after translation loaded.
    await loadTranslations(i18n)
    app.mount(this)
})
