<template>
  <div>
    <multiselect v-model="selectedVariant" track-by="id" label="description" :show-labels="false" :options="variants"
                 :searchable="true" :loading="isLoading" :internal-search="true" :allow-empty="false"
                 @select="handleVariantSelection">
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import {omsCsrfHeader} from "../../fetch-utils";

export default {
  components: {Multiselect},

  props: {
    productRequestItem: {
      required: true,
      type: Object
    },
    productSupplierId: {
      required: false,
      type: Number
    },
    handleVariantSelection: {
      required: true,
      type: Function
    }
  },

  computed: {},

  data() {
    return {
      selectedVariant: null,
      variants: [],
      isLoading: false
    }
  },

  mounted() {
    this.fetchVariants()
  },

  watch: {
    productSupplierId: function () {
      this.fetchVariants()
    }
  },

  methods: {
    async fetchVariants() {
      if (this.productSupplierId) {
        this.isLoading = true
        try {
          const response = await fetch(`/product_requests/product_variants?product_supplier_id=${this.productSupplierId}`, {
            headers: omsCsrfHeader()
          })
          this.variants = await response.json()
          this.selectVariant()
        } finally {
          this.isLoading = false
        }
      }
    },
    selectVariant() {
      const productVariantId = this.productRequestItem.product_variant_id
      if (!productVariantId) {
        this.selectedVariant = this.variants[0]
      } else {
        this.selectedVariant = this.variants.find(variant => variant.id === productVariantId)
      }
      this.handleVariantSelection(this.selectedVariant)
    }
  }
}
</script>
