import Orders from '../../views/orders/index.vue'
import { createApp } from 'vue'
import { createPinia } from 'pinia'

$.onmount('[data-vue=orders]', function () {
  const initialData = JSON.parse(this.attributes['data-initial-data'].value)

  const pinia = createPinia()
  const app = createApp(Orders, { initialData })

  app.use(pinia)

  app.mount(this)
})
