<template>
  <div class='mt-4 mb-4'>
    <div class="row">
      <div v-if="serverError" class="alert alert-danger" role="alert">
        {{ serverError }}
      </div>
    </div>
    <div class="row mt-2 mb-2">
      <div class="col-sm-2">
        <h6>{{ t("activerecord.attributes.product_request.status") }}</h6>
      </div>
      <div class="col-sm-2">
        <span :class="'badge ' + productRequestStore.statusBadge">
          {{ t("features.product_request.status." + productRequestStore.status) }}
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <search-suppliers :supplier="productRequestStore.supplier" :setSupplier="setSupplier" />
        <p class="text-danger">{{ productRequestStore.errors.supplier }}</p>
      </div>
    </div>
    <div class="row mt-2 align-items-end">
      <div class="col-sm-4">
        <search-supplier-boxes v-if="productRequestStore.supplier"
                               :supplierId="productRequestStore.supplierId"
                               :box="productRequestStore.box"
                               :setBox="setBox" />
      </div>
      <div class="col-sm-8">
        <button class="btn btn-secondary" type="button" @click="handleFillProductRequestItems">
          {{ t('features.product_request.fill_product_request_items') }}
        </button>
      </div>
    </div>
    <div class="row form-group mt-4">
      <div class="col-sm-2">
        <label>
          <input type="checkbox" v-model="productRequestStore.shouldSendEmail">
          {{ t('features.product_request.send_email_to_supplier') }}
        </label>
      </div>
      <div class="col-sm-4">
        <label>
          <input type="checkbox" v-model="productRequestStore.isEachTimePayment">
          {{ t('features.product_request.each_time_payment') }}
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4 form-group">
        <label class="d-block" for="payment-type">
          {{ t('features.product_request.payment_type_title') }}
        </label>
        <select id="payment-type" v-model="productRequestStore.paymentType" class="form-control">
          <option disabled value="">Select payment type</option>
          <option value="prepaid">{{ t('features.product_request.payment_type.prepaid') }}</option>
          <option value="postpaid">{{ t('features.product_request.payment_type.postpaid') }}</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 form-group">
        <label class="d-block" for="additional-recipients">
          {{ t('features.product_request.additional_recipients.title') }}
        </label>
        <input id="additional-recipients" class="form-control" type="text"
               :placeholder="t('features.product_request.additional_recipients.placeholder')"
               v-model="productRequestStore.additionalRecipients" />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 form-group">
        <label class="d-block" for="address">
          {{ t('features.product_request.address') }}
        </label>
        <select id="address" class="form-control" v-model="productRequestStore.address">
          <option v-for="destination in productRequestStore.destinations" :value="destination.value"
                  :disabled="!destination.enabled">
            {{ destination.address }}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <label class="d-block" for="comment">
          {{ t('features.product_request.comment') }}
        </label>
        <textarea class="form-control" v-model="productRequestStore.comment" placeholder="Comment.."></textarea>
      </div>
    </div>
    <div class="row mt-4" v-if="productRequestStore.supplierId">
      <div class="col-sm-12">
        <table class="table">
          <thead>
          <tr>
            <th width="10%">{{ t('activerecord.attributes.product_request_item.product') }}</th>
            <th width="10%">{{ t('activerecord.attributes.product_request_item.price_per_item') }}</th>
            <th width="10%">{{ t('activerecord.attributes.product_request_item.product_variant') }}</th>
            <th width="10%">{{ t('activerecord.attributes.product_request_item.stock_destination') }}</th>
            <th width="10%">{{ t('activerecord.attributes.product_request_item.requested_quantity') }}</th>
            <th width="10%">{{ t('activerecord.attributes.product_request_item.requested_delivery_at') }}</th>
            <th width="10%">{{ t('activerecord.attributes.product_request_item.tax_rate') }}</th>
            <th width="10%">{{ t('activerecord.attributes.product_request_item.comment') }}</th>
            <th width="10%">{{ t('activerecord.attributes.product_request_item.internal_comment') }}</th>
            <th width="10%"></th>
          </tr>
          </thead>
          <tbody>
            <product-request-item-new v-for="(item, index) in productRequestStore.productRequestItems"
                                      :key="requestProductItemKey(item)"
                                      :index="index"
                                      :productRequestId="productRequestStore.id"
                                      :productRequestItem="item"
                                      :supplierId="productRequestStore.supplierId"
                                      :delete-product-request-item="productRequestStore.deleteProductRequestItem"
                                      :errors="productRequestStore.itemValidationErrors(index)"
                                      :boxId="productRequestStore?.box?.id"
            />
          </tbody>
        </table>
        <div class="d-flex justify-content-start">
          <button class="btn btn-outline-secondary m-1 pl-5 pr-5" type="button"
                  @click="productRequestStore.addNewProductRequestItem">
            {{ t('features.product_request.new_product') }}
          </button>
        </div>
      </div>
    </div>
    <hr />
    <div class="row mt-4 align-items-center">
      <div class="col-sm-4">
        {{ t('features.product_request.save_the_request_message') }}
        <button
          class="btn btn-primary m-1 w-100"
          type="button"
          @click="createProductRequest"
          :disabled="buttonDisabled"
        >
          {{ loading ? t('actions.saving') : t('actions.save') }}
        </button>
        <div v-if="loading" class="spinner-border" role="status" d-block>
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchSuppliers from './components/product-requests/search-suppliers.vue'
import SearchSupplierBoxes from './components/product-requests/search-supplier-boxes.vue'
import ValidatableInput from './validatable-input.vue'
import ProductRequestItemNew from "./components/product-requests/product-request-item-new";
import {useProductRequestStore} from "./stores/productRequestStore";
import {mapStores} from "pinia";
import {values} from "lodash";

export default {
  inject: ['i18n'],
  components: {ProductRequestItemNew, SearchSuppliers, SearchSupplierBoxes, ValidatableInput},
  data() {
    return {
      t: (key, options) => this.i18n.t(key, options),
      id: null,
      loading: false,
      serverError: "",
      buttonDisabled: false,
    }
  },
  computed: {
    ...mapStores(useProductRequestStore)
  },
  methods: {
    setSupplier(newSupplier) {
      this.productRequestStore.supplier = newSupplier
      this.productRequestStore.paymentType = newSupplier.default_payment_type
      this.productRequestStore.isEachTimePayment = newSupplier.each_time_payment
    },
    setBox(box) {
      this.productRequestStore.box = box
    },
    requestProductItemKey(item) {
      // item.product_supplier_id for fillProductRequestItem response, product_supplier.id for manually input on the item line.
      return item.id || item.product_supplier_id || item.product_supplier?.id
    },
    async createProductRequest() {
      this.buttonDisabled = true;
      const result = await this.productRequestStore.create()
      if (values(this.productRequestStore.errors).length > 0) {
        alert(values(this.productRequestStore.errors))
        this.buttonDisabled = false;
      }
      if (result?.error) {
        this.serverError = result.error
        alert(this.serverError)
        this.buttonDisabled = false;
      }
      if (result?.redirect_url) {
        window.location.href = result.redirect_url
      }
    },
    async handleFillProductRequestItems() {
      if (this.productRequestStore.isBoxSelected()) {
        await this.productRequestStore.fillProductRequestItems()
      }
    }
  }
}
</script>