$.onmount('[data-component=stock-destination-select]', function() {
  var $el = $(this)
  var $productSupplierId = $el.closest('[data-component=product-row]').find('[name*=product_supplier_id]')
  var $productVariantId = $el.closest('[data-component=product-row]').find('[name*=product_variant_id]')
  var $typeField = $el.closest('[data-component=product-row]').find('[name*=stock_destination_type]')
  var $idField = $el.closest('[data-component=product-row]').find('[name*=stock_destination_id]')

  function setOptions(data) {
    $el[0].selectize.clearOptions()
    $el[0].selectize.addOption(data)
  }

  function reloadOptions() {
    $.ajax({
      url: '/product_requests/stock_destinations',
      dataType: 'JSON',
      data: {
        product_supplier_id: $productSupplierId.val(),
        product_variant_id: $productVariantId.val()
      },
      success: setOptions
    })
  }

  $el.selectize({
    valueField: 'id',
    labelField: 'description',
    options: []
  })

  $el[0].selectize.on('change', function (id) {
    var parts = id.split('#')
    $typeField.val(parts[0]).trigger('change')
    $idField.val(parts[1]).trigger('change')
  })

  $productSupplierId.on('change', reloadOptions)
  $productVariantId.on('change', reloadOptions)
  reloadOptions()

  var preload = $el.data('preload')

  if (preload) {
    $el[0].selectize.addOption(preload)
    $el[0].selectize.setValue(preload.id, true)
  }
})
