$.onmount('[role=shopify-order-search]', function () {
  var $form = $(this)
  var url = $form.attr('action')

  $form.on('submit', function (e) {
    submit(url + '?' + $form.serialize())

    e.preventDefault()
  })

  $('body').on('click', '.pagination a, th > a', function (e) {
    e.preventDefault()
    e.stopPropagation()
    $el = $(this)

    if ( $el.attr('href') ) {
      submit( $(this).attr('href') )
    }
  })

  var submit = function (searchUrl) {
    $('.orders').addClass('-loading')
    $.ajax({
      url: searchUrl,
      cache: false,
      dataType: 'JSON',
      success: function (data) {
        onSuccess(searchUrl, data)
      },
      complete: function () {
        $('.orders').removeClass('-loading')
        $.onmount()
      }
    })
  }

  var onSuccess = function (searchUrl, data) {
    $('[role=shopify-search-list]').html(data.list)

    $.rails.enableFormElements( $form )
    window.history.pushState('', '', searchUrl)
    refresh()
  }

  var refresh = function () {
    var ids = JSON.parse(sessionStorage.shopifyOrders)

    _.each(ids, function (id) {
      $('tr[data-id=' + id + '] > td:first-child input[type=checkbox]').prop('checked', true)
      $('tr[data-id=' + id + ']').addClass('active')
    })

    if (ids.length > 0) {
      $('[data-role=print-label-btn]').removeClass('d-none')
      $('[data-role=print-label-btn] .count').text(ids.length)
    }

    $('[data-toggle=tooltip]').tooltip()
  }
})


