import { Controller } from '@hotwired/stimulus'

const SHIPMENTS_URL = '/japan_post/invoices/search_shipments'

export default class extends Controller {
  static values = { lastSelectedShippingMethodValue: String }

  static targets = ['shipmentComponentRowContainer', 'shippingMethodSelect', 'addShipmentButton']

  shippingMethodChange(event) {
    this.lastSelectedShippingMethodValue = event.target.value
  }

  async selection(event) {
    event.preventDefault()

    this.addShipmentButtonTarget.disabled = true

    if (this.shippingMethodSelectTarget.value !== undefined) {
      await fetch(SHIPMENTS_URL + `?shipping_method_id=${this.shippingMethodSelectTarget.value}`)
        .then(response => response.text())
        .then(html => {
          $(this.shipmentComponentRowContainerTarget).append(html)
        })
    }

    this.addShipmentButtonTarget.disabled = false
  }
}
