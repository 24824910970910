$.onmount('[data-component=order-print-form]', function () {
  var $this = $(this)

  // Different shippable ranges due to subscription cycle change in August 2022, see Order::BoxDates
  function setShippableRange(date, picker) {
    if (date.getTime() < new Date('2022-08-01 00:00:00').getTime()) {
      picker.set('minDate', new Date(date.getFullYear(), date.getMonth(), 1))
      picker.set('maxDate', new Date(date.getFullYear(), date.getMonth() + 1, 0))
    } else if (date.getTime() < new Date('2022-09-16 00:00:00').getTime()) {
      picker.set('minDate', new Date('2022-08-01'))
      picker.set('maxDate', new Date('2022-09-15'))
    } else if (date.getDate() < 16) {
      picker.set('minDate', new Date(date.getFullYear(), date.getMonth() - 1, 16))
      picker.set('maxDate', new Date(date.getFullYear(), date.getMonth(), 15))
    } else {
      picker.set('minDate', new Date(date.getFullYear(), date.getMonth(), 16))
      picker.set('maxDate', new Date(date.getFullYear(), date.getMonth() + 1, 15))
    }
  }

  flatpickr(this.querySelector('[data-show=shippable-date-picker]'), {
    mode: 'range',
    inline: true,
    onChange: function(selectedDates, _, instance) {
      if (selectedDates.length === 1) {
        setShippableRange(selectedDates[0], instance)
      } else {
        instance.set('minDate', undefined)
        instance.set('maxDate', undefined)
      }
    }
  })

  $this.find('[data-toggle-group]').click(function (e) {
    $this.find('[data-form-group=' + e.currentTarget.dataset.toggleGroup + ']').each(function () {
      var $group = $(this)
      var $input = $this.find('form ' + e.currentTarget.dataset.input)

      if ($group.attr('disabled')) {
        $input.val('1')
        $group.removeAttr('disabled')
        $group.find('.selectized').each(function () { this.selectize.enable() })
      } else {
        $input.val(null)
        $group.attr('disabled', true)
        $group.find('.selectized').each(function () { this.selectize.disable() })
      }
    })

    e.preventDefault();
  })
})
