$.onmount('[data-toggle=addon-search-select]', function () {
  var onLoad = function (query, callback) {
    $.ajax({
      url: '/addons/search',
      type: 'GET',
      dataType: 'JSON',
      data: {
        keyword: query
      },
      success: function (resp) {
        callback(resp.addons)
      },
      error: function () {
        callback()
      }
    })
  }

  var renderOption = function (item, escape) {
    return '<div class="media border-bottom">' +
      '<img class="media-object img-fluid w-25 mr-2" src="' + escape(item.image.url) + '" />' +

      '<div class="media-body">' +
        '<h6 class="media-heading">' + escape(item.sku) + '</h6>' +
        '<p class="mb-0">' + escape(item.name) + '</p>' +
      '</div>' +
    '</div>';
  }

  var init = function (element) {
    element.selectize({
      load: onLoad,
      highlight: false,
      valueField: 'id',
      labelField: 'name',
      searchField: ['name', 'sku'],
      options: [],
      create: false,
      preload: 'focus',
      placeholder: 'Search addon here..',
      render: {
        option: renderOption
      }
    })

    var selectedAddon = element.data('selected-addon')

    if (selectedAddon) {
      if (Array.isArray(selectedAddon)) {
        _.each(selectedAddon, function (objectItem) {
          element[0].selectize.addOption(objectItem)
        })
        element[0].selectize.setValue(_.map(selectedAddon, 'id'))
      } else {
        element[0].selectize.addOption(selectedAddon)
        element[0].selectize.setValue(selectedAddon.id)
      }
    }
  }

  var $el = $(this)

  init($el)
})
