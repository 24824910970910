<template>
  <oms-filter-dropdown title='Tags' icon='ri-price-tag-3-line' :is-filtering='isFiltering'>
    <p><strong>Include</strong></p>

    <label class='mr-3'>
      <input type='radio' value='all' :checked='!modelValue?.include?.filter_type || modelValue?.include?.filter_type === "all"'
        @change='emitInput("include", { filter_type: "all" })' />
      ALL of
    </label>
    <label>
      <input type='radio' value='any' :checked='modelValue?.include?.filter_type === "any"'
        @change='emitInput("include", { filter_type: "any" })' />
      ANY of
    </label>

    <multiselect class='multiselect--inline' :modelValue='modelValue?.include?.tags || []' :multiple='true' :options='tags'
      :show-labels='false' @update:modelValue='emitInput("include", { tags: $event })' />

    <p class='mt-3'><strong>Exclude</strong></p>

    <label class='mr-3'>
      <input type='radio' value='all' :checked='!modelValue?.exclude?.filter_type || modelValue?.exclude?.filter_type === "all"'
        @change='emitInput("exclude", { filter_type: "all" })' />
      ALL of
    </label>
    <label>
      <input type='radio' value='any' :checked='modelValue?.exclude?.filter_type === "any"'
        @change='emitInput("exclude", { filter_type: "any" })' />
      ANY of
    </label>

    <multiselect class='multiselect--inline' :modelValue='modelValue?.exclude?.tags || []' :multiple='true' :options='tags'
      :show-labels='false' @update:modelValue='emitInput("exclude", { tags: $event })' />
  </oms-filter-dropdown>
</template>

<script>
import Multiselect from 'vue-multiselect'
import OmsFilterDropdown from '../filter-dropdown.vue'

export default {
  props: {
    tags: Array,
    modelValue: Object
  },

  components: { Multiselect, OmsFilterDropdown },

  computed: {
    isFiltering() {
      return this.modelValue?.include?.tags?.length > 0 || this.modelValue?.exclude?.tags?.length > 0
    }
  },

  methods: {
    emitInput(type, fields) {
      this.$emit('update:modelValue', { ...(this.modelValue || {}), [type]: { ...this.modelValue[type], ...fields } })
    }
  },
}
</script>
