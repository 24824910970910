$.onmount('[data-toggle=addon-group-search-select]', function () {
  var objectMapper = {}
  var $el = $(this)

  var onLoad = function (query, callback) {
    $.ajax({
      url: '/addon_groups/search',
      type: 'GET',
      dataType: 'JSON',
      data: {
        keyword: query
      },
      success: function (resp) {
        _.each(resp.addon_groups, function (addon_group) {
          objectMapper[addon_group.id] = addon_group
        })
        callback(resp.addon_groups)
      },
      error: function () {
        callback()
      }
    })
  }

  var onChange = function (value) {
    if ($el.data('role') !== 'updateable') return

    var $addons = $el.parents('.item').find('[role=addons]')
    $addons.html('')
    if (value  === '' || value === null || value === undefined) return

    var object = objectMapper[value]

    _.each(object.addon_group_items, function (addon_group_item) {
      var $template = $($('[role=addon-group-template]').data('template'))

      $template.find('[name*=addon_id]').val(addon_group_item.addon.id)
      $template.find('[name*=prev_quantity]').val(addon_group_item.quantity)
      $template.find('[name*=next_quantity]').val(addon_group_item.quantity)
      $template.find('[name*=name]').val(addon_group_item.addon.name)
      $template.find('[role*=addon-name]').text(addon_group_item.addon.name)

      $addons.append($template)
      $addons.removeClass('d-none')
    })
  }

  var init = function (element) {
    var options = []
    var items = []
    var object = element.data('addon-group')

    if (object !== null && object !== undefined) {
      if (Array.isArray(object)) {
        _.each(object, function (objectItem) {
          options.push(objectItem)
          items.push(objectItem.id)
        })
      } else {
        options.push(object)
        items.push(object.id)
      }
    }

    element.selectize({
      load: onLoad,
      onChange: onChange,
      highlight: false,
      valueField: 'id',
      labelField: 'name',
      searchField: ['name'],
      options: options,
      items: items,
      create: false,
      preload: 'focus',
      placeholder: 'Search addon group here..',
    })
  }

  init($el)
})
