$.onmount('[data-component="shopify/order_table"]', function () {
  var $el = $(this)

  $el.on('oms:shopify-refund-complete', function (e, data) {
    var $tr = $el.find('tr[data-id="' + data.id + '"]')
    var $content = $(data.html)

    $tr.css({ opacity: 0.1 })
    $tr.removeClass()
    $tr.html($content.html())
    $tr.addClass( $content.attr('class') )
    $tr.animate({ opacity: 1 }, 1000)

    refreshOrderRow($tr, data)
  })

  var refreshOrderRow = function ($tr, data) {
    if ($el.data('template') == 'order' && sessionStorage.shopifyOrders !== undefined && sessionStorage.shopifyOrders !== null && !data.cancelled) {
      var ids = JSON.parse(sessionStorage.shopifyOrders)
      if (ids.indexOf(data.id.toString()) !== -1) $tr.find('> td:first-child input[type=checkbox]').prop('checked', true)
      if (data.cancelled) {
        $('body').trigger('shopify:order-cancelled', data.id)
      }
    } else if ($el.data('template') == 'confirm_order') {
      var $excluded = $('#excluded_ids')
      var current = $excluded.val().split(',')
      var orderId = data.id.toString()
      if (data.cancelled) {
        $excluded.val( _.union(current, [orderId]) )
      }
      if (current.indexOf(orderId) !== -1) {
        $tr.addClass('removed')
        $tr.find('.remove').parent().addClass('d-none')
        $tr.find('.add').parent().removeClass('d-none')
      }
    }
  }
})
