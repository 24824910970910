// This is a reusable component for selecting items
// in a table using a checkbox.
// Checkboxes can be controlled by the checkbox in the table head
//
// Sample Component Structure/Usage:
// <table data-component="table-items-selector">
//   <thead>
//     <tr>
//       <th><input data-select-all type="checkbox"></th>
//     </tr>
//   </thead>
//   <tbody>
//     <tr>
//       <td><input data-selectable-item type="checkbox"></td>
//     </tr>
//     <tr>
//       <td><input data-selectable-item type="checkbox"></td>
//     </tr>
//   </tbody>
// </table>
$.onmount('[data-component=table-items-selector]', function() {
  var $table = $(this);
  var $selector = $table.find('[data-select-all]');

  function updateSelectorCheckedProp () {
    var itemsCount = $table.find('[data-selectable-item]').length
    var selectedItemsCount = $table.find('[data-selectable-item]:checked').length

    if (itemsCount === selectedItemsCount) {
      $selector.prop('checked', true)
    } else {
      $selector.prop('checked', false)
    }
  }

  updateSelectorCheckedProp();

  $selector.on('click', function() {
    if($(this).prop('checked')) {
      $table.find('[data-selectable-item]').prop('checked', true)
    } else {
      $table.find('[data-selectable-item]').prop('checked', false)
    }
  });

  $table.find('[data-selectable-item]').on('click', function() {
    updateSelectorCheckedProp();
  });
});
