<template>
  <section class="list">
    <div
      class="card loading-area"
      :class="isLoading ? '-loading' : ''"
      role="search-list"
      data-selected="[]"
    >
      <div class="spinner-border text-secondary"></div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-7">
            <pagination-nav :initialData="pagination" />
          </div>
          <div class="col-sm-5 text-right">
            <pagination-info :initialData="pagination" />
          </div>
        </div>
      </div>
      <table class="table font-size-sm" data-toggle="order-table" data-url="/orders/bulk_update">
        <thead>
          <th v-if="canShipOrders" width="30px">
            <div class="checkbox">
              <input
                type="checkbox"
                name="orders[id][]"
                id="orders_id_"
                value="all"
                data-toggle="order-select"
              />
              <span></span>
            </div>
          </th>
          <th>
            <a data-toggle="order-sorting" :href="`/orders?sort=_id+asc${paramsUrl}`"> ID </a>
          </th>
          <th width="220px">
            <a data-toggle="order-sorting" :href="`/orders?sort=email+asc${paramsUrl}`">
              Customer
            </a>
          </th>
          <th>
            <a data-toggle="order-sorting" :href="`/orders?sort=invoice_id+asc${paramsUrl}`">
              Source
            </a>
          </th>
          <th width="170px">Plan &amp; Box</th>
          <th>
            <a data-toggle="order-sorting" :href="`/orders?sort=shippable_at+asc${paramsUrl}`">
              Shippable At
            </a>
          </th>
          <th>Shipping Invoice & Tags</th>
          <th>Status</th>
          <th class="text-right"></th>
        </thead>
        <tbody>
          <item
            v-for="order in orders"
            :key="order.id"
            :order="order"
            :returnReasons="returnReasons"
            :chargebeeAdminUrl="chargebeeAdminUrl"
          />
        </tbody>
      </table>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-7">
            <pagination-nav :initialData="pagination" />
          </div>
          <div class="col-sm-5 text-right">
            <pagination-info :initialData="pagination" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Item from './item.vue'
import PaginationNav from '../pagination/nav.vue'
import PaginationInfo from '../pagination/info.vue'

import buildQueryString from '../../../helpers/query-builder'

import { mapState } from 'pinia'
import { useOrdersStore } from '../../stores/orders'

export default {
  components: { Item, PaginationInfo, PaginationNav },

  computed: {
    ...mapState(useOrdersStore, {
      isLoading: 'isLoading',
      canShipOrders: 'canShipOrders',
      orders: 'getOrders',
      pagination: 'getPagination',
      returnReasons: 'getReturnReasons',
      chargebeeAdminUrl: 'getChargebeeAdminUrl'
    }),

    paramsUrl() {
      return this.paramsQueryString ? `&${this.paramsQueryString}` : ''
    },

    paramsQueryString() {
      return buildQueryString(this.pagination.params, 'q')
    }
  },

  mounted() {
    // Remount [data-toggle=order-table] to fix selectedOrderIds feature from not initializing on first mount and prevent
    // duplicate vue app instances which can be caused by calling just $.onmount.
    $.onmount('[data-toggle=order-table]')
  }
}
</script>
