<template>
  <oms-filter-dropdown :title='title' :icon='icon'>
    <label v-for='option in options' class='form-check'>
      <input class='form-check-input' type='radio' :checked='isChecked(option.id)' @input='$emit("update:modelValue", option.id)' />
      <span class='form-check-label'>{{ option.name }}</span>
    </label>
  </oms-filter-dropdown>
</template>

<script>
import OmsFilterDropdown from '../filter-dropdown.vue'

export default {
  props: {
    title: String,
    icon: String,
    options: Array,
    modelValue: String
  },

  computed: {
    defaultOption() {
      return this.options[0].id
    }
  },

  methods: {
    isChecked(option) {
      if (!this.modelValue && option === this.defaultOption) {
        return true
      }

      return this.modelValue === option
    }
  },

  components: {
    OmsFilterDropdown
  }
}
</script>
