<template>
  <oms-filter-content title='Tag' :is-filtering='isFiltering'>
    <span v-for='(tag, index) in modelValue?.include?.tags' :key='tag'>
      <span class='badge badge-info position-static'>{{ tag }}</span>
      <span class='ml-1 mr-1' v-if='index !== modelValue.include.tags.length - 1'>
        <template v-if='modelValue.include.filter_type === "all"'>AND</template>
        <template v-else>OR</template>
      </span>
    </span>

    <strong class='mx-1' v-if='modelValue?.exclude?.tags?.length > 0'>EXCLUDING</strong>

    <span v-for='(tag, index) in modelValue?.exclude?.tags' :key='tag'>
      <span class='badge badge-info position-static'>{{ tag }}</span>
      <span class='ml-1 mr-1' v-if='index !== modelValue.exclude.tags.length - 1'>
        <template v-if='modelValue.exclude.filter_type === "all"'>AND</template>
        <template v-else>OR</template>
      </span>
    </span>
  </oms-filter-content>
</template>


<script>
import OmsFilterContent from '../filter-content.vue'
export default {
  props: {
    modelValue: Object
  },

  computed: {
    isFiltering() {
      return this.modelValue?.include?.tags?.length > 0 || this.modelValue?.exclude?.tags?.length > 0
    }
  },

  components: {
    OmsFilterContent
  }
}
</script>
