<template>
  <div>
    <label>{{ t('features.product_request.select_month_box') }}</label>
    <multiselect v-model="selectedBox" track-by="id" placeholder="Search for a month box .." :allow-empty="false"
                 :show-labels="false" :options="boxes" :custom-label="boxName" :searchable="true" :loading="isLoading"
                 :value="box" @select="setBox" :disabled="!supplierId">
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import {omsCsrfHeader} from "../../fetch-utils";

export default {
  inject: ['i18n'],
  props: {
    setBox: {
      required: true,
      type: Function
    },
    supplierId: {
      required: false,
      type: Number
    },
    box: {
      required: false,
      type: Object
    }
  },
  components: {Multiselect},

  computed: {
    selectedBox: {
      get() {
        return this.box
      },
      set(newBox) {
        this.setBox(newBox)
      }
    }
  },

  data() {
    return {
      t: (key, options) => this.i18n.t(key, options),
      boxes: [],
      isLoading: false
    }
  },

  watch: {
    async supplierId() {
      await this.fetchBoxes()
    }
  },

  async mounted() {
    if (this.supplierId) {
      await this.fetchBoxes()
    }
  },

  methods: {
    boxName(box) {
      return `${box.month} ${box.year} ${box.plan_type}`
    },
    async fetchBoxes() {
      this.isLoading = true
      try {
        const response = await fetch(`/suppliers/${this.supplierId}/boxes`, {
          headers: omsCsrfHeader()
        })
        this.boxes = await response.json()
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
