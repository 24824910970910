<template>
  <div>
    <label>{{ t('features.product_request.select_supplier') }}</label>
    <multiselect v-model="selectedSupplier" label="company_name" track-by="id" placeholder="Search for a supplier .."
                 :options="suppliers" :searchable="true" :loading="isLoading" :internal-search="false"
                 :allow-empty="allowEmpty" :show-labels="false" @search-change="asyncFind">
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import {omsCsrfHeader} from "../../fetch-utils";

export default {
  inject: ['i18n'],
  props: {
    supplier: {
      required: false,
      type: Object
    },
    setSupplier: {
      required: true,
      type: Function
    },
    allowEmpty: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  components: {Multiselect},

  computed: {
    selectedSupplier: {
      get() {
        return this.supplier
      },
      set(newSupplier) {
        this.setSupplier(newSupplier)
      }
    }
  },

  async mounted() {
    if (this.supplier) {
      this.suppliers = [this.supplier]
    }
  },

  data() {
    return {
      t: (key, options) => this.i18n.t(key, options),
      suppliers: [],
      isLoading: false,
    }
  },

  methods: {
    async asyncFind(query) {
      this.isLoading = true
      try {
        const response = await fetch(`/product_requests/search_suppliers?keyword=${query}`, {
          headers: omsCsrfHeader()
        })
        this.suppliers = await response.json()
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
