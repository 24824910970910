<template>
  <div class="card mb-4">
    <div class="card-body pb-3">
      <div class="d-flex align-items-end">
        <div class="mr-2 mb-2">
          <search-suppliers :supplier="supplier" :setSupplier="setSupplier" :allow-empty="true" />
        </div>
        <div
          class="mr-2 mb-2 flex-grow-1"
          :title="t('features.product_delivery.tip_for_keyword_on_search')"
        >
          <input
            class="form-control"
            v-model="filters.keyword"
            @keyup.enter="submit"
            type="text"
            :placeholder="t('features.product_delivery.tip_for_keyword_on_search')"
          />
        </div>
      </div>
      <div class="d-flex flex-wrap">
        <oms-filter-statuses
          :title="t('features.search_items.stock_destination_type')"
          icon="ri-ship-line"
          :statuses="stockDestinationTypes"
          v-model="filters.stock_destination_types"
          color="secondary"
        />
        <oms-filter-date-range
          :title="t('features.search_items.expected_at')"
          icon="ri-map-pin-time-line"
          v-model="filters.requested_delivery_at"
        />
        <oms-filter-ids :title="t('features.search_items.product_request_ids')"
                        v-model="filters.product_request_ids" />
        <oms-filter-ids :title="t('features.search_items.product_request_item_ids')"
                        v-model="filters.request_item_ids" />
        <button class="btn btn-primary mr-2 mb-2" @click="submit" :disabled="isLoading">
          <span class="ri-search-line"></span> {{ t('actions.search') }}
        </button>
      </div>
    </div>

    <div class="card-body pt-0 pb-1">
      <oms-filter-content-statuses
        :title="t('features.search_items.stock_destination_type')"
        :statuses="stockDestinationTypes"
        v-model="filters.stock_destination_types"
        color="secondary"
      />
      <oms-filter-content-date-range
        :title="t('features.search_items.expected_at')"
        :modelValue="filters.requested_delivery_at" />
    </div>
  </div>
</template>

<script>
import buildQueryString from '../../../helpers/query-builder'
import OmsFilterStatuses from '../filter/statuses.vue'
import OmsFilterDateRange from '../filter/date-range.vue'
import OmsFilterContentStatuses from '../filter-content/statuses.vue'
import OmsFilterContentDateRange from '../filter-content/date-range.vue'
import OmsFilterContent from '../filter-content/basic.vue'
import SearchSuppliers from "../product-requests/search-suppliers";
import OmsFilterIds from '../filter/ids.vue'
import {omsCsrfHeader} from "../../fetch-utils";

export default {
  inject: ['i18n'],
  props: {
    initialData: Object
  },

  data() {
    return {
      t: (key, options) => this.i18n.t(key, options),
      ...this.initialData,
      supplier: undefined,
      isLoading: false,
      startsAt: this.initialData.filters.arrived_at?.starts_at,
      endsAt: this.initialData.filters.arrived_at?.ends_at
    }
  },

  async mounted() {
    if (this.initialData.filters?.supplier_id) {
      this.supplier = await this.fetchSupplierById(this.initialData.filters.supplier_id)
    }
  },

  computed: {
    searchUrl() {
      return `${this.baseUrl}?${this.filterQueryString}`
    },

    filterQueryString() {
      return buildQueryString(this.filters, 'q')
    }
  },

  methods: {
    submit() {
      this.isLoading = true

      window.location.href = this.searchUrl
    },
    setSupplier(supplier) {
      this.supplier = supplier
      this.filters.supplier_id = supplier?.id
    },
    async fetchSupplierById(supplierId) {
      const response = await fetch(`/suppliers/${supplierId}`, {
        headers: omsCsrfHeader()
      })
      return await response.json()
    }
  },

  components: {
    OmsFilterContentStatuses,
    OmsFilterContentDateRange,
    OmsFilterStatuses,
    OmsFilterDateRange,
    OmsFilterContent,
    SearchSuppliers,
    OmsFilterIds
  }
}
</script>

