import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'statusField', 'submitBtn', 'paymentTypeSelect', 'supplierSelect', 'eachTimePaymentCheckbox']

  connect() {
    // Selectize doesn't support the addEventListener method that Stimulus is probably using (even that `onchange` works).
    // thus we need to manually register the handler
    this.supplierSelectTarget.selectize.on('change', (e) => this.supplierSelected(e)) 
  }

  submitToDirector(event) {
    event.preventDefault()
    this.statusFieldTarget.value = 'submitted_to_director'
    $(this.submitBtnTarget).click()
  }

  supplierSelected(value) {
    const supplier = this.supplierSelectTarget.selectize.options[value]
    if (supplier) {
      this.eachTimePaymentCheckboxTarget.checked = !!supplier['default_each_time_payment']
      this.paymentTypeSelectTarget.value = supplier['default_payment_type']
    }
  }
}
