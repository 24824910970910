<template>
  <oms-filter-content :title='title' :is-filtering='isFiltering'>
    <span v-for='status in includedOptions' :key='status' class='badge badge-dark position-static mr-1'
      :class='backgroundClass(status)'>
      {{ statuses[status] }}
    </span>

    <strong v-if='excludedOptions.length > 0' class='mx-1'>EXCLUDING</strong>

    <span v-for='status in excludedOptions' :key='status' class='badge badge-dark position-static mr-1'
      :class='backgroundClass(status)'>
      {{ statuses[status] }}
    </span>
  </oms-filter-content>
</template>

<script>
import OmsFilterContent from '../filter-content.vue'

export default {
  props: {
    title: {
      type: String,
      default: 'Status'
    },
    color: {
      type: String
    },
    statuses: Object,
    modelValue: Object
  },

  computed: {
    isFiltering() {
      return this.modelValue?.include?.length > 0 || this.modelValue?.exclude?.length > 0
    },

    includedOptions() {
      return this.modelValue?.include || []
    },

    excludedOptions() {
      return this.modelValue?.exclude || []
    }
  },

  methods: {
    backgroundClass(status) {
      if (this.color) {
        return `bg-${this.color}`
      } else {
        return `bg-${status.replace(/_/g, '-')}`
      }
    }
  },

  components: {
    OmsFilterContent
  }
}
</script>
