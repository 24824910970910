<template>
  <oms-bulk-action-modal id='adjustTagsModal' title='Adjust Tags' :filters='filters' :params='params'
    :disabled='!tagsToAdd.length && !tagsToRemove.length' :total-results='totalResults'>
    <div class="mt-3 d-flex flex-column">
      <label>Add Tags</label>
      <multiselect v-model='tagsToAdd' :options='currentTags.concat(newTags)' :multiple='true' :show-labels='false'
        :taggable='true' @tag='addTag(tagsToAdd, $event)' />
    </div>

    <div class="mt-3 d-flex flex-column">
      <label>Remove Tags</label>
      <multiselect v-model='tagsToRemove' :options='currentTags.concat(newTags)' :multiple='true' :show-labels='false'
        :taggable='true' @tag='addTag(tagsToRemove, $event)' />
    </div>
  </oms-bulk-action-modal>
</template >

<script>
import Multiselect from 'vue-multiselect';
import OmsBulkActionModal from './bulk-action-modal.vue';

export default {
  props: {
    filters: Object,
    currentTags: Array,
    totalResults: Number
  },

  components: { Multiselect, OmsBulkActionModal },

  data() {
    return {
      tagsToAdd: [],
      tagsToRemove: [],
      newTags: []
    }
  },

  computed: {
    params() {
      return {
        type: 'adjust_tags',
        payload: { add: this.tagsToAdd, remove: this.tagsToRemove }
      }
    }
  },

  methods: {
    addTag(list, tag) {
      list.push(tag)
      this.newTags.push(tag)
    }
  }
}
</script>
