import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "paymentTypeSelect", "supplierSelect", "productItemsTable", "productInvoiceId" ]

  static values = {
    id: String
  }

  connect() {
    this.selection()
    // Selectize doesn't support the addEventListener method that Stimulus is probably using (even that `onchange` works).
    // thus we need to manually register the handler
    this.supplierSelectTarget.selectize.on('change', (e) => this.supplierSelected(e))
  }

  async selection() {
    if (this.paymentTypeSelectTarget?.value && this.supplierSelectTarget?.value) {
      const url = this.urlBuilder(
        this.paymentTypeSelectTarget.value,
        this.supplierSelectTarget.value,
        this.idValue
      )

      await fetch(url)
        .then(response => response.text())
        .then(html => {
          this.productItemsTableTarget.innerHTML = html
        }
      )
    }
  }

  supplierSelected(value) {
    const supplier = this.supplierSelectTarget.selectize.options[value]
    if (supplier && supplier["default_payment_type"])
      this.paymentTypeSelectTarget.value = supplier["default_payment_type"]
    
    this.selection()
  }

  paymentTypeSelected() {
    this.selection()
  }

  urlBuilder(paymentMethod, supplierId, invoiceId) {
    const params = {
      unpaid: "true",
    }

    if (invoiceId) {
      params.product_invoice_id = invoiceId
    }

    return paymentMethod === 'prepaid' ?
      `/suppliers/${supplierId}/product_request_items?${new URLSearchParams(params).toString()}` :
      `/suppliers/${supplierId}/product_delivery_items?${new URLSearchParams(params).toString()}`
  }
}
