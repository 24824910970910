$.onmount("[data-component='coupon-forms/select-plan-constraint']", function() {
  var $el = $(this)
  var addonGroupsTable = $('#coupon-addon-groups')
  $addonGroupsBody = addonGroupsTable.find('tbody')
  $plansSelectField = $('#specified-plan-ids').find('select')
  $form = $el.closest('form')

  $plansSelectField.selectize({
    highlight: false,
    create: false,
    plugins: ['remove_button']
  })

  $plansSelectize = $plansSelectField[0].selectize

  function selectPlanConstraint() {
    var planConstraint = $el.val()

    if(planConstraint == 'all') {
      $('#specified-plan-ids').addClass('d-none')
      Object.values($plansSelectize.options).forEach(function(value) {
        appendToAddonGroupBody(value.value)
      })
    } else if(planConstraint == 'specific') {
      $('#specified-plan-ids').removeClass('d-none')
    }
  }

  function selectizeAddonGroupSelect(field) {
    field.selectize({
      highlight: false,
      create: false
    })

    field[0].selectize.on('item_add', function (value, $item) {
      var planId = $(this)[0].$input.attr('id')
      var hiddenSelectId = `hidden-addon-select-${value}`
      var hiddenSelect = $(`#${hiddenSelectId}`)
      var option = $(`<option value="${planId}" selected="selected"></option>`)
      var existingOption = $('.hidden-plan-select').find(`option[value="${planId}"]`)

      if (hiddenSelect.length == 0) {
        hiddenSelect = $(`<select id="${hiddenSelectId}" name="addon_group_plans[${value}][]" class="d-none hidden-plan-select" multiple>`)
        $form.prepend(hiddenSelect)
      }

      if (existingOption.length > 0) {
        hiddenSelect.prepend(existingOption)
      } else {
        hiddenSelect.prepend(option)
      }
    })
  }

  function appendToAddonGroupBody(value) {
    var duplicatedAddonGroupSelect = $('#original-addon-group-select').clone()
    duplicatedAddonGroupSelect.attr('id', value)
    duplicatedAddonGroupSelect.attr('name', `addon_groups[id]`)
    duplicatedAddonGroupSelect.addClass('addon-group-select')
    var selectField = $(`<tr><td>${value}</td><td></td></tr>`)
    selectField.find(':nth-child(2)').append(duplicatedAddonGroupSelect)
    duplicatedAddonGroupSelect.removeClass('d-none')
    selectizeAddonGroupSelect(duplicatedAddonGroupSelect)
    $addonGroupsBody.append(selectField)
  }

  function removeFromAddonGroupBody(value) {
    var parentTrOfSelectField = $(`#${value}`).closest('tr')
    var existingOption = $('.hidden-plan-select').find(`option[value="${value}"]`)
    parentTrOfSelectField.remove()
    existingOption.remove()
  }

  $('.addon-group-select').each(function () {
    selectizeAddonGroupSelect($(this))
  })

  selectPlanConstraint()

  $el.on('change', function() {
    if ($addonGroupsBody.length > 0) $addonGroupsBody.html('')
    selectPlanConstraint()
    $plansSelectize.clear(false)
  })

  $plansSelectize.on('item_add', function (value, $item) {
    appendToAddonGroupBody(value)
  })
  $plansSelectize.on('item_remove', function (value, $item) {
    removeFromAddonGroupBody(value)
  })
})
