<template>
  <oms-filter-dropdown title='Plan' icon='ri-stack-line' :isFiltering='isFiltering' width='500px'>
    <h6>Box Type</h6>
    <label class='form-check d-inline-block w-50' v-for='(name, planType) in planTypes' :key='planType'>
      <input class='form-check-input' type='checkbox' :checked='selectedPlanTypes?.include?.includes(planType)'
        @input='toggleFilterSelected("selectedPlanTypes", "input-types", planType)' />
      <span class='form-check-label'>{{ name }}</span>
    </label>

    <h6>Duration</h6>
    <label class='form-check w-50' v-for='(name, duration) in planPeriods' :key='duration'>
      <input class='form-check-input' type='checkbox' :checked='selectedPlanPeriods?.include?.includes(duration)'
        @input='toggleFilterSelected("selectedPlanPeriods", "input-periods", duration)' />
      <span class='form-check-label'>{{ name }}</span>
    </label>
  </oms-filter-dropdown>
</template>

<script>
import OmsFilterDropdown from '../filter-dropdown.vue'

export default {
  props: {
    planTypes: Object,
    planPeriods: Object,
    selectedPlanTypes: Object,
    selectedPlanPeriods: Object
  },

  computed: {
    isFiltering() {
      return this.selectedPlanTypes?.include?.length > 0 ||
        this.selectedPlanPeriods?.include?.length > 0
    }
  },

  methods: {
    toggleFilterSelected(field, eventName, value) {
      const current = this[field] || {}
      const currentValues = current.include || []

      if (currentValues.includes(value)) {
        this.$emit(eventName, { ...current, include: currentValues.filter(item => item !== value) })
      } else {
        this.$emit(eventName, { ...current, include: [...currentValues, value] })
      }
    }
  },

  components: {
    OmsFilterDropdown
  }
}
</script>
