import ProductDeliveriesFilters from '../../components/product-deliveries/filters.vue'
import i18nPlugin, {i18n, loadTranslations} from "../../i18n";
import {createApp} from 'vue'

$.onmount('[data-vue=product-deliveries-filters]', async function () {
    const initialData = JSON.parse(this.attributes['data-initial-data'].value)
    const app = createApp(ProductDeliveriesFilters, {initialData})
    app.use(i18nPlugin)
    await loadTranslations(i18n)
    app.mount(this)
})
