<template>
  <h5 class="text-primary">
    <i class="bi bi-clock mr-2" />
    {{ format(new Date(batch.expected_delivery_at), 'HH:mm') }}
    <a :href=linkDeliveryUrl(batch) target="_blank" class="btn btn-outline-primary float-right">
      {{ t('actions.deliver') }}
    </a>
    <a :href="linkProductRequest(batch)" target="_blank" class="btn btn-outline-info float-right mr-1">
      {{ t('activerecord.models.product_request.one') }}
      <i class="ri-search-eye-line" />
    </a>
  </h5>
  <div class="row p-3 mt-4 mb-3" :style="{ 'background-color': '#FFEBEE' }">
    <div class="col-sm-3"><i class="mr-1 bi bi-building"></i>
      <a :href=linkSupplier(batch.supplier) target="_blank">
        <b>{{ batch.supplier.company_name }}</b>
      </a>
    </div>
    <div class="col-sm-3"><i class="mr-1 bi bi-person"></i>({{ batch.supplier.contact_person }})</div>
    <div class="col-sm-3"><i class="mr-1 bi bi-telephone"></i>{{ batch.supplier.phone }}</div>
    <div class="col-sm-3"><i class="mr-1 bi bi-at"></i>{{ batch.supplier.email }}</div>
  </div>
  <div>
    <div class="row pt-2 pb-2 pr-4 pl-4 justify-content-space-between"
         v-for="product_request_item in batch.product_request_items">
      <div class="col-sm-6">
        <div><b>{{ product_request_item.product.invoice_name }}</b></div>
        <div>
          <a :href=linkProduct(product_request_item.product) target="_blank">
            {{ product_request_item.product.name }}
          </a>
        </div>
        <div>{{ product_request_item.product.sku }}</div>
        <div>{{ product_request_item.product.product_type }}</div>
      </div>
      <div class="col-sm-4">
      </div>
      <div class="col-sm-2 justify-self-flex-end align-self-center">
        <h4 class="text-info">{{ product_request_item.quantity_per_batch }} item</h4>
      </div>
    </div>
  </div>
</template>

<script>
import buildQueryString from "../../helpers/query-builder";
import {format, formatDistanceToNow} from "date-fns";

export default {
  name: "upcoming-delivery-batch",
  inject: ['i18n'],
  props: {
    initialData: {
      type: Object,
      required: true
    },
    batch: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      t: (key, options) => this.i18n.t(key, options),
      ...this.initialData,
      format,
      formatDistanceToNow
    }
  },

  methods: {
    linkDeliveryUrl(batch) {
      return `${this.deliveryLinkUrl}?${this.filterQueryString(batch)}&${this.buildQuantityQueryString(batch)}`
    },
    /**
     * The query should be like
     * q[request_item_ids][include][]=1&q[request_item_ids][include][]=2
     */
    filterQueryString(batch) {
      const item_ids = batch.product_request_items.map((item) => item.id)
      const filters = {request_item_ids: {include: item_ids}}
      return buildQueryString(filters, 'q')
    },
    /**
     * The query should be like
     * deliveries[][item_id]=1&deliveries[][quantity]=1000&deliveries[][item_id]=2&deliveries[][quantity]=2000
     */
    buildQuantityQueryString(batch) {
      const quantities = batch.product_request_items.map((item) => {
        return {item_id: item.id, quantity: item.quantity_per_batch}
      })
      return buildQueryString(quantities, 'deliveries')
    },
    linkProductRequest(batch) {
      return `${this.productRequestUrl}/${batch.product_request_id}/preview`
    },
    linkSupplier(supplier) {
      return `${this.supplierUrl}/${supplier.id}`
    },
    linkProduct(product) {
      return `${this.productUrl}/${product.id}`
    }
  }
}
</script>
