$.onmount('[data-toggle=print-label]', function () {
  var apiURL = this.dataset.url
  var $table = $(this)

  // Toggle Order Checkbox
  $('body').on('change', '[data-toggle=order-select]', function () {
    $(this).parents('tr').toggleClass('active')

    if ($('[data-toggle=order-select]:checked').length > 0) {
      $('.order-actions').removeClass('d-none')
    } else {
      $('.order-actions').addClass('d-none')
    }
  })

  $('body').on('click', '[data-toggle=single-order-action]', function (e) {
    e.preventDefault()

    if (confirm(this.dataset.warning)) {
      callAction(this.dataset.type, [this.dataset.id])
    }
  })

  $('body').on('click', '[data-toggle=bulk-order-action]', function (e) {
    e.preventDefault()

    if (confirm('Are you sure to ' + this.dataset.type.toUpperCase() + ' selected orders?')) {
      orderIds = $('[data-toggle=order-select]:checked')
        .map(function (i, o) {
          return o.value
        })
        .toArray()

      callAction(this.dataset.type, orderIds)
    }
  })

  // Helpers
  var callAction = function (type, orderIds) {
    if (type === 'remove') {
      remove(orderIds)
    } else if (type === 'add') {
      add(orderIds)
    } else {
      update(type, orderIds)
    }

    toggle(orderIds, !(type === 'release' || type === 'add'))
  }

  var remove = function (orderIds) {
    _.each(orderIds, function (orderId) {
      var $el = $('[data-toggle=order-select][value=' + orderId + ']')
      $el.prop('checked', false)
      $('.orders tr[data-id=' + orderId + ']').removeClass('active')
      $('.orders tr[data-id=' + orderId + ']').addClass('removed')
      $('.orders tr[data-id=' + orderId + ']').css('text-decoration', 'line-through')
      $('.orders tr[data-id=' + orderId + '] .dropdown-menu .remove').addClass('d-none')
      $('.orders tr[data-id=' + orderId + '] .dropdown-menu .add').removeClass('d-none')
    })
  }

  var add = function (orderIds) {
    _.each(orderIds, function (orderId) {
      var $el = $('[data-toggle=order-select][value=' + orderId + ']')
      $el.prop('checked', false)
      $('.orders tr[data-id=' + orderId + ']').removeClass('removed')
      $('.orders tr[data-id=' + orderId + ']').removeClass('active')
      $('.orders tr[data-id=' + orderId + ']').css('text-decoration', 'none')
      $('.orders tr[data-id=' + orderId + '] .dropdown-menu .remove').removeClass('d-none')
      $('.orders tr[data-id=' + orderId + '] .dropdown-menu .add').addClass('d-none')
    })
  }

  var update = function (type, orderIds) {
    $.ajax({
      url: apiURL,
      method: 'PUT',
      data: {
        order_ids: orderIds,
        action_type: type
      },
      success: onSuccess
    })
  }

  var onSuccess = function (orders) {
    _.each(orders, function (order, i) {
      $table.find('tr[data-id=' + order.id + ']').replaceWith(order.html)
    })
  }

  var toggle = function (orderIds, isExcluded) {
    var $el = $('#excluded_ids')
    var current = $el.val().split(',')

    if (isExcluded) {
      $el.val(_.union(current, orderIds))
    } else {
      $el.val(_.difference(current, orderIds))
    }

    var totalCount = $('[role=orders-count]').data('count')
    var excludedCount = _.reject($el.val().split(','), function (v) {
      return v === ''
    }).length
    var remainingCount = $('[role=orders-count]').text(totalCount - excludedCount)
  }
})
