$("[data-component='coupon-forms/confirmation']").on("click", function() {
  var validTillValue = $("#coupon_form_valid_till").val();
  var formattedDate = new Date(validTillValue).toLocaleString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false // Add this option to remove AM/PM
  }) + " JST";

  $("#valid_til").text(formattedDate);

  $('#confirmation-modal').modal('show');
})

$("[data-component='coupon-forms/submit']").on("click", function() {
  $("#coupon-form").submit()
})