$.onmount('[data-toggle=order-refund-type]', function () {
  var $el = $(this)

  $el.on('change', 'input[type=radio]', function () {
    if (this.value === 'partial_refund') {
      $('[data-component=partial-amount]').removeClass('d-none')
      $('[data-component=full-amount]').addClass('d-none')
    } else {
      $('[data-component=partial-amount]').addClass('d-none')
      $('[data-component=full-amount]').removeClass('d-none')
    }
  })
})

$.onmount('[data-toggle=order-refund-destination]', function () {
  var $el = $(this)
  if ($el.find('input[type=radio]').val() === 'coupon') {
    $('[data-component=coupon-refund-message]').removeClass('d-none')
  }
  $el.on('change', 'input[type=radio]', function () {
    if (this.value === 'coupon') {
      if ($('[data-toggle=order-refund-type] input[type=radio]:checked').val() == 'partial_refund') {
        $('[data-component=partial-coupon-refund-message]').removeClass('d-none')
      } else {
        $('[data-component=full-coupon-refund-message]').removeClass('d-none')
      }
    } else {
      $('[data-component=coupon-refund-message]').addClass('d-none')
    }
  })
})
