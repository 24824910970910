<script setup>
import { storeToRefs } from 'pinia'
import { toRefs, onMounted, inject, watch } from 'vue'
import { useShipmentsStore } from '../../stores/shipments'
import OmsFilterDateRange from '../filter/date-range.vue'
import OmsFilterContentDateRange from '../filter-content/date-range.vue'
import OmsFilterContentBasic from '../filter-content/basic.vue'
import OmsFilterContentStatuses from '../filter-content/statuses.vue'
import OmsFilterRadioButtons from '../filter/radio-buttons.vue'
import OmsFilterStatuses from '../filter/statuses.vue'

const i18n = inject('i18n')
const t = (key, options) => i18n.t(key, options)

const props = defineProps({
  initialData: {
    type: Object,
    required: true
  }
})

const { initialData } = toRefs(props)

const store = useShipmentsStore()
// Store getters and state
const {
  shippingMethods,
  shippingMethodPrintOptions,
  filters,
  boxBrands,
  isBrandSubscriptionBoxes,
  isTestShippingSelected,
  transactedOptions,
  printStatusOptions,
  exportType,
  countries
} = storeToRefs(store)
// Store actions
const { setInitialState, handleSubmit } = store

watch(isTestShippingSelected, (newValue) => {
  if (!newValue) {
    // Set filter.shipment_method_print_option to empty string if 'other' is removed from shipping_methods
    filters.value.shipping_method_print_option = ''
  }
})

onMounted(() => {
  setInitialState(initialData)

  $.onmount() // Reinitialize mount to load data-* attributes
})
</script>

<template>
  <form role="shipments-filter" @submit.prevent="handleSubmit">
    <div class="row">
      <div class="col-lg-3 col-md-4">
        <!-- Shipment name -->
        <div class="form-group">
          <label class="col-form-label">{{ t('features.shipments.filters.shipment_names') }}</label>
          <oms-filter-tags :tags="tags" v-model="filters.tag_filters" />
          <textarea
            name="shipment_name"
            id="shipment_name"
            class="form-control"
            v-model="filters.shipment_name"
            rows="1"
            placeholder="SSB-1,SSB-2,SSB-3..."
          ></textarea>

        </div>
      </div>
      <div class="col-lg-3 col-md-4">
        <!-- Shipper memo -->
        <div class="form-group">
          <label class="col-form-label">
            {{ t('features.shipments.filters.shipper_memo') }}
          </label>
          <input
            type="text"
            name="shipper_memo"
            id="shipper_memo"
            class="form-control"
            v-model="filters.shipper_memo"
          />
        </div>
      </div>
      <div class="col-lg-3 col-md-4" :class="{ 'mr-auto': !isBrandSubscriptionBoxes }">
        <!-- Invoice name -->
        <div class="form-group">
          <label class="col-form-label">
            {{ t('features.shipments.filters.shipment_invoice_name') }}
          </label>
          <input
            type="text"
            name="shipment_invoice_name"
            id="shipment_invoice_name"
            class="form-control"
            v-model="filters.shipment_invoice_name"
          />
        </div>
      </div>
      <div v-if="isBrandSubscriptionBoxes" class="col-lg-3 col-md-4 mr-auto">
        <!-- Box month -->
        <div class="form-group">
          <label class="col-form-label">
            {{ t('features.shipments.box_month_content') }}
          </label>
          <input
            type="text"
            name="box_month_content"
            id="box_month_content"
            class="form-control"
            v-model="filters.box_month_content"
          />
        </div>
      </div>
      <div class="col-lg-3 col-md-4 mr-auto">
        <div class="form-group">
          <label class="col-form-label">
            {{ t('features.shipments.country') }}
          </label>
          <select
            name="country"
            id="country"
            class="form-control"
            v-model="filters.country"
          >
            <option value="" selected="selected"></option>
            <option
              v-for="(option, index) in countries"
              :key="index"
              :value="index"
            >
              {{ option }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-end">
      <div class="d-flex flex-wrap">
        <!-- Status? -->
        <oms-filter-radio-buttons
          :title="t('features.shipments.print_status')"
          icon="ri-git-branch-line"
          :options="printStatusOptions"
          v-model="filters.status"
        />

        <!-- Transacted? -->
        <oms-filter-radio-buttons
          :title="t('features.shipments.transacted_status')"
          icon="ri-git-branch-line"
          :options="transactedOptions"
          v-model="filters.transacted"
        />
        <!-- Printed At -->
        <oms-filter-date-range
          :title="t('features.shipments.filters.printed_at')"
          icon="ri-printer-line"
          toLabel="To"
          fromLabel="From"
          v-model="filters.printed_at"
        />
        <!-- Shippable starts at -->
        <oms-filter-date-range
          :title="t('features.shipments.filters.shippable_starts_at')"
          icon="ri-map-pin-time-line"
          toLabel="To"
          fromLabel="From"
          v-model="filters.shippable_starts_at"
        />
        <!-- Shippable ends at -->
        <oms-filter-date-range
          :title="t('features.shipments.filters.shippable_ends_at')"
          icon="ri-map-pin-time-line"
          toLabel="To"
          fromLabel="From"
          v-model="filters.shippable_ends_at"
        />
        <!-- Box Brand -->
        <oms-filter-statuses
          :title="t('features.shipments.filters.subscription_box_brand')"
          icon="ri-stack-line"
          :statuses="boxBrands"
          v-model="filters.subscription_box_brands"
        />
        <!-- Shipping Method -->
        <oms-filter-statuses
          :title="t('features.shipments.filters.shipping_method')"
          icon="ri-ship-line"
          :statuses="shippingMethods"
          v-model="filters.shipping_methods"
        />

        <oms-filter-radio-buttons
          :title="t('features.shipments.filters.export_type')"
          icon="ri-newspaper-line"
          :options="exportType"
          v-model="filters.export_type"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-4 mr-auto">
        <!-- Test Shipping method print options -->
        <div v-if="isTestShippingSelected" class="form-group">
          <label class="col-form-label">
            {{ t('features.shipments.filters.shipping_method_print_option') }}
          </label>
          <select
            name="shipping_method_print_option"
            id="shipping_method_print_option"
            class="form-control"
            v-model="filters.shipping_method_print_option"
          >
            <option value="" selected="selected"></option>
            <option
              v-for="(option, index) in shippingMethodPrintOptions"
              :key="index"
              :value="option"
            >
              {{ option }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="card-body pt-0 pb-1 px-0">
      <oms-filter-content-basic
        :title="t('features.shipments.print_status')"
        default-value="all"
        :mapping="printStatusOptions"
        :modelValue="filters.status"
      />

      <oms-filter-content-basic
        :title="t('features.shipments.export_type')"
        default-value="all"
        :mapping="exportType"
        :modelValue="filters.export_type"
      />

      <oms-filter-content-basic
        :title="t('features.shipments.transacted_status')"
        default-value="all"
        :mapping="transactedOptions"
        :modelValue="filters.transacted"
      />
      <oms-filter-content-date-range
        :title="t('features.shipments.filters.printed_at')"
        :modelValue="filters.printed_at"
      />
      <oms-filter-content-date-range
        :title="t('features.shipments.filters.shippable_starts_at')"
        :modelValue="filters.shippable_starts_at"
      />
      <oms-filter-content-date-range
        :title="t('features.shipments.filters.shippable_ends_at')"
        :modelValue="filters.shippable_ends_at"
      />
      <oms-filter-content-statuses
        :title="t('features.shipments.filters.subscription_box_brand')"
        :statuses="boxBrands"
        v-model="filters.subscription_box_brands"
      />
      <oms-filter-content-statuses
        :title="t('features.shipments.filters.shipping_method')"
        :statuses="shippingMethods"
        v-model="filters.shipping_methods"
      />
    </div>

    <div class="row justify-content-start flex-row-reverse">
      <div class="col-lg-3 col-md-4">
        <div class="form-group">
          <label class="col-form-label">&nbsp;</label>
          <input type="submit" name="commit" value="Search" class="btn btn-block btn-primary" />
        </div>
      </div>
      <div class="col-lg-3 col-md-4">
        <div class="form-group">
          <label class="col-form-label">&nbsp;</label>
          <input
            type="submit"
            name="commit"
            value="Export CSV"
            class="btn btn-block btn-light"
            @click.prevent="handleSubmit('csv')"
          />
        </div>
      </div>
      <div class="col-lg-3 col-md-4">
        <div class="form-group">
          <label class="col-form-label">&nbsp;</label>
          <textarea
            name="csv"
            id="csv"
            class="sr-only"
            data-shipments-index-target="csvTextArea"
          ></textarea>
          <div class="d-flex align-items-center">
            <button
              name="button"
              type="button"
              class="flex-1 btn btn-light"
              data-action="shipments-index#copyCsv"
            >
              Copy Selected
            </button>
            <i
              class="ri-information-fill ml-2 text-muted"
              data-toggle="tooltip"
              title=""
              data-original-title="Copies checked shipments as a simple CSV, which shippers can paste into a Google Sheet for warehouse operations"
            ></i>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
