<template>
  <div class='mt-4 mb-4'>
    <div class="row">
      <div v-if="serverError" class="alert alert-danger" role="alert">
        {{ serverError }}
      </div>
      <div v-if="!productRequestStore.isUpdatable" class="alert alert-danger" role="alert">
        {{ t("features.product_request.uneditable_message") }}
      </div>
    </div>
    <div class="row mt-2 mb-2">
      <div class="col-sm-2">
        <h6>{{ t("activerecord.attributes.product_request.status") }}</h6>
      </div>
      <div class="col-sm-2">
        <span :class="'badge ' + productRequestStore.statusBadge">
          {{ t("features.product_request.status." + productRequestStore.status) }}
        </span>
        <span :class="'ml-1 badge ' + productRequestStore.paymentStatusBadge">
          {{ t("features.product_request.payment_status." + productRequestStore.paymentStatus) }}
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <search-suppliers :supplier="productRequestStore.supplier" :setSupplier="setSupplier" />
        <p class="text-danger">{{ productRequestStore.errors.supplier }}</p>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-sm-4">
        <search-supplier-boxes v-if="productRequestStore.supplier"
                               :supplierId="productRequestStore.supplierId"
                               :box="productRequestStore.box"
                               :setBox="setBox" />
      </div>
    </div>
    <div class="row form-group mt-4">
      <div class="col-sm-2">
        <label>
          <input type="checkbox" v-model="productRequestStore.shouldSendEmail">
          {{ t('features.product_request.send_email_to_supplier') }}
        </label>
      </div>
      <div class="col-sm-4">
        <label>
          <input type="checkbox" v-model="productRequestStore.isEachTimePayment">
          {{ t('features.product_request.each_time_payment') }}
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4 form-group">
        <label class="d-block" for="payment-type">
          {{ t('features.product_request.payment_type_title') }}
        </label>
        <select id="payment-type" v-model="productRequestStore.paymentType" class="form-control">
          <option disabled value="">Select payment type</option>
          <option value="prepaid">{{ t('features.product_request.payment_type.prepaid') }}</option>
          <option value="postpaid">{{ t('features.product_request.payment_type.postpaid') }}</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 form-group">
        <label class="d-block" for="additional-recipients">
          {{ t('features.product_request.additional_recipients.title') }}
        </label>
        <input id="additional-recipients" class="form-control" type="text"
               :placeholder="t('features.product_request.additional_recipients.placeholder')"
               v-model="productRequestStore.additionalRecipients" />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 form-group">
        <label class="d-block" for="address">
          {{ t('features.product_request.address') }}
        </label>
        <select id="address" class="form-control" v-model="productRequestStore.address">
          <option v-for="destination in productRequestStore.destinations" :value="destination.value"
                  :disabled="!destination.enabled">
            {{ destination.address }}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <label class="d-block" for="comment">
          {{ t('features.product_request.comment') }}
        </label>
        <textarea class="form-control" v-model="productRequestStore.comment" placeholder="Comment.."></textarea>
      </div>
    </div>
    <div class="row" v-if="productRequestStore.id">
      <div class="col-sm-8">
        <hr />
        <h5>
          {{ t('features.product_request.delivery_plan_batches.title') }}:
        </h5>
        <div class='mt-4 mb-4'>
          <delivery-plan-batch v-for='(batch, i) in productRequestStore.deliveryPlanBatches'
                               :key="batch.expected_delivery_at"
                               :index="i" :batch="batch" :deleteBatch='productRequestStore.deleteBatch'
                               :select-expected-delivery-at="productRequestStore.selectExpectedDeliveryAt"
                               :can-delete-delivery-plan-batch="productRequestStore.canDeleteDeliveryPlanBatch" />
          <button type="button" class="btn btn-secondary align-self-center ml-2"
                  @click="productRequestStore.addNewDeliveryPlanBatch()">
            {{ t('features.product_request.delivery_plan_batches.add_new_batch') }}
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-4" v-if="productRequestStore.supplierId">
      <div class="col-sm-12">
        <table class="table">
          <thead>
          <tr>
            <th width="10%">{{ t('activerecord.attributes.product_request_item.product') }}</th>
            <th width="10%">{{ t('activerecord.attributes.product_request_item.price_per_item') }}</th>
            <th width="10%">{{ t('activerecord.attributes.product_request_item.product_variant') }}</th>
            <th width="10%">{{ t('activerecord.attributes.product_request_item.stock_destination') }}</th>
            <th width="10%">{{ t('activerecord.attributes.product_request_item.requested_quantity') }}</th>
            <th width="10%">{{ t('activerecord.attributes.product_request_item.requested_delivery_at') }}</th>
            <th width="10%">{{ t('activerecord.attributes.product_request_item.tax_rate') }}</th>
            <th width="10%">{{ t('activerecord.attributes.product_request_item.comment') }}</th>
            <th width="10%">{{ t('activerecord.attributes.product_request_item.internal_comment') }}</th>
            <th width="10%"></th>
          </tr>
          </thead>
          <tbody>
            <product-request-item-update v-for="(item, index) in productRequestStore.productRequestItems"
                                         :key="item.id || item.product_supplier_id" :index="index"
                                         :productRequestId="productRequestStore.id"
                                         :allDeliveryPlanBatches="productRequestStore.deliveryPlanBatches"
                                         :productRequestItem="item"
                                         :supplierId="productRequestStore.supplierId"
                                         :delete-product-request-item="productRequestStore.deleteProductRequestItem"
                                         :removedPlannedBatchItems="productRequestStore.removedPlannedBatchItems"
                                         :errors="productRequestStore.itemValidationErrors(index)"
                                         :boxId="productRequestStore?.box?.id"
            />
          </tbody>
        </table>
        <div class="d-flex justify-content-start">
          <button class="btn btn-outline-secondary m-1 pl-5 pr-5" type="button"
                  @click="productRequestStore.addNewProductRequestItem">
            {{ t('features.product_request.new_product') }}
          </button>
        </div>

      </div>
    </div>
    <hr />
    <div class="row mt-4 align-items-center">
      <div class="col-sm-4">
        <button :disabled="!productRequestStore.isUpdatable" class="btn btn-primary mb-1 w-100"
                type="button" @click="updateProductRequest">
          {{ t('actions.save') }}
        </button>
        <a class="btn btn-info w-100"
           :href="`/product_requests/${productRequestStore.id}/preview`">
          {{ t('features.product_request.preview_product_request') }}
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div v-if="productRequestStore.canSendToDirectors" class="mt-2 mb-2 row justify-content-center">
          <div class="col-sm-6">
            <input class="form-control mb-1" type="text" v-model="productRequestStore.directorEmails"
                   :placeholder="t('features.product_request.should_submit_email_to_directors.place_holder')">
          </div>
          <div class="col-sm-6">
            <button class="btn btn-warning w-100" type="button"
                    :disabled="productRequestStore.shouldSubmitEmailToDirectors || !productRequestStore.isUpdatable"
                    @click="handleShouldSubmitEmailChange">
              {{ t('features.product_request.should_submit_email_to_directors.submit') }}
            </button>
          </div>
        </div>
      </div>
      <div v-if="loading" class="spinner-border" role="status" d-block>
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
import SearchSuppliers from './components/product-requests/search-suppliers.vue'
import SearchSupplierBoxes from './components/product-requests/search-supplier-boxes.vue'
import ProductRequestItemUpdate from './components/product-requests/product-request-item-update.vue'
import ValidatableInput from './validatable-input.vue'
import DeliveryPlanBatch from './components/product-requests/delivery-plans/delivery-plan-batch.vue'
import {computed} from "vue";
import {mapStores} from "pinia";
import {useProductRequestStore} from "./stores/productRequestStore";
import {values} from "lodash";

export default {
  inject: ['i18n'],
  components: {SearchSuppliers, SearchSupplierBoxes, ProductRequestItemUpdate, ValidatableInput, DeliveryPlanBatch},
  data() {
    return {
      t: (key, options) => this.i18n.t(key, options),
      loading: false,
      serverError: ""
    }
  },

  provide() {
    return {
      translate: computed(() => this.t)
    }
  },

  computed: {
    ...mapStores(useProductRequestStore)
  },

  async mounted() {
    await this.fetchRequest(this.requestId())

    await this.$nextTick(function () {
      this.productRequestStore.addDefaultDeliveryPlanFromRequestedDates()
    })
  },

  methods: {
    setSupplier(newSupplier) {
      this.productRequestStore.supplier = newSupplier
    },
    setBox(box) {
      this.productRequestStore.box = box
    },
    requestId() {
      const regex = /\/product_requests\/(\d+)\/edit/
      return window.location.pathname.match(regex)?.[1]
    },
    async fetchRequest(id) {
      try {
        await this.productRequestStore.fetchProductRequest(id)
      } catch (e) {
        console.error(e.message)
      }
    },
    async updateProductRequest() {
      this.loading = true
      try {
        const result = await this.productRequestStore.update()
        if (values(this.productRequestStore.errors).length > 0) {
          alert(values(this.productRequestStore.errors))
          this.loading = false
          return
        }
        if (result?.error) {
          this.serverError = result.error
          alert(this.serverError)
        } else {
          this.serverError = ""
          window.location.href = `/product_requests/${this.productRequestStore.id}/preview`
        }
      } catch (e) {
        this.serverError = e
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    async handleShouldSubmitEmailChange() {
      if (confirm(this.t('features.product_request.confirm_to_send_email_to_directors'))) {
        this.productRequestStore.shouldSubmitEmailToDirectors = true
        await this.updateProductRequest()
        // Re-enabled the submit email button even if validations complain something.
        if (this.serverError) {
          this.productRequestStore.shouldSubmitEmailToDirectors = false
        }
      }
    }
  }
}
</script>