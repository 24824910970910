$.onmount('[data-component=product-select]', function() {
  var $el = $(this);
  var $pricePerItemField = $el.closest('[data-component=product-row]').find('[name*=price_per_item]')

  function onLoad(query, callback) {
    var supplierId = $('#product_request_supplier_id').val();

    $.ajax({
      url: '/product_requests/search_products',
      dataType: 'JSON',
      data: {
        keyword: query,
        supplier_id: supplierId,
      },
      success: callback,
      error: callback,
    });
  }

  $el.selectize({
    load: onLoad,
    valueField: 'id',
    labelField: 'name',
    searchField: ['name', 'barcode', 'sku'],
    options: [],
    create: false,
    preload: 'focus',
    placeholder: 'Search products here..'
  });

  var preload = $el.data('preload');

  if (preload) {
    $el[0].selectize.addOption(preload);
    $el[0].selectize.setValue(preload.id, true);
    $pricePerItemField.attr('value', preload.buy_price)
  }

  $el.on('change', function(e) {
    $pricePerItemField.val(this.selectize.options[this.selectize.items[0]].buy_price)
  })
});
