<template>
  <div class="card mb-4">
    <div class="card-body pb-3 d-flex flex-wrap">
      <oms-filter-plans
        :plan-types="planTypes"
        :plan-periods="planPeriods"
        :selected-plan-types="filters.plan_type"
        :selected-plan-periods="filters.plan_period"
        @input-types="filters.plan_type = $event"
        @input-periods="filters.plan_period = $event"
      />
      <oms-filter-statuses :statuses="statuses" v-model="filters.status" />
      <oms-filter-statuses
        title="Shipment status"
        :statuses="shipmentStatuses"
        v-model="filters.shipment_status"
      >
        <p>
          Filters by the status of the most recent <strong>Order Shipment</strong> (if the order has
          been printed). Use "Status" to filter by the status of the order itself.
        </p>
      </oms-filter-statuses>
      <oms-filter-statuses
        title="Shipping method"
        icon="ri-ship-line"
        :statuses="shippingMethods"
        v-model="filters.shipping_method_ids"
      />
      <oms-filter-statuses
        title="Conversion platform"
        icon="ri-guide-line"
        :statuses="conversionPlatforms"
        v-model="filters.conversion_platform"
      />
      <oms-filter-statuses
        title="Subscription status"
        icon="ri-git-branch-line"
        :statuses="subscriptionStatuses"
        v-model="filters.subscription_status"
      />
      <oms-filter-radio-buttons
        title="First invoice"
        icon="ri-refresh-line"
        :options="firstInvoiceOptions"
        v-model="filters.first_invoice"
      />
      <oms-filter-radio-buttons
        title="Gift"
        icon="ri-gift-line"
        :options="giftOptions"
        v-model="filters.gift"
      />
      <oms-filter-radio-buttons
        title="Blacklisted"
        icon="ri-forbid-line"
        :options="blacklistedOptions"
        v-model="filters.blacklisted"
      />
      <oms-filter-statuses
        title="Risks"
        icon="ri-alarm-warning-line"
        :statuses="orderRisks"
        v-model="filters.order_risks"
      />
      <oms-filter-statuses
        title="Dispute"
        icon="ri-refund-line"
        :statuses="invoiceDisputeReasons"
        v-model="filters.invoice_dispute_reasons"
      />
      <oms-filter-statuses
        title="Source"
        icon="ri-at-line"
        :statuses="sources"
        v-model="filters.source_id"
      />
      <oms-filter-fetch-autocomplete
        title="Coupon"
        icon="ri-coupon-line"
        :fetch="{ url: '/addons/search_coupon.json', responseField: 'coupons' }"
        v-model="filters.coupons"
      />
      <oms-filter-autocomplete
        title="Country"
        icon="ri-earth-line"
        :options="countries"
        v-model="filters.country"
      />
      <oms-filter-autocomplete
        title="State Code"
        :options="stateCodes"
        v-model="filters.state_code"
      >
        <div class="alert alert-warning">Only usable for US and CA</div>
      </oms-filter-autocomplete>
      <oms-filter-radio-buttons
        title="Has addons"
        icon="ri-links-fill"
        :options="hasAddonsOptions"
        v-model="filters.has_addons"
      />
      <oms-filter-fetch-autocomplete
        title="Addons"
        :fetch="{ url: '/addons/search.json', responseField: 'addons' }"
        v-model="filters.addons"
      />
      <oms-filter-fetch-autocomplete
        title="Addon Groups"
        :fetch="{ url: '/addon_groups/search.json', responseField: 'addon_groups' }"
        v-model="filters.addon_groups"
      />
      <oms-filter-tags :tags="tags" v-model="filters.tag_filters" />
      <oms-filter-date-range
        title="Shippable at"
        icon="ri-map-pin-time-line"
        v-model="filters.shippable_at"
      />
      <oms-filter-date-range
        title="Last printed at"
        icon="ri-printer-line"
        v-model="filters.printed_at"
      >
        <p>
          The <strong>most recent time</strong> the order was printed. For returned and reshipped
          orders, this is the date of the reshipment after the box was returned.
        </p>
      </oms-filter-date-range>
      <oms-filter-date-range
        title="Originally printed at"
        icon="ri-printer-line"
        v-model="filters.original_printed_at"
      >
        <p>
          The <strong>first time</strong> the order was printed. For returned and reshipped orders,
          this is the date of the original shipment before the box was returned.
        </p>
      </oms-filter-date-range>
      <oms-filter-date-range
        title="Current term start"
        icon="ri-calendar-event-line"
        v-model="filters.current_term_start"
      />
      <oms-filter-statuses
        title="Shipping invoice status"
        :statuses="shippingInvoiceStatuses"
        v-model="filters.shipping_invoice_status"
        color="secondary"
      />
      <oms-filter-date-range
        title="Invoice paid at"
        icon="ri-money-dollar-box-line"
        v-model="filters.invoice_paid_at"
      >
        <p>
          For ChargeBee orders this indicates the date the ChargeBee invoice was paid (note:
          renewal invoices may fail to charge initially so the "invoice paid" may end up later
          than the "shippable at" which is based on the invoice creation date).
        </p>
        <p>
          For non-ChargeBee orders this is the same as "shippable at".
        </p>
      </oms-filter-date-range>
      <oms-filter-date-range
        title="Transaction date"
        icon="ri-calendar-check-line"
        v-model="filters.transaction_date"
      />
      <oms-filter-ids title="Order IDs" v-model="filters.order_ids" />

      <div class="form-inline mr-2 mb-2 ml-auto">
        <input
          class="form-control"
          v-model="filters.keyword"
          @keyup.enter="submit"
          type="text"
          placeholder="Search ..."
        />
      </div>

      <button class="btn btn-primary mr-2 mb-2" @click="submit" :disabled="isLoading || !isDirty">
        <span class="ri-search-line"></span> Search
      </button>

      <order-actions
        :filters="filters"
        :disabled="isDirty"
        :shipping-methods="shippingMethodsForPrinting"
        :hold-status-reasons="holdStatusReasons"
        :current-tags="tags"
        :total-results="totalResults"
      />
    </div>

    <div class="card-body pt-0 pb-1">
      <oms-filter-content-plans
        :plan-types="planTypes"
        :plan-periods="planPeriods"
        :selected-plan-types="filters.plan_type"
        :selected-plan-periods="filters.plan_period"
      />
      <oms-filter-content-statuses :statuses="statuses" :modelValue="filters.status" />
      <oms-filter-content-statuses
        title="Shipment status"
        :statuses="shipmentStatuses"
        v-model="filters.shipment_status"
      />
      <oms-filter-content-statuses
        title="Shipment method"
        :statuses="shippingMethods"
        v-model="filters.shipping_method_ids"
      />
      <oms-filter-content-statuses
        title="Conversion platform"
        :statuses="conversionPlatforms"
        v-model="filters.conversion_platform"
      />
      <oms-filter-content-statuses
        title="Subscription status"
        :statuses="subscriptionStatuses"
        v-model="filters.subscription_status"
      />
      <oms-filter-content-basic
        title="First invoice"
        default-value="all"
        :mapping="firstInvoiceOptions"
        :modelValue="filters.first_invoice"
      />
      <oms-filter-content-basic
        title="Gift"
        default-value="all"
        :mapping="giftOptions"
        :modelValue="filters.gift"
      />
      <oms-filter-content-basic
        title="Blacklisted"
        default-value="all"
        :mapping="blacklistedOptions"
        :modelValue="filters.blacklisted"
      />
      <oms-filter-content-statuses
        title="Risks"
        :statuses="orderRisks"
        v-model="filters.order_risks"
      />
      <oms-filter-content-statuses
        title="Dispute"
        :statuses="invoiceDisputeReasons"
        :modelValue="filters.invoice_dispute_reasons"
      />
      <oms-filter-content-statuses
        title="Source"
        :statuses="sources"
        :modelValue="filters.source_id"
      />
      <oms-filter-content-named-items title="Coupon" :modelValue="filters.coupons" />
      <oms-filter-content-statuses
        title="Country"
        :statuses="countries"
        :modelValue="filters.country"
      />
      <oms-filter-content-statuses
        title="State Code"
        :statuses="stateCodes"
        :modelValue="filters.state_code"
      />
      <oms-filter-content-basic
        title="Addons"
        default-value="all"
        :mapping="hasAddonsOptions"
        :modelValue="filters.has_addons"
      />
      <oms-filter-content-named-items title="Addon Group" :modelValue="filters.addon_groups" />
      <oms-filter-content-named-items title="Addon" :modelValue="filters.addons" />
      <oms-filter-content-tags :modelValue="filters.tag_filters" />
      <oms-filter-content-date-range title="Shippable at" :modelValue="filters.shippable_at" />
      <oms-filter-content-date-range title="Last printed at" :modelValue="filters.printed_at" />
      <oms-filter-content-date-range
        title="Originally printed at"
        :modelValue="filters.original_printed_at"
      />
      <oms-filter-content-date-range
        title="Current term start"
        :modelValue="filters.current_term_start"
      />
      <oms-filter-content-date-range
        title="Invoice paid at"
        :modelValue="filters.invoice_paid_at"
      />
      <oms-filter-content-date-range
        title="Transaction date"
        :modelValue="filters.transaction_date"
      />
    </div>
  </div>
</template>

<script>
import selectedOrderIds from 'javascripts/stores/orders/selected-ids'
import buildQueryString from '../../../helpers/query-builder'
import OmsFilterContentBasic from '../filter-content/basic.vue'
import OmsFilterContentDateRange from '../filter-content/date-range.vue'
import OmsFilterContentNamedItems from '../filter-content/named-items.vue'
import OmsFilterContentPlans from '../filter-content/plans.vue'
import OmsFilterContentStatuses from '../filter-content/statuses.vue'
import OmsFilterContentTags from '../filter-content/tags.vue'
import OmsFilterAutocomplete from '../filter/autocomplete.vue'
import OmsFilterDateRange from '../filter/date-range.vue'
import OmsFilterFetchAutocomplete from '../filter/fetch-autocomplete.vue'
import OmsFilterIds from '../filter/ids.vue'
import OmsFilterPlans from '../filter/plans.vue'
import OmsFilterRadioButtons from '../filter/radio-buttons.vue'
import OmsFilterStatuses from '../filter/statuses.vue'
import OmsFilterTags from '../filter/tags.vue'
import OrderActions from '../order-actions.vue'

import {mapActions, mapState} from 'pinia'
import {useOrdersStore} from '../../stores/orders'

export default {
  name: 'order-filters',
  props: {
    initialData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    // Fix reactivity by setting default values for all filters
    this.initialData.filters = {
      plan_type: {},
      plan_period: {},
      status: {},
      shipment_status: {},
      shipping_method_ids: {},
      conversion_platform: {},
      subscription_status: {},
      first_invoice: null,
      gift: null,
      blacklisted: null,
      order_risks: {},
      invoice_dispute_reasons: {},
      source_id: {},
      coupons: {},
      country: {},
      state_code: {},
      has_addons: null,
      addon_groups: {},
      addons: {},
      tag_filters: {},
      shippable_at: null,
      printed_at: null,
      original_printed_at: null,
      current_term_start: null,
      shipping_invoice_status: {},
      invoice_paid_at: null,
      transaction_date: null,
      order_ids: {},
      keyword: '',
      ...this.initialData.filters
    }

    return {
      ...this.initialData,
      isDirty: false
    }
  },

  watch: {
    filters: {
      deep: true,
      handler() {
        this.isDirty = true
      }
    }
  },

  computed: {
    ...mapState(useOrdersStore, {
      isLoading: 'isLoading',
      totalResults: 'getTotalResults'
    }),

    searchUrl() {
      return `${this.baseUrl}?${this.filterQueryString}`
    },

    firstInvoiceOptions() {
      return [
        {id: 'all', name: 'All'},
        {id: '1', name: 'First Invoice'},
        {id: '0', name: 'Renewal'}
      ]
    },

    giftOptions() {
      return [
        {id: 'all', name: 'All'},
        {id: '1', name: 'Gift'},
        {id: '0', name: 'Non-gift'}
      ]
    },

    blacklistedOptions() {
      return [
        {id: 'all', name: 'All'},
        {id: '1', name: 'Blacklisted'},
        {id: '0', name: 'Not blacklisted'}
      ]
    },

    hasAddonsOptions() {
      return [
        {id: 'all', name: 'All'},
        {id: '1', name: 'Addons'},
        {id: '0', name: 'No addons'}
      ]
    },

    filterQueryString() {
      return buildQueryString(this.filters, 'q')
    }
  },

  methods: {
    ...mapActions(useOrdersStore, ['fetchOrders']),

    submit() {
      this.isDirty = false

      this.fetchOrders(this.filterQueryString).then(() => {
        window.history.pushState('', '', this.searchUrl.replace('.json', ''))
        this.refresh()
      })
    },

    refresh() {
      selectedOrderIds.toArray().forEach((id) => {
        document
          .querySelectorAll('tr[data-id=' + id + '] > td:first-child input[type=checkbox]')
          .forEach((element) => {
            element.checked = true
          })
      })

      $.onmount()
    }
  },

  components: {
    OmsFilterPlans,
    OmsFilterAutocomplete,
    OmsFilterStatuses,
    OmsFilterRadioButtons,
    OmsFilterFetchAutocomplete,
    OmsFilterTags,
    OmsFilterDateRange,
    OmsFilterIds,
    OrderActions,
    OmsFilterContentPlans,
    OmsFilterContentBasic,
    OmsFilterContentNamedItems,
    OmsFilterContentTags,
    OmsFilterContentDateRange,
    OmsFilterContentStatuses
  }
}
</script>
