<script setup>
import { storeToRefs } from 'pinia'
import { computed, inject, watch, ref } from 'vue'
import { useProductInvoiceStore } from '../../../stores/productInvoiceStore'
import SearchSuppliers from '../../product-requests/search-suppliers'
import LineItem from './line-item'
import Filters from './filters'
import FlatPickr from 'vue-flatpickr-component'

const i18n = inject('i18n')
const t = (key, options) => i18n.t(key, options)

const store = useProductInvoiceStore()

// Store getters
const { getProductInvoiceDraft, getSupplier, getPaymentTypeOptions, getPaymentType } =
  storeToRefs(store)
// Store actions
const { setSupplier, setInvoiceImages, fetchProducts, handleSubmit, resetSelectedLineItems } = store

const isLoading = ref(false)

const productInvoiceDraft = computed(() => getProductInvoiceDraft.value)
const supplier = computed(() => getSupplier.value)
const paymentType = computed(() => getPaymentType.value)
const paymentTypeOptions = computed(() => getPaymentTypeOptions.value)
const productInvoiceGroupedLineItems = ref({})
// Mutable version of productInvoiceGroupedLineItems for manipulation.
const mutableProductInvoiceGroupedLineItems = ref({})

// Flatpickr config
const config = computed(() => {
  return {
    dateFormat: 'Y/m/d H:i',
    altFormat: 'Y/m/d H:i',
    altInput: true,
    enableTime: true,
    enableSeconds: false,
    time_24hr: true,
    defaultHour: 0,
    defaultMinute: 0
  }
})

// Fetch product line items if supplier changes.
watch(supplier, async (newSupplier) => {
  if (newSupplier) await loadProductLineItems(paymentType.value, newSupplier.id)
})

// Fetch product line items and clear selected items if payment type changes.
watch(paymentType, async (newPaymentType) => {
  if (newPaymentType) {
    await loadProductLineItems(newPaymentType, supplier.value.id)
    resetSelectedLineItems()
  }
})

async function loadProductLineItems(payType, supplier_id) {
  isLoading.value = true
  const products = await fetchProducts(payType, supplier_id)

  // Set 2 copies of the products, one for manipulation and one for the original.
  productInvoiceGroupedLineItems.value = products
  mutableProductInvoiceGroupedLineItems.value = products

  isLoading.value = false
}

function handleSearch(keyword) {
  isLoading.value = true
  // Simulate a delay in fetching data
  setTimeout(() => {
    mutableProductInvoiceGroupedLineItems.value = {}

    if (keyword === '') {
      mutableProductInvoiceGroupedLineItems.value = productInvoiceGroupedLineItems.value
      isLoading.value = false
      return
    }

    const filteredGroupedLineItems = Object.keys(productInvoiceGroupedLineItems.value).reduce(
      (acc, key) => {
        const group = productInvoiceGroupedLineItems.value[key]
        const filteredProducts = group.filter((product) => {
          return (
            product.full_product_name.toLowerCase().includes(keyword.trim().toLowerCase()) ||
            product.product_sku.toLowerCase().includes(keyword.trim().toLowerCase())
          )
        })
        acc[key] = filteredProducts
        return acc
      },
      {}
    )

    mutableProductInvoiceGroupedLineItems.value = filteredGroupedLineItems
    isLoading.value = false
  }, 500)
}
</script>

<template>
  <form class="new_product_invoice" id="new_product_invoice" @submit.prevent="handleSubmit">
    <!-- Status -->
    <input
      type="hidden"
      :value="productInvoiceDraft.status"
      name="status"
      id="product_invoice_status"
    />
    <!-- Invoice registration number -->
    <div class="form-group">
      <label for="invoice_registration_number">Invoice Registration Number</label>
      <input
        class="form-control"
        type="text"
        v-model="productInvoiceDraft.invoice_registration_number"
        name="invoice_registration_number"
        id="invoice_registration_number"
      />
    </div>
    <!-- Supplier Select -->
    <div class="form-group supplier-select">
      <search-suppliers :supplier="supplier" :setSupplier="setSupplier" :allow-empty="true" />
    </div>
    <!-- Payment Type -->
    <div class="form-group">
      <label for="product_invoice_payment_type">Payment Type</label>
      <select
        class="form-control"
        name="payment_type"
        id="product_invoice_payment_type"
        v-model="productInvoiceDraft.payment_type"
      >
        <option value="null">Select Payment Type</option>
        <template v-for="option in paymentTypeOptions" :key="option.type">
          <option :value="option.type" :selected="option.type === paymentType">
            {{ option.label }}
          </option>
        </template>
      </select>
    </div>
    <!-- Invoice Date -->
    <div class="form-group">
      <label for="product_invoice_invoice_date">Invoice Date</label>
      <flat-pickr
        class="form-control"
        placeholder="Select invoice date"
        name="invoice_date"
        id="product_invoice_invoice_date"
        v-model="productInvoiceDraft.invoice_date"
        :config="config"
      />
    </div>
    <!-- Subtotal -->
    <div class="form-group">
      <label for="product_invoice_subtotal">Subtotal</label>
      <input
        step="any"
        class="form-control"
        type="number"
        v-model="productInvoiceDraft.subtotal"
        name="subtotal"
        id="product_invoice_subtotal"
      />
    </div>
    <!-- Total Tax -->
    <div class="form-group">
      <label for="product_invoice_total_tax">Total Tax</label>
      <input
        step="any"
        class="form-control"
        type="number"
        v-model="productInvoiceDraft.total_tax"
        name="total_tax"
        id="product_invoice_total_tax"
      />
    </div>
    <!-- Total -->
    <div class="form-group">
      <label for="product_invoice_total">Total</label>
      <input
        step="any"
        class="form-control"
        type="number"
        v-model="productInvoiceDraft.total"
        name="total"
        id="product_invoice_total"
      />
    </div>
    <!-- Adjustment Price -->
    <div class="form-group">
      <label for="product_invoice_adjustment_price">Adjustment price</label>
      <input
        step="any"
        class="form-control"
        type="number"
        v-model="productInvoiceDraft.adjustment_price"
        name="adjustment_price"
        id="product_invoice_adjustment_price"
      />
    </div>
    <!-- Adjustment Reason -->
    <div class="form-group">
      <label for="product_invoice_adjustment_reason">Adjustment reason</label>
      <input
        class="form-control"
        type="text"
        v-model="productInvoiceDraft.adjustment_reason"
        name="adjustment_reason"
        id="product_invoice_adjustment_reason"
      />
    </div>
    <!-- Shipping Fee -->
    <div class="form-group">
      <label for="product_invoice_shipping_fee">Shipping Fee</label>
      <input
        step="any"
        class="form-control"
        type="number"
        v-model="productInvoiceDraft.shipping_fee"
        name="shipping_fee"
        id="product_invoice_shipping_fee"
      />
    </div>
    <!-- Invoice Images -->
    <div class="form-group">
      <label for="product_invoice_invoice_images">Invoice Images</label>
      <input
        multiple="multiple"
        class="form-control-file"
        type="file"
        name="invoice_images"
        id="product_invoice_invoice_images"
        @change="setInvoiceImages($event.target.files)"
      />
    </div>
    <!-- Comment -->
    <div class="form-group">
      <label for="product_invoice_comment">Comment</label>
      <textarea
        class="form-control"
        name="comment"
        id="product_invoice_comment"
        v-model="productInvoiceDraft.comment"
      ></textarea>
    </div>
    <hr />
    <!-- Product filters -->
    <Filters @handle-search="handleSearch" />
    <div class="loading-area" :class="isLoading ? '-loading' : ''">
      <div class="spinner-border text-secondary mt-5"></div>
      <table class="table">
        <thead>
          <tr>
            <th width="5%"></th>
            <th width="35%">Product</th>
            <th width="15%">Payment Type</th>
            <th width="10%">Quantity</th>
            <th width="20%">Planned Delivery Date</th>
            <th width="20%">Comment</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="mutableProductInvoiceGroupedLineItems.length === 0">
            <tr>
              <td colspan="5">{{ t('features.product_invoice.no_product_request_items') }}</td>
            </tr>
          </template>
          <template
            v-else
            v-for="(groupItems, key, _) in mutableProductInvoiceGroupedLineItems"
            :key="key"
          >
            <tr class="font-weight-bold font-size-md">
              <td></td>
              <td>
                <a :href="`/product_requests/${key}`" target="_blank">
                  {{
                    t('activerecord.models.product_request.one', { default: 'Product Request' }) +
                    `: ${key}`
                  }}
                </a>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <template v-for="(lineItem, index) in groupItems" :key="lineItem.id">
              <LineItem :data="lineItem" :index="index" />
            </template>
          </template>
        </tbody>
      </table>
    </div>
    <br />
    <hr />
    <div class="actions">
      <input
        type="submit"
        name="commit"
        :value="t('actions.next')"
        class="btn btn-block btn-primary btn-lg"
        :disabled="store.disabled"
      />
    </div>
  </form>
</template>