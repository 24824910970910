<template>
  <oms-filter-dropdown
    :title="title"
    :icon="icon"
    :is-filtering="Boolean(modelValue && (modelValue.starts_at || modelValue.ends_at))"
    width="580px"
  >
    <div class="text-muted">
      <slot />
    </div>
    <div class="d-flex">
      <div class="form-group flex mb-0 mr-3">
        <label>{{ fromLabel }}</label>
        <flat-pickr
          class="form-control flatpickr-compact d-lg-none"
          :config="config"
          :modelValue="formattedStartsAtDate"
          @on-change="updateStartDate($event)"
        />
        <button
          type="button"
          class="btn btn-light btn-sm mt-2"
          @click="$emit('update:modelValue', { starts_at: undefined })"
        >
          Clear
        </button>
      </div>
      <div class="form-group flex mb-0">
        <label>{{ toLabel }}</label>
        <flat-pickr
          class="form-control flatpickr-compact d-lg-none"
          :config="config"
          @on-change="updateEndDate($event)"
          :modelValue="formattedEndsAtDate"
        />
        <button
          type="button"
          class="btn btn-light btn-sm mt-2"
          @click="$emit('update:modelValue', { ...modelValue, ends_at: undefined })"
        >
          Clear
        </button>
      </div>
    </div>
  </oms-filter-dropdown>
</template>

<script>
import FlatPickr from 'vue-flatpickr-component'
import OmsFilterDropdown from '../filter-dropdown.vue'

const dateFormat = Intl.DateTimeFormat('en', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  hourCycle: 'h23'
})

export default {
  props: {
    title: String,
    icon: String,
    toLabel: {
      type: String,
      default: 'Ends at'
    },
    fromLabel: {
      type: String,
      default: 'Starts at'
    },
    modelValue: Object
  },

  computed: {
    config() {
      return {
        enableTime: true,
        enableSeconds: false,
        time_24hr: true,
        defaultHour: 0,
        defaultMinute: 0,
        // flatpickr doesn't work well on phones, so just use the system date picker there
        inline: !/Android|iPhone/i.test(navigator.userAgent)
      }
    },

    formattedStartsAtDate() {
      return this.modelValue?.starts_at && new Date(this.modelValue.starts_at)
    },

    formattedEndsAtDate() {
      return this.modelValue?.ends_at && new Date(this.modelValue.ends_at)
    }
  },

  components: { FlatPickr, OmsFilterDropdown },

  methods: {
    updateStartDate(date) {
      if (date[0]?.getTime() == this.formattedStartsAtDate?.getTime()) return

      const startsAt = this.formatDate(date[0])

      this.$emit('update:modelValue', { ...this.modelValue, starts_at: startsAt })
    },

    updateEndDate(date) {
      if (date[0]?.getTime() == this.formattedEndsAtDate?.getTime()) return

      const endsAt = this.formatDate(date[0])

      this.$emit('update:modelValue', { ...this.modelValue, ends_at: endsAt })
    },

    formatDate(date) {
      const datePartsArray = dateFormat.formatToParts(date)
      const findPart = (part) => datePartsArray.find(({ type }) => type === part).value
      return `${findPart('year')}-${findPart('month')}-${findPart('day')} ${findPart(
        'hour'
      )}:${findPart('minute')}`
    }
  }
}
</script>
