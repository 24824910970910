<template>
  <div>
    <multiselect v-model="selectedProduct" track-by="id" label="name" :show-labels="false" :options="products"
                 :searchable="true" :loading="isLoading" :internal-search="true" :allow-empty="false"
                 @search-change="asyncFind"
                 @select="handleProductSelection">
      <template slot="option" slot-scope="props">
        <div class="option__desc">
          <div class="option__title text-wrap">{{ props.option.name }}</div>
        </div>
      </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import {omsCsrfHeader} from "../../fetch-utils";

export default {
  props: {
    supplierId: {
      required: false,
      type: Number
    },
    productRequestItem: {
      required: true,
      type: Object
    },
    handleProductSelection: {
      required: true,
      type: Function
    }
  },

  components: {Multiselect},

  data() {
    const {product, product_supplier} = this.productRequestItem
    return {
      selectedProduct: {
        id: product_supplier?.id,
        name: product?.name,
        barcode: product?.barcode,
        sku: product?.sku,
        buy_price: product_supplier?.buy_price
      },
      products: [
        {
          id: product_supplier?.id,
          name: product?.name,
          barcode: product?.barcode,
          sku: product?.sku,
          buy_price: product_supplier?.buy_price
        }
      ],
      isLoading: false
    }
  },

  methods: {
    async asyncFind(query) {
      this.isLoading = true
      try {
        const products = await fetch(`/product_requests/search_products?keyword=${query}&supplier_id=${this.supplierId}`, {
          headers: omsCsrfHeader()
        })
        this.products = await products.json()
      } catch (err) {
        console.error(err)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

