<template>
  <div class='d-flex flex-wrap align-items-center my-2' v-if='isFiltering'>
    <strong class='mr-2'>{{ title }}</strong>
    <slot></slot>
  </div>
</template>

<script>

export default {
  props: {
    title: String,
    isFiltering: Boolean
  },
}
</script>
