import {createApp} from 'vue'
import UpcomingDeliveries from './components/upcoming-deliveries.vue'
import i18nPlugin, {i18n, loadTranslations} from "./i18n";
import {createPinia} from "pinia";

$.onmount('[data-vue=upcoming-deliveries]', async function () {
    const initialData = JSON.parse(this.attributes['data-initial-data'].value)
    const pinia = createPinia()
    const app = createApp(UpcomingDeliveries, {initialData})
    app.use(pinia)
    app.use(i18nPlugin)
    await loadTranslations(i18n)
    app.mount(this)
})
