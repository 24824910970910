$.onmount('[data-toggle=tokyo-catch-clear-selected]', function () {
    var $el = $(this)

    $el.on('click', function (e) {
        e.preventDefault()
        sessionStorage.shopifyOrders = JSON.stringify([])
        $('[data-role=tokyo-catch-actions]').addClass('d-none')
        $('.orders th input[type=checkbox]').prop('checked', false)
        $('.orders tr > td:first-child input[type=checkbox]').prop('checked', false)
        $('[role=tokyo-catch-search-list] [component="shopify/order_table"] tr').removeClass('active')
    })
})

