import { defineStore } from 'pinia'
import { omsCsrfHeader } from '../fetch-utils'

export const useProductInvoiceStore = defineStore('product-invoices', {
  state: () => ({
    productInvoiceDraft: {},
    supplier: null,
    paymentTypeOptions: [],
    selectedLineItems: [],
    errors: null,
    disabled: false
  }),

  getters: {
    getProductInvoiceDraft: (state) => state.productInvoiceDraft,
    getSupplier: (state) => state.supplier,
    getPaymentType: (state) => state.productInvoiceDraft.payment_type,
    getPaymentTypeOptions: (state) => state.paymentTypeOptions,
    getSelectedLineItems: (state) => state.selectedLineItems,
    getErrors: (state) => state.errors,

    hasErrors: (state) => state.errors !== null
  },

  actions: {
    setInitialState(data) {
      const initialData = data.value
      Object.keys(initialData).forEach((key) => {
        this[key] = initialData[key]
      })
    },
    setErrors(errors) {
      this.errors = errors
    },
    setSupplier(supplier) {
      // Set the supplier and the default payment type
      this.supplier = supplier
      this.productInvoiceDraft.supplier_id = supplier.id
      this.productInvoiceDraft.payment_type = supplier.default_payment_type
    },
    setInvoiceImages(filesList) {
      this.productInvoiceDraft.invoice_images = [...filesList]
    },
    resetSelectedLineItems() {
      this.selectedLineItems = []
    },
    updateSelectedLineItem(lineItem) {
      const index = this.selectedLineItems.findIndex((item) => item.id === lineItem.id)
      this.selectedLineItems[index] = lineItem
    },
    setSelectedLineItem(lineItem) {
      // Check if the line item is already selected
      const index = this.selectedLineItems.findIndex((item) => item.id === lineItem.id)
      // If it is already selected, remove it from the array
      // Otherwise, add it to the array
      if (index > -1) {
        this.selectedLineItems.splice(index, 1)
      } else {
        this.selectedLineItems.push(lineItem)
      }
    },
    async fetchProducts(paymentMethod, supplierId) {
      const params = {
        unpaid: 'true'
      }

      let url
      if (paymentMethod === 'prepaid') {
        url = `/suppliers/${supplierId}/product_request_items?${new URLSearchParams(
          params
        ).toString()}`
      } else {
        url = `/suppliers/${supplierId}/product_delivery_items?${new URLSearchParams(
          params
        ).toString()}`
      }

      const response = await fetch(url, {
        headers: {
          ...omsCsrfHeader(),
          accept: 'application/json'
        }
      })

      return await response.json()
    },
    bodyParamsAsFormData(bodyParams) {
      // In order to send invoice images (files), we need to format the body params as a FormData object.
      const formData = new FormData()

      Object.keys(bodyParams).forEach((key) => {
        const value = bodyParams[key]
        if (key === 'invoice_images') {
          value.forEach((file) => {
            formData.append(`product_invoice[${key}][]`, file)
          })
        } else if (key === 'product_invoice_line_items_attributes') {
          value.forEach((item) => {
            Object.keys(item).forEach((itemKey) => {
              if (itemKey !== 'product_request_index_id') {
                formData.append(
                  `product_invoice[${key}][${item.product_request_index_id}][${itemKey}]`,
                  item[itemKey]
                )
              }
            })
          })
        } else {
          formData.append(`product_invoice[${key}]`, value || '')
        }
      })
      formData.append('authenticity_token', omsCsrfHeader()['X-CSRF-Token'])

      return formData
    },
    async handleSubmit() {
      this.disabled = true
      // Build permitted params for the product invoice
      const bodyParams = {
        supplier_id: this.productInvoiceDraft.supplier_id,
        status: this.productInvoiceDraft.status,
        payment_type: this.productInvoiceDraft.payment_type,
        invoice_date: this.productInvoiceDraft.invoice_date,
        subtotal: this.productInvoiceDraft.subtotal,
        total_tax: this.productInvoiceDraft.total_tax,
        total: this.productInvoiceDraft.total,
        adjustment_price: this.productInvoiceDraft.adjustment_price,
        adjustment_reason: this.productInvoiceDraft.adjustment_reason,
        shipping_fee: this.productInvoiceDraft.shipping_fee,
        comment: this.productInvoiceDraft.comment,
        invoice_images: this.productInvoiceDraft.invoice_images,
        invoice_registration_number: this.productInvoiceDraft.invoice_registration_number,
        product_invoice_line_items_attributes: this.selectedLineItems.map((item) => {
          const lineItem = {
            comment: item.comment || '',
            product_request_index_id: item.product_request_index_id,
            quantity: item.quantity || item.requested_quantity
          }
          // Determine if the item is a product request item or a product delivery item
          if (item.product_request_item_id) {
            return {
              ...lineItem,
              product_request_item_id: item.product_request_item_id,
              product_delivery_item_id: item.id
            }
          }

          return {
            ...lineItem,
            product_request_item_id: item.id
          }
        })
      }

      const formData = this.bodyParamsAsFormData(bodyParams)

      const response = await fetch('/product_invoices', {
        method: 'POST',
        body: formData
      })

      const result = await response.json()

      if (result?.errors) {
        this.disabled = false
        this.setErrors(result.errors)
        window.scrollTo(0, 0)
      }

      if (result?.redirect_url) {
        window.location.href = result.redirect_url
      }
    }
  }
})
