<template>
  <oms-filter-content :title='title' :is-filtering='modelValue?.include?.length > 0 || modelValue?.exclude?.length'>
    <span v-for='item in modelValue?.include' class='badge badge-info position-static mr-1'>
      {{ item.name }}
    </span>

    <strong v-if='modelValue?.exclude?.length > 0' class='mx-1'>EXCLUDING</strong>

    <span v-for='item in modelValue?.exclude' class='badge badge-info position-static mr-1'>
      {{ item.name }}
    </span>
  </oms-filter-content>
</template>

<script>

import OmsFilterContent from '../filter-content.vue'

export default {
  props: {
    title: { type: String, required: true },
    modelValue: Object
  },

  components: {
    OmsFilterContent
  }
}
</script>
