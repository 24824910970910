$.onmount('[data-component=product-row]', function() {
  var $el = $(this);

  function onSuccess(data) {
    $el.find('[data-component=product-row-status]').html(data.html);
  }

  function onError() {
    $el
      .find('[data-component=product-row-status]')
      .text('Error while loading product.');
  }

  function fetchStatus(stockDestinationType, stockDestinationId) {
    $el.find('[data-component=product-row-status]').text('Loading...')

    $.ajax({
      url: '/product_requests/analyze_stock_destination',
      dataType: 'JSON',
      data: {
        stock_destination_type: stockDestinationType,
        stock_destination_id: stockDestinationId,
      },
      success: onSuccess,
      onError: function() {
        onError(ids);
      },
    });
  }

  var fetchStatusDebounced = _.debounce(fetchStatus, 100);

  $el.on('change', '[name*=stock_destination_type], [name*=stock_destination_id]', function(e) {
    var stockDestinationType = $el.find('[name*=stock_destination_type]').val();
    var stockDestinationId = $el.find('[name*=stock_destination_id]').val();

    fetchStatusDebounced(stockDestinationType, stockDestinationId);
  });
});
