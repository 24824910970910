import jQuery from 'jquery'
import moment from 'moment'
import flatpickr from 'flatpickr'
import underscore from 'underscore'
import Chart from 'chart.js'
import Handsontable from 'handsontable'
import onmount from 'onmount'

jQuery.onmount = onmount

window.$ = window.jQuery = jQuery
window.moment = moment
window.flatpickr = flatpickr
window._ = underscore
window.Chart = Chart
window.Handsontable = Handsontable
