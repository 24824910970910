$.onmount('[data-toggle=remove-addon-order-item]', function () {
  var $el = $(this)

  $el.on('click', function (e) {
    e.preventDefault()
    $el.parents('.nested-fields').remove()

    $.onmount()
  })
})
