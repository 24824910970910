<template>
  <div class="row p-2 m-2 align-items-center" :class="backGroundColor">
    <div class="col-sm-4">
      <h5 class="m-0">
        {{ t('features.product_request.delivery_plan_batches.batch') }} #{{ index + 1 }}
      </h5>
    </div>
    <div class="col-sm-6">
      <div class="row align-items-center">
        <div class="col-sm-12">
          <div class="m-1">
            {{ t('activerecord.attributes.delivery_plan_batch.expected_delivery_at') }}:
          </div>
          <flat-pickr v-model="batch.expected_delivery_at" class='form-control' name="requestedDeliveryAt"
                      placeholder="Select requested delivery date"
                      :value="batch.expected_delivery_at" :config='config' />
        </div>
      </div>
    </div>
    <div class="col-sm-2">
      <tooltip :tooltip-text="t('features.product_request.delivery_plan_batches.delete_batch_tip')"
               :hidden="canDeleteDeliveryPlanBatch(batch.tmp_id)">
        <button type="button" class="btn btn-danger" @click="deleteBatch(index, batch)"
                :disabled="!canDeleteDeliveryPlanBatch(batch.tmp_id)">
          {{ t('actions.delete') }}
        </button>
      </tooltip>
    </div>
  </div>
</template>

<script>
import FlatPickr from 'vue-flatpickr-component'
import {format} from "date-fns";
import Tooltip from "../../tooltip";

export default {
  inject: ['i18n'],
  components: {Tooltip, FlatPickr},

  data() {
    return {
      t: (key, options) => this.i18n.t(key, options)
    }
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    batch: {
      type: Object,
      required: true
    },
    deleteBatch: {
      type: Function,
      required: true
    },
    selectExpectedDeliveryAt: {
      type: Function,
      required: true
    },
    canDeleteDeliveryPlanBatch: {
      type: Function,
      required: true
    }
  },

  computed: {
    config() {
      return {
        dateFormat: 'Y/m/d H:i',
        altFormat: 'Y/m/d H:i',
        altInput: true,
        enableTime: true,
        enableSeconds: false,
        time_24hr: true,
        defaultHour: 0,
        defaultMinute: 0,
        onChange: this.updateDeliveryPlanBatch
      }
    },
    isPersisted() {
      return !!this.batch.id
    },
    backGroundColor() {
      return this.isPersisted ? 'bg-light-blue-gray' : 'bg-lighter-gray'
    }
  },
  methods: {
    updateDeliveryPlanBatch(selectedDates) {
      this.batch.expected_delivery_at = format(selectedDates[0], "yyyy-MM-dd HH:mm")
      this.selectExpectedDeliveryAt(this.batch, this.batch.expected_delivery_at)
    }
  }
}
</script>

<style lang="scss" scoped>
.bg-light-blue-gray {
  background-color: #dbeff0
}

.bg-lighter-gray {
  background-color: #e9ecef
}
</style>

<style lang="scss">
// For flat-pickr background color comes from form.scss
.form-control[readonly] {
  background-color: white;
}
</style>