$.onmount("[data-component=states-select]", function() {
  var $el = $(this)
  var $selectizeUS = $("#states-code-us-select")
  var $selectizeCA = $("#states-code-ca-select")

  function toggleStatesSelect() {
    var country = $el.val()

    if (country == "US") {
      $selectizeUS[0].selectize.enable()
      $selectizeCA[0].selectize.disable()
    }

    if (country == "CA") {
      $selectizeUS[0].selectize.disable()
      $selectizeCA[0].selectize.enable()
    }
  }

  function toggleStatesDropdown() {
    var country = $el.val()
    $("#states").toggleClass("d-none", ["US", "CA"].includes(country))
    $("#states-code-us").toggleClass("d-none", country != "US")
    $("#states-code-ca").toggleClass("d-none", country != "CA")
  }

  toggleStatesDropdown()
  toggleStatesSelect()

  $el.on("change", toggleStatesDropdown)
  $el.on("change", toggleStatesSelect)
})
