<template>
  <oms-bulk-action-modal id='holdOrdersModal' title='Hold Orders' :filters='filters' :params='params'
    :disabled='includesNonProcessingOrders || !selectedReason' :total-results='totalResults'>
    <div class='mt-3 d-flex flex-column'>
      <label>Reason</label>
      <multiselect v-model='selectedReason' :options='holdStatusReasons' :group-select='true' group-label='status'
        group-values='reasons' :show-labels='false' />
    </div>

    <div v-if='includesNonProcessingOrders' class='mt-3 alert alert-danger'>
      Holding only works for orders in the "processing" status. Filter by this status to perform this update.
    </div>
  </oms-bulk-action-modal>
</template>

<script>
import _ from 'underscore';
import Multiselect from 'vue-multiselect';
import OmsBulkActionModal from './bulk-action-modal.vue';

export default {
  props: {
    filters: Object,
    holdStatusReasons: Array,
    totalResults: Number
  },

  components: { Multiselect, OmsBulkActionModal },

  data() {
    return {
      selectedReason: undefined
    }
  },

  computed: {
    params() {
      const status = this.holdStatusReasons.find(({ reasons }) => reasons.includes(this.selectedReason))?.status

      return {
        type: status,
        payload: { reason: this.selectedReason }
      }
    },

    includesNonProcessingOrders() {
      return !_.isEqual(this.filters?.status?.include, ['processing'])
    }
  },
}
</script>
