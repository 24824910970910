$.onmount('[data-toggle=tooltip]', function () {
  $(this).tooltip()
})

$.onmount('[data-toggle=popover]', function () {
  $(this).popover({
    sanitize: $(this).attr('data-sanitize') === 'true'
  })

  $(this).on('click', function (event) {
    event.preventDefault()
  })
})
