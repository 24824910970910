<template>
  <oms-filter-dropdown :title='title' :icon='icon'
    :is-filtering='modelValue && (modelValue.include?.length > 0 || modelValue.exclude?.length > 0)'>
    <slot />

    <h6>Include</h6>
    <multiselect class='multiselect--inline mb-4' v-model='includedOptions' label='name' track-by='id' :multiple='true'
      :options='selectOptions' :show-labels='false' />

    <h6>Exclude</h6>
    <multiselect class='multiselect--inline' v-model='excludedOptions' label='name' track-by='id' :multiple='true'
      :options='selectOptions' :show-labels='false' />
  </oms-filter-dropdown>
</template>

<script>

import Multiselect from 'vue-multiselect'
import OmsFilterDropdown from '../filter-dropdown.vue'

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: 'ri-list-check'
    },
    options: Object,
    modelValue: Object
  },


  computed: {
    selectOptions() {
      return Object.entries(this.options).map(([id, name]) => ({ id, name }))
    },

    includedOptions: {
      get() {
        const ids = (this.modelValue && this.modelValue.include) || []
        return Object.entries(this.options).filter(([id]) => ids.includes(id)).map(([id, name]) => ({ id, name }))
      },

      set(options) {
        this.$emit('update:modelValue', { ...(this.modelValue || {}), include: options.map(({ id }) => id) })
      }
    },

    excludedOptions: {
      get() {
        const ids = (this.modelValue && this.modelValue.exclude) || []
        return Object.entries(this.options).filter(([id]) => ids.includes(id)).map(([id, name]) => ({ id, name }))
      },

      set(options) {
        this.$emit('update:modelValue', { ...(this.modelValue || {}), exclude: options.map(({ id }) => id) })
      }
    }
  },


  components: { Multiselect, OmsFilterDropdown },
}
</script>
