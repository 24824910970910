import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['confirmation', 'confirmationRefund', 'confirmationAddition', 'option']

  static values = {
    originalPrice: Number
  }

  connect() {
    this.selectShippingMethod()
  }

  selectShippingMethod(_event) {
    const target = this.getSelectedTarget()
    const originalPrice = this.originalPriceValue || 0
    const selectedPrice =
      Number(
        target.attributes['data-shopify-product-change-shipping-method-form-option-param']
          .value
      ) || 0

    const difference = originalPrice - selectedPrice

    if (difference > 0) {
      this.confirmationRefundTarget.style.display = "inline"
      this.confirmationAdditionTarget.style.display = "none"
      this.setDifference(this.confirmationRefundTarget, difference)
    } else if (difference < 0) {
      this.confirmationAdditionTarget.style.display = "inline"
      this.confirmationRefundTarget.style.display = "none"
      this.setDifference(this.confirmationAdditionTarget, Math.abs(difference))
    }

    if (difference != 0) {
      this.confirmationTarget.style.display = 'block'
    } else {
      this.confirmationTarget.style.display = 'none'
    }
  }

  setDifference(node, difference) {
    node.innerText = node.innerText.replace(/\(.+\)/, `(${difference.toFixed(2)})`)
  }

  getSelectedTarget() {
    return this.optionTargets.find(option => option.checked)
  }
}
