$.onmount('[data-component=edit-product-request]', function () {
  var $el = $(this)

  // Start editing
  $el.on('click', '[data-toggle=edit-product-request-item]', function (e) {
    $(this).closest('tr').addClass('editing')
    $(this).closest('td').addClass('editing')
    $(this).closest('td').siblings('[role=' + this.dataset.target + ']').addClass('editing')
  })

  // Cancel editing
  $el.on('click', '[data-toggle=cancel-edit-product-request-item]', function () {
    $(this).closest('tr').find('td').removeClass('editing')
    $(this).closest('tr').removeClass('editing')
    $(this).closest('tr').find('[role=errormsg]').text(null)
  })

  function onSuccess($row, data, params) {
    var $html = $(data.html)
    $row.replaceWith($html)
    $html.addClass('complete')
    setTimeout(function () {
      $html.removeClass('complete')
    }, 500)
  }

  function onError($row, data, params) {
    var errors = data.responseJSON.errors

    for (key in errors) {
      var error = errors[key]

      $row.find('[role=' + key + ']').find('[role=errormsg]').text(error[0])
    }
  }

  // Save/Persist editing
  $el.on('click', '[data-toggle=save-product-request-item]', function () {
    var $row = $(this).closest('tr')
    var params = {}
    $row.find('.editing[role] input').each(function(index, element) {
      params[element.name] = element.value
    })

    $.ajax({
      url: $row.data('path'),
      dataType: 'JSON',
      data: { product_request_item: params },
      method: 'PUT',
      success: function(data) {
        onSuccess($row, data, params)
      },
      error: function(data) {
        onError($row, data, params)
      },
      complete: function() {
        $.onmount()
      }
    })
  })

  // Cancel Product request item
  $el.on('click', '[data-toggle=cancel-product-request-item]', function () {
    var confirmed = confirm(this.dataset.confirmationText)
    if (!confirmed) return

    var $row = $(this).closest('tr')

    $.ajax({
      url: $row.data('path'),
      dataType: 'JSON',
      method: 'DELETE',
      success: function(data) {
        $row.replaceWith(data.html)
      }
    })
  })
})
