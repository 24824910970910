<template>
  <div>
    <div class="row">
      <div class="col-sm-6 form-group">
        <label class="d-block" for="address">
          {{ t('features.product_request.address') }}
        </label>
        <select id="address" class="form-control" v-model="productRequestStore.address">
          <option v-for="destination in productRequestStore.destinations" :value="destination.value"
                  :disabled="!destination.enabled">
            {{ destination.address }}
          </option>
        </select>
      </div>
    </div>
    <div class="row mt-4 mb-4 align-items-end">
      <div class="col-sm-4">
        <button class="btn btn-primary mr-2" type="button" @click="batchesOfToday">
          {{ t('features.product_request.upcoming_deliveries.today_batches') }}
        </button>
        <button class="btn btn-secondary" type="button" @click="batchesOfTomorrow">
          {{ t('features.product_request.upcoming_deliveries.tomorrow_batches') }}
        </button>
      </div>
      <div class="col-sm-3">
        <label>From date:</label>
        <flat-pickr class='form-control' placeholder="Select from date" v-model="from" :min-date="new Date()"
                    :config='config' />
      </div>
      <div class="col-sm-3">
        <label>To date:</label>
        <flat-pickr class='form-control' placeholder="Select to date" v-model="to" :min-date="from" :config='config' />
      </div>
      <div class="col-sm-2" v-if="from && to && productRequestStore.address">
        <button class="btn btn-primary" type="button"
                @click="fetchDeliveryPlanBatchesBetween(new Date(from), new Date(to), productRequestStore.address)">
          {{ t('actions.search') }}
        </button>
      </div>
    </div>
    <div class="m-2 p-2" v-for="batchesInSameDay in batchesPerDay">
      <div class="card">
        <div class="card-title mb-0">
          <h4 class="text-primary ml-2 mt-3">
            <i class="bi bi-truck mr-2 ml-2" />
            <span class="ml-2">
              {{ t('features.product_request.upcoming_deliveries.planned_deliveries') }}
              {{ batchesInSameDay.deliveryDate }}
            </span>
            <b>
              ({{ formatDistanceToNow(new Date(batchesInSameDay.batches[0].expected_delivery_at), {addSuffix: true}) }})
            </b>
          </h4>
        </div>
        <div class="card-body" v-for="batch in batchesInSameDay.batches">
          <upcoming-delivery-batch :batch="batch" :initial-data="initialData" />
        </div>
      </div>
    </div>
    <h2 v-if="upcomingBatches.length < 1" class="text-center text-secondary mt-5">
      {{ t('features.product_request.upcoming_deliveries.no_batches_expected') }}</h2>
  </div>
</template>

<script>
import FlatPickr from 'vue-flatpickr-component'
import {addDays, format, formatDistanceToNow, startOfToday} from 'date-fns'
import {endOfToday} from 'date-fns/esm'
import {omsCsrfHeader} from "../fetch-utils";
import UpcomingDeliveryBatch from "./upcoming-delivery-batch.vue";
import {sortBy, uniq} from "lodash";
import {mapStores} from "pinia";
import {useProductRequestStore} from "../stores/productRequestStore";
import buildQueryString from "../../helpers/query-builder";

export default {
  inject: ['i18n'],
  props: {
    initialData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      t: (key, options) => this.i18n.t(key, options),
      ...this.initialData,
      currentUser: null,
      upcomingBatches: [],
      from: null,
      to: null,
      format,
      formatDistanceToNow
    }
  },

  components: {UpcomingDeliveryBatch, FlatPickr},

  computed: {
    ...mapStores(useProductRequestStore, ['destinations']),
    // FlatPickr config
    config() {
      return {
        dateFormat: 'Y/m/d H:i',
        altFormat: 'Y/m/d H:i',
        altInput: true,
        enableTime: true,
        enableSeconds: false,
        time_24hr: true,
        defaultHour: 0,
        defaultMinute: 0,
      }
    },
    batchesPerDay() {
      const dates = uniq(this.upcomingBatches.map((batch) => {
        return format(new Date(batch.expected_delivery_at), 'yyyy/MM/dd')
      }))

      return dates.map((date) => {
        let batchesInSameDay = this.upcomingBatches.filter((batch) => {
          return format(new Date(batch.expected_delivery_at), 'yyyy/MM/dd') === date
        })
        // Sort by delivery time.
        batchesInSameDay = sortBy(batchesInSameDay, ['expected_delivery_at', 'supplier.id'])
        return {deliveryDate: date, batches: batchesInSameDay}
      })
    },
    defaultDestination() {
      switch (this.currentUser?.role?.name) {
        case "curator":
          return 'office'
        default:
          return "warehouse_a"
      }
    }
  },

  async mounted() {
    await this.fetchCurrentUser()
    this.batchesOfToday()
    this.productRequestStore.address = this.defaultDestination
  },

  methods: {
    useProductRequestStore,
    batchesOfToday() {
      this.fetchDeliveryPlanBatchesBetween(
        startOfToday(),
        endOfToday(),
        this.productRequestStore.address
      )
    },
    batchesOfTomorrow() {
      this.fetchDeliveryPlanBatchesBetween(
        addDays(startOfToday(), 1),
        addDays(endOfToday(), 1),
        this.productRequestStore.address
      )
    },
    async fetchDeliveryPlanBatchesBetween(from, to, address) {
      const batches = await fetch(`/delivery_plan_batches?${new URLSearchParams({
        from: from.toUTCString(),
        to: to.toUTCString(),
        address: address
      })}`, {
        headers: omsCsrfHeader()
      })
      this.upcomingBatches = await batches.json()
    },
    async fetchCurrentUser() {
      const account = await fetch(`/account`, {
        headers: omsCsrfHeader()
      })
      this.currentUser = await account.json()
    },
    linkDeliveryUrl(batch) {
      return `${this.deliveryLinkUrl}?${this.filterQueryString(batch)}&${this.buildQuantityQueryString(batch)}`
    },
    /**
     * The query should be like
     * q[request_item_ids][include][]=1&q[request_item_ids][include][]=2
     */
    filterQueryString(batch) {
      const item_ids = batch.product_request_items.map((item) => item.id)
      const filters = {request_item_ids: {include: item_ids}}
      return buildQueryString(filters, 'q')
    },
    /**
     * The query should be like
     * deliveries[][item_id]=1&deliveries[][quantity]=1000&deliveries[][item_id]=2&deliveries[][quantity]=2000
     */
    buildQuantityQueryString(batch) {
      const quantities = batch.product_request_items.map((item) => {
        return {item_id: item.id, quantity: item.quantity_per_batch}
      })
      return buildQueryString(quantities, 'deliveries')
    },
    linkProductRequest(batch) {
      return `${this.productRequestUrl}/${batch.product_request_id}/preview`
    },
    linkSupplier(supplier) {
      return `${this.supplierUrl}/${supplier.id}`
    },
    linkProduct(product) {
      return `${this.productUrl}/${product.id}`
    }
  }
}
</script>
