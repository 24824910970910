import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['calculationOptionsForm']

  submit() {
    const fieldset = this.calculationOptionsFormTargets.find((fieldset) => !fieldset.disabled)
    const fields = [...fieldset.elements].filter(element => element.name && !['fieldset', 'button'].includes(element.tagName.toLowerCase()))

    const options = {}

    fields.forEach((element) => {
      const arrayField = element.closest('[data-array]')

      if (arrayField) {
        if (element.closest('[data-new-item]')) return

        const index = [...arrayField.querySelectorAll('[data-item]')].indexOf(element.closest('[data-item]'))

        options[arrayField.name] = options[arrayField.name] || []
        options[arrayField.name][index] = options[arrayField.name][index] || {}
        options[arrayField.name][index][element.name] = element.value
      } else {
        options[element.name] = element.value
      }
    })

    this.element.querySelector('[name="shipping_method_fee_schedule[calculation_method_options]"]').value = JSON.stringify(options)
  }

  calculationMethodChanged(event) {
    const currentValue = event.target.value

    this.calculationOptionsFormTargets.forEach((fieldset) => {
      fieldset.classList.toggle('d-none', fieldset.dataset.value !== currentValue)
      fieldset.disabled = fieldset.dataset.value !== currentValue
    })
  }

  arrayAdd(event) {
    const arrayField = event.target.closest('[data-array]')
    const newItem = arrayField.querySelector('[data-new-item]')

    arrayField.querySelector('[data-items]').appendChild(newItem.children[0].cloneNode(true))
  }

  arrayRemove(event) {
    event.target.closest('[data-items]').removeChild(
      event.target.closest('[data-item]')
    )
  }
}
