import buildQueryString from '../../helpers/query-builder'

$.onmount('[data-component="charts/select-box"]', function() {
  var $el = $(this)

  $el.selectize({
    highlight: false,
    valueField: 'value',
    labelField: 'id',
    searchField: ['id'],
    options: [],
    create: false,
    preload: 'focus',
    onItemAdd: function(val) {
      const query =  buildQueryString({ box_id: val } )
      window.location = `${window.location.pathname}?${query}`
    }
  })
})
