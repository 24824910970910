<template>
  <oms-filter-dropdown :title='title' :icon='icon' :isFiltering='isFiltering'>
    <slot />
    <label class='form-check' v-for='(name, status) in statuses' :key='status'>
      <input class='form-check-input' type='checkbox'
        :checked='modelValue && modelValue.include && modelValue.include.includes(status)' @input='toggleStatusSelected(status)' />
      <span class='form-check-label'>
        <i class='ri-checkbox-blank-circle-fill' :class='colorClass(status)'></i>
        {{ name }}
      </span>
    </label>

    <div class='pt-2'>
      <button type='button' class='btn btn-sm btn-light' @click='selectAll'>
        Select all
      </button>
      <button type='button' class='btn btn-sm btn-light' @click='selectNone'>
        Select none
      </button>
    </div>
  </oms-filter-dropdown>
</template>

<script>
import OmsFilterDropdown from '../filter-dropdown.vue'

export default {
  props: {
    title: {
      type: String,
      default: 'Status'
    },
    icon: {
      type: String,
      default: 'ri-git-branch-line'
    },
    color: {
      type: String
    },
    statuses: Object,
    modelValue: Object
  },

  computed: {
    isFiltering() {
      return this.modelValue && this.modelValue.include && this.modelValue.include.length > 0
    }
  },

  methods: {
    colorClass(status) {
      if (this.color) {
        return `text-${this.color}`
      } else {
        return `text-${status.replace(/_/g, '-')}`
      }
    },

    toggleStatusSelected(modelValue) {
      const currentValues = (this.modelValue && this.modelValue.include) || []

      if (currentValues.includes(modelValue)) {
        this.$emit(
          'update:modelValue',
          { include: currentValues.filter((item) => item !== modelValue) }
        )
      } else {
        this.$emit('update:modelValue', { include: [...currentValues, modelValue] })
      }
    },

    selectAll() {
      this.$emit('update:modelValue', { include: Object.keys(this.statuses) })
    },

    selectNone() {
      this.$emit('update:modelValue', { include: [] })
    }
  },

  components: {
    OmsFilterDropdown
  }
}
</script>
