$.onmount('[data-component=shipment-transaction-action]', function() {
  function changeFormMode($form) {
    var $submitBtn = $form.find('button[type="submit"]')
    var $draftCheckbox = $form.find('input[data-role="draft-status"]')

    if ($draftCheckbox.prop('checked') == true) {
      $submitBtn.text($submitBtn.data('draft'))
    } else {
      if ($draftCheckbox.is(':disabled')) {
        $submitBtn.text($submitBtn.data('update'))
      } else {
        $submitBtn.text($submitBtn.data('record'))
      }
    }
  }

  $(this)
    .find('[data-toggle=modal]')
    .on('click', function() {
      var modalTarget = $(this).data('target')
      var modalElement = $('[data-modal=' + modalTarget + ']')

      modalElement.on('shown.bs.modal', function(e) {
        changeFormMode(modalElement.find('form'))
      })
      modalElement.modal('show')
    })

  $(this)
    .find('input')
    .on('change keyup', function(e) {
      var $form = $(this).closest('form')
      changeFormMode($form)
    })
})
