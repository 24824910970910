<template>
  <div class="tooltip-container">
    <slot></slot>
    <span class="tooltiptext" v-if="!hidden">{{ tooltipText }}</span>
  </div>
</template>

<style>
.tooltip-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.tooltip-container .tooltiptext {
  visibility: hidden;
  width: 100%;
  background-color: black;
  color: white;
  padding: 5px 5px;
  text-align: left;
  border-radius: 6px;
  font-size: 0.8rem;

  position: absolute;
  z-index: 1;
}

.tooltip-container:hover .tooltiptext {
  visibility: visible;
}
</style>

<script>
export default {
  props: {
    tooltipText: {
      type: String,
      required: false
    },
    hidden: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
