(function () {
  const VARIANT_ONLY_COLUMNS = ['variant_sku_suffix', 'variant_value']

  class ProductUpdater {
    constructor(store) {
      this.store = store
      this.updatedProductIndexes = new Set()
      this.deletedProductIndexes = new Set()
    }

    writeProduct(index, data) {
      if (index == null) {
        index = this.store.products.length
      }

      this.store.products[index] = _.extend(
        this.store.products[index] || this.newProductData(),
        _.omit(data, VARIANT_ONLY_COLUMNS)
      )
      delete this.store.products[index]._destroy

      this.maybeOverwriteVariants(index, _.pick(data, VARIANT_ONLY_COLUMNS))

      this.updatedProductIndexes.add(index)

      return index
    }

    writeVariant(productIndex, variantIndex, data) {
      const product = this.store.products[productIndex]

      product.shopify_product_variants[variantIndex] = _.extend(
        product.shopify_product_variants[variantIndex] || {},
        data
      )
      delete product.shopify_product_variants[variantIndex]._destroy

      this.updatedProductIndexes.add(productIndex)

      return variantIndex
    }

    deleteProduct(productIndex) {
      this.store.products[productIndex]._destroy = true
      this.deletedProductIndexes.add(productIndex)
      this.updatedProductIndexes.delete(productIndex)
    }

    deleteVariant(productIndex, variantIndex) {
      if (this.store.products[productIndex].shopify_product_variants[variantIndex] && !this.store.products[productIndex]._destroy) {
        this.store.products[productIndex].shopify_product_variants[variantIndex]._destroy = true
        this.updatedProductIndexes.add(productIndex)
      }
    }

    saveChanges() {
      const updatedIndexes = Array.from(this.updatedProductIndexes)
      const deletedIndexes = Array.from(this.deletedProductIndexes)

      return Promise.all([
        this.requestSaves(updatedIndexes),
        this.requestDeletions(deletedIndexes)
      ]).then(([updateResponse, deleteResponse]) => {
        const results = this.formatChangeResults(updatedIndexes, updateResponse, deletedIndexes)

        results.forEach(result => {
          if (result.product) this.store.products[result.index] = result.product
        })
        deletedIndexes.forEach(index => this.store.products[index] = null)
        return results
      })
    }

    formatChangeResults(updatedIndexes, updateResponse, deletedIndexes) {
      return _.zip(updateResponse, updatedIndexes)
        .map(([response, index]) => ({ product: response.shopify_product, index }))
        .concat(deletedIndexes.map(index => ({ deleted: true, index })))
    }

    requestSaves(updatedIndexes) {
      if (updatedIndexes.length > 0) {
        return $.ajax({
          url: '/shopify/products/bulk_save_drafts',
          method: 'PUT',
          data: JSON.stringify({
            shopify_products: updatedIndexes.map(index => this.paramsForProductAt(index))
          }),
          processData: false,
          contentType: 'application/json',
          dataType: 'JSON'
        }).promise()
      } else {
        return Promise.resolve([])
      }
    }

    paramsForProductAt(index) {
      return _.extend(_.omit(this.store.products[index], 'shopify_product_variants'), {
        shopify_product_variants_attributes: this.store.products[index].shopify_product_variants
      })
    }

    requestDeletions(deletedIndexes) {
      return Promise.all(deletedIndexes.map(index =>
        $.ajax({
          url: '/shopify/products/' + this.store.products[index].id,
          method: 'DELETE',
          dataType: 'JSON'
        }).promise()
      ))
    }

    maybeOverwriteVariants(index, variantData) {
      if (!_.isEmpty(variantData)) {
        this.store.products[index].shopify_product_variants.slice(1).forEach(variant => {
          variant._destroy = true
        })
        this.writeVariant(index, 0, variantData)
      }
    }

    newProductData() {
      return {
        shopify_product_upload_id: this.store.uploadId,
        shopify_product_variants: []
      }
    }
  }

  class ProductListSpreadsheetData {
    constructor(uploadId, products) {
      this.uploadId = uploadId
      this.products = products
    }

    makeChanges(changeMaker) {
      const updater = new ProductUpdater(this)
      changeMaker(updater)

      return updater.saveChanges()
    }

    errorMessageOn(productIndex, variantIndex, field) {
      if (productIndex != null && variantIndex != null) {
        return this.formatErrors(this.products[productIndex].shopify_product_variants[variantIndex], field)
      } else if (productIndex != null) {
        return this.formatErrors(this.products[productIndex], field)
      }
    }

    formatErrors(object, field) {
      if (object && object.errors && object.errors[field]) {
        return object.errors[field].join(', ')
      }
    }
  }

  window.Shopify = window.Shopify || {}
  Shopify.ProductListSpreadsheetData = ProductListSpreadsheetData
})()
