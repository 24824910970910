<template>
  <div class="card mb-4">
    <div class="card-body pb-3 d-flex flex-wrap">
      <oms-filter-statuses
        title="Order type"
        icon="ri-ship-line"
        :statuses="shipmentBoxTypes"
        v-model="filters.shipment_box_types"
        color="secondary"
      />
      <oms-filter-statuses
        title="Events"
        icon="ri-ship-line"
        :statuses="events"
        v-model="filters.events"
        color="secondary"
      />
      <oms-filter-date-range
        title="Picked up at"
        icon="ri-map-pin-time-line"
        v-model="filters.picked_up_at"
      />
      <oms-filter-date-range
        title="Left Japan at"
        icon="ri-map-pin-time-line"
        v-model="filters.left_japan_at"
      />
      <oms-filter-date-range
        title="Transacted at"
        icon="ri-map-pin-time-line"
        v-model="filters.transacted_at"
      />
      <oms-filter-date-range
        title="Cancelled at"
        icon="ri-map-pin-time-line"
        v-model="filters.cancelled_at"
      />
      <oms-filter-date-range
        title="Returned at"
        icon="ri-map-pin-time-line"
        v-model="filters.returned_at"
      />
      <oms-filter-date-range
        title="Delivered at"
        icon="ri-map-pin-time-line"
        v-model="filters.delivered_at"
      />
   
      <div class="form-inline mr-2 mb-2 ml-auto" title="Search by ID, box name or tracking number">
        <input
          class="form-control"
          v-model="filters.keyword"
          @keyup.enter="submit"
          type="text"
          placeholder="Search ..."
        />
      </div>

      <button class="btn btn-primary mr-2 mb-2" @click="submit" :disabled="isLoading || !isDirty">
        <span class="ri-search-line"></span> Search
      </button>

      <order-actions
        :filters="filters"
        :disabled="isDirty"
        :shipping-methods="[]"
        :hold-status-reasons="[]"
        :current-tags="[]"
      />
      <div v-if="selectedBoxes.size" class="w-100 d-flex justify-content-end align-items-center gap-3">
        <span>{{ selectedBoxes.size }} order(s) selected</span>
        <button v-if="filters.shipment_name" :disabled="isLoading" data-action="shipment-boxes-index#deleteOrder" class="btn btn-danger">        
          Remove order(s)
        </button>
      </div>
    </div>

 
    <div class="card-body pt-0 pb-1">
      <oms-filter-content-statuses
        title="Order type"
        :statuses="shipmentBoxTypes"
        v-model="filters.shipment_box_types"
        color="secondary"
      />
      <oms-filter-content-statuses
        title="Events"
        :statuses="events"
        v-model="filters.events"
        color="secondary"
      />
      <oms-filter-content-date-range title="Picked up at" :modelValue="filters.picked_up_at" />
      <oms-filter-content-date-range title="Left Japan at" :modelValue="filters.left_japan_at" />
      <oms-filter-content-date-range title="Transacted at" :modelValue="filters.transacted_at" />
      <oms-filter-content-date-range title="Cancelled at" :modelValue="filters.cancelled_at" />
      <oms-filter-content-date-range title="Returned at" :modelValue="filters.returned_at" />
      <oms-filter-content-date-range title="Delivered at" :modelValue="filters.delivered_at" />
      <oms-filter-content-basic title="Shipment" :modelValue="filters.shipment_name" />
      
      <oms-filter-content-basic
        title="Shipment invoice"
        :modelValue="filters.shipment_invoice_name"
      />
           
    </div>
  </div>
</template>

<script>
import buildQueryString from '../../../helpers/query-builder'
import OmsFilterStatuses from '../filter/statuses.vue'
import OmsFilterRadioButtons from '../filter/radio-buttons.vue'
import OmsFilterDateRange from '../filter/date-range.vue'
import OmsFilterContentDateRange from '../filter-content/date-range.vue'
import OmsFilterContentStatuses from '../filter-content/statuses.vue'
import OmsFilterContentBasic from '../filter-content/basic.vue'
import OrderActions from '../order-actions.vue'

export default {
  props: {
    initialData: Object
  },
  data() {
    return {
      ...this.initialData,
      isLoading: false,
      selectedBoxes: new Set(),
      isDirty: false
    }
  },

  computed: {
    searchUrl() {
      return `${this.baseUrl}?${this.filterQueryString}`
    },

    filterQueryString() {
      return buildQueryString(this.filters, 'q')
    }
  },

  watch: {
    filters: {
      deep: true,
      handler() {
        this.isDirty = true
      }
    }
  },

  methods: {
    submit() {
      this.isDirty = false
      this.isLoading = true

      window.location.href = this.searchUrl
    }
  },

  created() {
    this.$bus.on('checkboxChanged', ({ id, checked }) => {
      if (checked) {
        this.selectedBoxes.add(id);
      } else {
        this.selectedBoxes.delete(id);
      }
    });
  },

  components: {
    OmsFilterStatuses,
    OmsFilterRadioButtons,
    OmsFilterDateRange,
    OmsFilterContentDateRange,
    OmsFilterContentStatuses,
    OmsFilterContentBasic,
    OrderActions
  }
}
</script>
