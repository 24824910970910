<template>
  <div class='modal fade' role='dialog' id='printLabelsModal'>
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class='modal-title'>Print Labels</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class='modal-body'>
          <label for='selected-orders' class='form-check'>
            <input class='form-check-input' id='selected-orders' type='radio' value='selectedOrders'
              v-model='selectedOption' />
            Selected Orders ({{ orderIdsToPrint.length }})
          </label>
          <label for='filtered-orders' class='form-check'>
            <input class='form-check-input' id='filtered-orders' type='radio' value='filteredOrders'
              v-model='selectedOption' />
            <div class='mb-2'>Filtered Orders</div>
            <input class='form-control' v-model='ordersToPrintCount' @focus='selectedOption = "filteredOrders"'>
          </label>
          <div class="mt-3 d-flex flex-column">
            <label>Shipping Method</label>
            <multiselect v-model='selectedShippingMethod' track-by='id' label='name' :options='shippingMethods'
              :show-labels='false' />
          </div>
          <div v-if='selectedOption === "filteredOrders"' class='alert alert-warning mt-3'>
            Orders will be automatically split into shipments by brand and box.
          </div>
        </div>
        <div class="modal-footer">
          <button class='btn btn-primary mt-2 mb-2' :disabled='!selectedShippingMethod.id' @click='submit'>
            {{ loading ? 'Loading ...' : 'Print' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import selectedOrderIds from 'javascripts/stores/orders/selected-ids';
import Multiselect from 'vue-multiselect';
import buildQueryString, { appendSearchParams } from "../../helpers/query-builder";

export default {
  props: {
    filters: Object,
    shippingMethods: Array
  },

  components: { Multiselect },

  data() {
    return {
      loading: false,
      selectedOption: 'selectedOrders',
      selectedShippingMethod: {},
      ordersToPrintCount: 100
    }
  },

  computed: {
    orderIdsToPrint() {
      return selectedOrderIds.currentlySelected
    },

    printingOptions() {
      return [
        { id: '1', name: `Selected Orders` },
        { id: '2', name: 'Orders With This Filter' }
      ]
    },

    defaultOption() {
      return this.printingOptions[0].id
    }
  },

  methods: {
    isChecked(option) {
      if (!this.modelValue && option === this.defaultOption) {
        return true
      }

      return this.modelValue === option
    },

    submit() {
      if (this.selectedOption === 'selectedOrders') {
        window.location.href = this.selectedLabelsPathByIds(this.orderIdsToPrint)
      } else {
        this.openAutoShipments()
      }
    },

    openAutoShipments() {
      this.loading = true

      fetch(
        `/labels/printable_queries?${this.labelParams(this.filters).toString()}`
      ).then(response =>
        response.json()
      ).then(queries => {
        queries.forEach(ids => {
          window.open(this.selectedLabelsPathByIds(ids), '_blank')
        })

        this.loading = false
      })
    },

    labelParams(filters) {
      const labelParams = new URLSearchParams()

      const currentParams = new URL(document.location.href).searchParams
      if (currentParams.get('sort')) {
        labelParams.set('sort', currentParams.get('sort'))
      }

      labelParams.set('limit', this.ordersToPrintCount)
      appendSearchParams(labelParams, filters, 'q')

      return labelParams
    },

    selectedLabelsPathByIds(ids) {
      const options = this.selectedShippingMethod.shippingMethodPrintOptions ?
        `&${buildQueryString(this.selectedShippingMethod.shippingMethodPrintOptions, 'shipping_method_print_options')}` :
        ''

      return `/labels/selected?shipping_method_id=${this.selectedShippingMethod.id}&${buildQueryString(ids, 'ids')}${options}`
    }
  },
}

</script>
