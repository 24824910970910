import { reactive } from 'vue'

const currentlySelected = []

const selectedOrderIds = reactive({
  currentlySelected: currentlySelected,

  add: (id) => {
    if (!currentlySelected.includes(id)) {
      selectedOrderIds.currentlySelected[currentlySelected.length] = id
    }
  },

  remove: (id) => {
    const index = currentlySelected.indexOf(id)
    if (index > -1) {
      delete selectedOrderIds.currentlySelected[index]
    }
  },

  size: () => currentlySelected.length,

  toArray: () => currentlySelected
})

export default selectedOrderIds
