$.onmount('[data-toggle=shopify-toggle-order]', function () {
  var $el = $(this)

  $el.on('click', function (e) {
    e.preventDefault()
    $(this).parent().addClass('d-none')
    $(this).parent().siblings().removeClass('d-none')

    if ( $(this).hasClass('remove') ) {
      $(this).parents('tr').addClass('removed')
      toggle(this.dataset.id, true)
    } else {
      $(this).parents('tr').removeClass('removed')
      toggle(this.dataset.id, false)
    }
  })

  var toggle = function (orderId, isExcluded) {
    var $el = $('#excluded_ids')
    var current = $el.val().split(',')

    if (isExcluded) {
      $el.val( _.union(current, [orderId]) )
    } else {
      $el.val( _.difference(current, [orderId]) )
    }
  }
})
