<script setup>
import Counts from '../../components/orders/counts'
import Filters from '../../components/orders/filters'
import List from '../../components/orders/list'

import { toRefs, onMounted } from 'vue'
import { useOrdersStore } from '../../stores/orders'

const props = defineProps({
  initialData: {
    type: Object,
    required: true
  }
})

const { initialData } = toRefs(props)

const { fetchOrders, setInitialState } = useOrdersStore()

onMounted(() => {
  setInitialState(initialData)

  const query = window.location.search.substring(1)
  fetchOrders(query)
})
</script>

<template>
  <div role="orders">
    <Counts />
    <Filters :initialData="initialData" />
    <List />
  </div>
</template>
