<template>
    <div @focus.capture="dirty = true">
        <slot :value="value" :setValue="_setValue" :onChange="() => console.log('Changed')" :v-model="newVModel"></slot>
        <h6 class="text-danger" v-if="validationMessage">{{ validationMessage }}</h6>
    </div>
</template>

<script>
export default {
    props: {
        validationFn: {
            type: Function,
            required: false
        },
        value: {
            required: false
        },
        setValue: {
            required: false
        },
        allowEmpty: {
            required: false,
            type: Boolean
        },
        vModel: {
            required: false
        }
    },
    data() {
        return {
            dirty: false,
            validationMessage: ""
        }
    },

    mounted() {},

    watch: {
        dirty() {
            this.validate()
        }
    },
    computed: {
        newVModel: {
            set() {
                this.dirty = true
                this.vModel = args[0]
            },
            get() {
                return this.vModel
            }
        }
    },
    methods: {
        validate() {
            if (!this.allowEmpty && ( !this.value || !this.vModel)) {
                this.validationMessage = "Should not be empty"
            } else if (this.dirty && this.validationFn) {
                this.validationMessage = this.validationFn()
            } else {
                this.validationMessage = ""
            }

            this.dirty = false
        },
        _setValue(...args) {
            this.dirty = true
            if (this.setValue)
                this.setValue(...args)
            
        }
    }
}
</script>

<style>
</style>