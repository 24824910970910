$.onmount('[data-toggle=select-month]', function () {
  var $el = $(this)

  $el.on('change', function () {
    refresh()
  })

  var refresh = function () {
    var month = $('#date_month').val()
    var year  = $('#date_year').val()

    var november1st = new Date('November 01, 2017')

    var currentDate = new Date(month + ' 01 ' + year)

    var $boxes = $('.boxes')
    $boxes.find('.col-sm-3').removeClass('d-none')
    $boxes.find("[role=regular], [role=classic], [role=small]").addClass('d-none')

    if (currentDate > november1st) {
      $boxes.find('[role=classic]').removeClass('d-none')
    } else {
      $boxes.find('[role=regular], [role=small]').removeClass('d-none')
    }
  }

  refresh()
})
