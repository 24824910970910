<template>
  <oms-filter-dropdown :title='title' :icon='icon' :is-filtering='isFiltering'>
    <h6>Include</h6>
    <textarea class='form-control mb-4' v-model='includedIds' type='text' rows='10' cols='150'
      placeholder='Paste IDs separated by commas, or each on a new line' />

    <h6>Exclude</h6>
    <textarea class='form-control' v-model='excludedIds' type='text' rows='10' cols='150'
      placeholder='Paste IDs separated by commas, or each on a new line' />
  </oms-filter-dropdown>
</template>

<script>
import Multiselect from 'vue-multiselect'
import OmsFilterDropdown from '../filter-dropdown.vue'

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: 'ri-focus-line'
    },
    modelValue: Object
  },

  components: { Multiselect, OmsFilterDropdown },

  computed: {
    isFiltering() {
      return this.modelValue?.include?.filter(Boolean)?.length > 0 || this.modelValue?.exclude?.filter(Boolean)?.length > 0
    },

    includedIds: {
      get() {
        const ids = this.modelValue?.include || []
        return ids.join(',')
      },

      set(content) {
        this.$emit('update:modelValue', { ...(this.modelValue || {}), include: content.split(/[,\n]/).map(id => id.trim()) })
      }
    },

    excludedIds: {
      get() {
        const ids = this.modelValue?.exclude || []
        return ids.join(',')
      },

      set(content) {
        this.$emit('update:modelValue', { ...(this.modelValue || {}), exclude: content.split(/[,\n]/).map(id => id.trim()) })
      }
    }
  }
}
</script>
