$.onmount("[data-component='coupon-forms/select-duration-type']", function() {
  var $el = $(this)

  function selectDurationType() {
    var durationType = $el.val()

    $("#duration-month").toggleClass(
      "d-none",
      durationType !== "limited_period"
    )
  }

  $el.on("change", selectDurationType)
})
