import 'stylesheets/main'
import 'remixicon/fonts/remixicon.css'

import '../globals'

import 'jquery-ujs'
import 'selectize'
import 'bootstrap'
import 'cocoon-js'

import '../cocoon-setup'
import '../handsontable-setup'
import '../vue/i18n'
import 'controllers'

const requireAll = (require) => {
  require.keys().forEach(require)
}

requireAll(require.context('../components/', true, /\.js|\.vue$/))
requireAll(require.context('../stores/', true, /\.js|\.vue$/))
requireAll(require.context('../vue/', true, /\.js|\.vue$/))

$.onmount()
