$.onmount('[data-role=shipment-upload-group]', function() {
  var $uploadContainer = $(this)
  var $uploadFileField = $uploadContainer.find('[data-role=receipt-images-upload]')
  var $label = $uploadContainer.find('[data-role=upload-label]')
  var $newFilesContainer = $uploadContainer.find('[data-role=new-files-container]')
  var $originalFilesContainer = $uploadContainer.find('[data-role=original-files-container]')

  var shipmentType = $uploadFileField.data('type')
  var fieldName = $uploadFileField.data('uploadfield')

  $uploadFileField.on('change', (event) => {
    $newFilesContainer.html('')

    Array.prototype.forEach.call(event.target.files, function(file) {
      var $li = $(`<li class="additional-preview-file" data-name="${file.name}">${file.name}</li>`)
      $newFilesContainer.append($li)
    })
  })

  if ($originalFilesContainer.length > 0) {
    $originalFilesContainer.find('[data-role=remove-button]').on('click', function() {
      var index = $(this).data('index')
      $label.append(
        `<input type="hidden" name="shipment_transaction[shipment_receipts_to_remove][]" value="${index}" />`
      )

      $(this)
        .parents('li')
        .remove()
    })
  }
})