$.onmount('[data-toggle=shopify-order-action]', function () {
  var $el = $(this)

  $el.on('click', function (e) {
    e.stopPropagation()
    e.preventDefault()
    var $modal = $('#refund_form')
    $modal.find('.modal-content').html($modal.data('-loading'))
    $modal.modal('show')

    $.getJSON(this.href, function (data) {
      $modal.find('.modal-content').html(data.html)
      loadForm($modal)
    })
  })

  var loadForm = function ($modal) {
    var $form = $modal.find('form')
    var timeout;
    $form.find('[data-toggle=calculate-shopify-order]').on('input', function () {
      var $input = $(this)
      checkValue($input)
      clearTimeout(timeout)

      timeout = setTimeout(function () {
        onChange($form, $input)
      }, 200)
    })

    $form.find('[data-toggle=shopify-cancel-reason]').on('change', function () {
      var $select = $(this)
      if ($select.val() == 'fraud') {
        $('#shopify_refund_form_notify').prop('checked', false)
        $('#shopify_refund_form_restock').prop('checked', true)
      } else {
        $('#shopify_refund_form_notify').prop('checked', true)
        $('#shopify_refund_form_restock').prop('checked', false)
      }
    })

    $form.on('submit', function (e) {
      e.preventDefault()
      onSubmit($form)
      return false
    })
  }

  var checkValue = function ($input) {
    var value = $input.val()
    if (value === '') { return }
    var num = parseInt(value)
    var max = parseInt($input[0].max)

    if (isNaN(num) || num < 0) {
      $input.val(0)
    } else if (num > max) {
      $input.val(max)
    }
  }

  var onChange = function ($form, $input) {
    var parentId = $input.parents('td').attr('id')
    $price = $input.parents('td').siblings('td[role=quantity-price]')
    $price.find('[role=loader]').removeClass('d-none')
    $price.find('[role=price]').addClass('d-none')
    $form.find('.table-info [role=loader]').removeClass('d-none')
    $form.find('.table-info [role=price]').addClass('d-none')
    $form.find('[data-toggle=shopify-refund-save]').prop('disabled', true)

    var path = $form.find('[role=helpers]').data('calculate-path')
    $.getJSON(path + '?' + $form.serialize(), function (data) {
      loadPartial($form, data)
    })
  }

  var loadPartial = function ($form, data) {
    var $body = $(data.html)

    // Line Items
    var $formLineItems = $form.find('[role=line-items] tr')
    $body.find('[role=line-items] tr').each(function (index, el) {
      var $td = $(el).find('td:last-child')
      $($formLineItems[index]).find('td:last-child').replaceWith($td)
    })

    // Sub totals
    $form.find('[role=table-info]').replaceWith( $body.find('[role=table-info]') )

    // Restock
    $form.find('.restock').replaceWith( $body.find('.restock') )

    // Submit button
    $form.find('[data-toggle=shopify-refund-save]').replaceWith( $body.find('[data-toggle=shopify-refund-save]') )
  }

  var onSubmit = function ($form) {
    $form.find('[data-toggle=shopify-refund-save]').text('Submitting..').prop('disabled', true)

    $.ajax({
      url: $form.attr('action') + '?' + $form.serialize(),
      method: 'PUT',
      data: {
        template: $el.data('template')
      },
      dataType: 'json',
      success: function (data) {
        $el.trigger('oms:shopify-refund-complete', [data])

        $('#refund_form').modal('hide')
        $.onmount()
      },
      error: function (xhr, status, errorThrown) {
        $form.find('.modal-body').prepend("<div class='alert alert-danger'>" +
          "Oops, something went wrong when submitting the form. Please refresh the page to see the latest data before trying again." +
        "</div>")
      }
    })
  }
})
