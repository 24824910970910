<script setup>
// Shared among Product Delivery and Product Request items
import { storeToRefs } from 'pinia'
import { inject, toRefs, computed, watch } from 'vue'
import { format } from 'date-fns'
import { useProductInvoiceStore } from '../../../stores/productInvoiceStore'

const i18n = inject('i18n')
const t = (key, options) => i18n.t(key, options)

const store = useProductInvoiceStore()

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  index: {
    type: Number,
    required: true
  }
})

const emit = defineEmits(['handleCheckboxClick'])

const { data, index } = toRefs(props)

// Store getters
const { getSelectedLineItems, getPaymentType } = storeToRefs(store)
// Store actions
const { setSelectedLineItem, updateSelectedLineItem } = store

const selectedLineItems = computed(() => getSelectedLineItems.value)
// Determine if the item is selected (checked).
const isSelected = computed(() => {
  return selectedLineItems.value.some((item) => item.id === data.value.id)
})

// Determine if the item is a product delivery item or product request item.
const isProductDeliveryItem = computed(() => {
  // check if data has a product_request_item key, if it does, it's a product delivery item.
  return data.value.product_request_item !== undefined
})
// Get the product request from the data.
const productRequest = computed(() => {
  if (isProductDeliveryItem.value) {
    return data.value.product_request_item.product_request
  } else {
    return data.value.product_request
  }
})

const productInvoicePaymentType = computed(() => getPaymentType.value)
const lineItemPaymentType = computed(() => productRequest.value.payment_type)

const isRowDisabled = computed(() => {
  return productInvoicePaymentType.value !== lineItemPaymentType.value
})

const plannedDeliveryDate = computed(() => {
  if (isProductDeliveryItem.value) {
    return data.value.product_delivery.arrived_at
  } else {
    return data.value.requested_delivery_at
  }
})
const formattedPlannedDeliveryDate = computed(() => {
  return `${format(
    new Date(
      new Date(plannedDeliveryDate.value).toLocaleString('jp-JP', { timeZone: 'Asia/Tokyo' })
    ),
    'yyyy/MM/dd HH:mm'
  )} JST`
})

function handleCheckboxClick() {
  const lineItem = {
    ...data.value,
    product_request_index_id: productRequest.value.id + index.value
  }

  setSelectedLineItem(lineItem)
}

function handleLineItemChange(event) {
  if (isSelected.value) {
    // Update the item's key/value pair.
    const updatedLineItem = {
      ...data.value,
      [event.target.name]: event.target.value,
      product_request_index_id: productRequest.value.id + index.value
    }

    // Update the selected line items.
    updateSelectedLineItem(updatedLineItem)
  }
}
</script>

<template>
  <tr :class="isRowDisabled ? 'bg-light-gray' : ''">
    <td>
      <input
        v-if="!isRowDisabled || productRequest.each_time_payment"
        type="checkbox"
        name="product_delivery_item_id"
        :id="`product_invoice_product_invoice_line_items_attributes_${productRequest.id}${index}_product_delivery_item_id`"
        :value="data.id"
        @click="handleCheckboxClick"
        :checked="isSelected"
      />
    </td>
    <td>
      <a :href="`/product_requests/${productRequest.id}`" target="_blank">
        {{ data.full_product_name }}
      </a>
    </td>
    <td>{{ t(`features.product_request.payment_type.${lineItemPaymentType}`) }}</td>
    <td>
      <input
        type="number"
        name="quantity"
        :id="`product_invoice_product_invoice_line_items_attributes_${productRequest.id}${index}_quantity`"
        class="form-control"
        v-model="data[isProductDeliveryItem ? 'quantity' : 'requested_quantity']"
        @change="handleLineItemChange"
        :disabled="isRowDisabled"
      />
      <input
        type="hidden"
        name="product_request_item_id"
        :id="`product_invoice_product_invoice_line_items_attributes_${productRequest.id}${index}_product_request_item_id`"
        :value="data.product_request_item_id"
      />
    </td>
    <td>
      {{ formattedPlannedDeliveryDate }}
    </td>
    <td>
      <input
        type="text"
        name="comment"
        :id="`product_invoice_product_invoice_line_items_attributes_${productRequest.id}${index}_comment`"
        class="form-control"
        :disabled="isRowDisabled"
        @change="handleLineItemChange"
        v-model="data.comment"
      />
    </td>
  </tr>
</template>
