$.onmount('[data-component=shopify-graphs]', function () {
  var $graphs = $(this)

  var charts = {}

  $.get('/shopify/graphs/search?' + $('#search').serialize(), function (data) {
    renderCharts(data)
  })

  function daysBetween(date1, date2) {
    const oneDay = 1000 * 60 * 60 * 24;
    const differenceMs = Math.abs(date1 - date2);

    return Math.round(differenceMs / oneDay);
  }


  $('#search').on('submit', function (e) {
    var $form = $(this)
    $graphs.find('[data-chart]').closest('.card-body').addClass('-loading')

    var from = $form.find('#from').val()
    var to = $form.find('#to').val()

    if(daysBetween(new Date(from), new Date(to)) > 14) {
      $("#search-error").removeClass('d-none')
    }
    else {
      $("#search-error").addClass('d-none')
      $("#search-button").prop('disabled', true)
      $.ajax({
        url: '/shopify/graphs/search',
        method: 'GET',
        data: {
          from: from,
          to: to
        },
        success: function (data) {
          renderCharts(data)

          window.history.pushState('', '', '/shopify/graphs?' + $form.serialize())
        }
      })
    }

    e.preventDefault()
    return false
  })

  function renderCharts (data) {
    if (charts['sales']) charts['sales'].destroy()

    charts['sales'] = Charts.render($graphs.find('[data-chart=sales]'), {
      labels: data.sales.x,
      title: {
        display: true,
        text: 'Sales'
      },
      tooltips: {
        intersect: true
      },
      series: [{
        label: 'Value',
        data: data.sales.y,
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        borderColor: 'rgb(54, 162, 235)',
        borderWidth: 1
      }]
    })

    $graphs.find('[data-show-total=sales]').text('$' + totalValue(data.sales.y))

    if (charts['profit']) charts['profit'].destroy()

    charts['profit'] = Charts.render($graphs.find('[data-chart=profit]'), {
      labels: data.profit.x,
      title: {
        display: true,
        text: 'Profit'
      },
      tooltips: {
        intersect: true
      },
      series: [{
        label: 'Value',
        data: data.profit.y,
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        borderColor: 'rgb(54, 162, 235)',
        borderWidth: 1
      }]
    })

    $graphs.find('[data-show-total=profit]').text('$' + totalValue(data.profit.y))

    $graphs.find('[data-chart]').closest('.card-body').removeClass('-loading')
    $("#search-button").prop('disabled', false)
  }

  function totalValue (values) {
    return values.reduce(function (total, current) {
      return Number(total) + Number(current)
    }, 0).toFixed(2)
  }
})
