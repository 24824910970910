import { defineStore } from 'pinia'

export const useOrdersStore = defineStore('orders', {
  state: () => ({
    loading: false,
    orders: [],
    counts: {},
    pagination: {},
    canUserShipOrders: false,
    support: false,
    baseUrl: '',
    chargebeeAdminUrl: '',
    returnReasons: {},
    shippingMethodsForPrinting: [],
    holdStatusReasons: [],
    planTypes: {},
    planPeriods: {},
    statuses: {},
    shippingMethods: {},
    conversionPlatforms: {},
    orderRisks: {},
    invoiceDisputeReasons: {},
    sources: {},
    countries: {},
    stateCodes: {},
    tags: [],
    shipmentStatuses: {},
    subscriptionStatuses: {},
    shippingInvoiceStatuses: {},
    filters: {}
  }),

  getters: {
    isLoading: (state) => state.loading,
    getOrders: (state) => state.orders,

    getBrandCounts: (state) => state.counts.brand_counts,
    getVariantCounts: (state) => state.counts.variant_counts,
    getFirstInvoiceCount: (state) => state.counts.first_invoice_count,
    getRenewalCount: (state) => state.counts.renewal_count,

    canShipOrders: (state) => state.canUserShipOrders,
    isSupport: (state) => state.support,

    getPagination: (state) => state.pagination,

    getTotalResults: (state) => state.pagination.total_count,

    getChargebeeAdminUrl: (state) => state.chargebeeAdminUrl,
    getReturnReasons: (state) => state.returnReasons
  },

  actions: {
    setInitialState(data) {
      const initialData = data.value
      Object.keys(initialData).forEach((key) => {
        this[key] = initialData[key]
      })
    },

    setLoading(boolean) {
      this.loading = boolean
    },

    async fetchOrders(query) {
      this.setLoading(true)

      const response = await fetch(`${this.baseUrl}?${query}`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })

      if (!response.ok) {
        const message = `An error has occurred: ${response.status} ${response.statusText}`
        throw new Error(message)
      }

      const data = await response.json()

      this.counts = data.counts
      this.orders = data.orders
      this.pagination = data.pagination

      this.setLoading(false)
    }
  }
})
