import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['hiddenInput']

  changeSelection() {
    if (this.hiddenInputTarget.value === '0') {
      this.hiddenInputTarget.value = '1'
    } else {
      this.hiddenInputTarget.value = '0'
    }
  }
}
