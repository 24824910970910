<template>
  <div class='modal fade' role='dialog' :id='id'>
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class='modal-title'>{{ title }}</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class='modal-body'>
          <label for='selected-orders' class='form-check'>
            <input class='form-check-input' id='selected-orders' type='radio' value='selectedOrders'
              v-model='selectedOption' />
            Selected Orders ({{ orderIds.length }})
          </label>
          <label for='filtered-orders' class='form-check'>
            <input class='form-check-input' id='filtered-orders' type='radio' value='filteredOrders'
              v-model='selectedOption' />
            <div class='mb-2'>Filtered Orders ({{ totalResultsDisplay }})</div>
            <input type='number' :max='totalResults' class='form-control' v-model='filteredOrderCount'
              @focus='selectedOption = "filteredOrders"'>
          </label>

          <div v-if='selectedOption == "filteredOrders"' class='alert alert-warning mt-3 mb-3'>
            This will always update the <strong>first results</strong> for this query even if you are currently on a
            later page.
          </div>

          <slot />
        </div>
        <div class="modal-footer">
          <button class='btn btn-primary mt-2 mb-2' :disabled='disabled || loading || selectedOrderCount === 0'
            @click='submit'>
            {{ loading ? 'Working ...' : title }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import selectedOrderIds from 'javascripts/stores/orders/selected-ids';
import Multiselect from 'vue-multiselect';

export default {
  props: {
    id: String,
    title: String,
    filters: Object,
    params: Object,
    disabled: Boolean,
    totalResults: Number
  },

  components: { Multiselect },

  data() {
    return {
      loading: false,
      selectedOption: 'selectedOrders',
      filteredOrderCount: 0
    }
  },

  computed: {
    orderIds() {
      return selectedOrderIds.currentlySelected
    },

    selectedOrderCount() {
      if (this.selectedOption === 'selectedOrders') {
        return this.orderIds.length
      } else {
        return this.filteredOrderCount
      }
    },

    totalResultsDisplay() {
      if (this.totalResults > 1000) {
        return `up to 1000/${this.totalResults}`
      } else {
        return `up to ${this.totalResults}`
      }
    }
  },

  methods: {
    submit() {
      const query = this.selectedOption === 'selectedOrders' ?
        { order_ids: this.orderIds } :
        { q: this.filters, limit: this.filteredOrderCount }

      this.loading = true

      fetch('/orders/bulk_update', {
        method: 'PUT',
        body: JSON.stringify({
          ...this.params,
          ...query
        }),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
        }
      }).then((response) =>
        response.json()
      ).then((data) => {
        window.open(`/bulk_actions/${data.bulk_action_id}`, '_blank').focus()

        this.loading = false

        $(`#${this.id}`).modal('hide')
      })
    },
  },
}

</script>
