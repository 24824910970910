<template>
  <tr class="nested-fields">
    <td>
      <search-products
        :supplierId="supplierId"
        :productRequestItem="productRequestItem"
        :handleProductSelection="handleProductSelection"
      />
      <tooltip :tooltip-text="errors['product_supplier']" :hidden="!errors['product_supplier']">
        <i class="ri-error-warning-fill text-danger" v-if="errors['product_supplier']"></i>
      </tooltip>
    </td>
    <td>
      <input
        class="form-control"
        type="number"
        readonly="readonly"
        v-model="productRequestItem.price_per_item"
      />
    </td>
    <td>
      <search-variants
        :productRequestItem="productRequestItem"
        :productSupplierId="
            productRequestItem.product_supplier_id || productRequestItem.product_supplier?.id
          "
        :handleVariantSelection="handleVariantSelection"
      />
    </td>
    <td>
      <select-stock-destination
        :productRequestItem="productRequestItem"
        :productSupplierId.number="
            productRequestItem.product_supplier_id || productRequestItem.product_supplier?.id
          "
        :productVariantId.number="productRequestItem.product_variant_id"
        :stockDestinationId="productRequestItem.stock_destination_id"
        :stockDestinationType="productRequestItem.stock_destination_type"
        :boxId="boxId"/>
      <tooltip :tooltip-text="errors['stock_destination_id']" :hidden="!errors['stock_destination_id']">
        <i class="ri-error-warning-fill text-danger" v-if="errors['stock_destination_id']"></i>
      </tooltip>
    </td>
    <td>
      <input
        :class="{ 'form-control': true }"
        min="1"
        :style="{ 'background-color': quantityValidationError ? '#FFF3E0' : 'white' }"
        type="number"
        v-model.number="productRequestItem.requested_quantity"
      />
      <tooltip :tooltip-text="errors['requested_quantity']" :hidden="!errors['requested_quantity']">
        <i class="ri-error-warning-fill text-danger" v-if="errors['requested_quantity']"></i>
      </tooltip>
    </td>
    <td>
      <flat-pickr
        class="form-control"
        name="requestedDeliveryAt"
        placeholder="Select requested delivery date"
        v-model="productRequestItem.requested_delivery_at"
        :config="config"
      />
      <tooltip :tooltip-text="errors['requested_delivery_at']" :hidden="!errors['requested_delivery_at']">
        <i class="ri-error-warning-fill text-danger" v-if="errors['requested_delivery_at']"></i>
      </tooltip>
    </td>
    <td>
      <input class="form-control" type="number" v-model="productRequestItem.tax_rate" min="1.0" />
      <tooltip :tooltip-text="errors['tax_rate']" :hidden="!errors['tax_rate']">
        <i class="ri-error-warning-fill text-danger" v-if="errors['tax_rate']"></i>
      </tooltip>
    </td>
    <td>
      <textarea class="form-control" v-model="productRequestItem.comment" />
    </td>
    <td>
      <textarea class="form-control" v-model="productRequestItem.internal_comment" />
    </td>
    <td>
      <Tooltip
        :tooltip-text="t('features.product_request.product_request_items.delete_item_tip')"
        :hidden="productRequestStore.canRemoveProductRequestItem(productRequestItem.id, index)"
      >
        <button
          class="btn btn-light text-danger"
          @click.prevent="deleteProductRequestItem(index, productRequestItem)"
          :disabled="!productRequestStore.canRemoveProductRequestItem(productRequestItem.id, index)"
        >
          <i class="ri-close-line" />
        </button>
      </Tooltip>
    </td>
  </tr>
  <tr>
    <td colspan="10">
      <div class="row">
        <div class="col-sm-8 form-group">
          <label>{{ t('activerecord.models.delivery_plan_batch.one') }}</label>
          <request-item-batch
            v-for="(plannedBatchItem, index) in productRequestItem.planned_batch_items"
            :index="index"
            :key="plannedBatchItem.id"
            :totalQuantity="productRequestItem.requested_quantity"
            :batches="allDeliveryPlanBatches"
            :plannedBatchItem="plannedBatchItem"
            :deletePlannedBatchItem="deletePlannedBatchItem"
            :errors="errors"
          />
        </div>
        <div class="col-sm-4">
          <div
            v-if="quantityValidationError"
            class="alert alert-warning d-flex align-items-center mt-4"
            role="alert"
          >
            <div class="ml-2">
              {{
                t(
                  'features.product_request.planned_batch_items.total_quantity_is_different_warning',
                  {
                    total_quantity: productRequestItem.requested_quantity,
                    quantity_in_batches: quantityInBatches
                  }
                )
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="defaultBatchToAttach">
        <div class="col-sm-12">
          <button class="btn btn-secondary ml-2" @click="addNewPlannedBatchItem">
            {{ t('features.product_request.planned_batch_items.split_to_another_batch') }}
          </button>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import FlatPickr from 'vue-flatpickr-component'
import SearchProducts from './search-products.vue'
import SearchVariants from './search-variants.vue'
import SelectStockDestination from './select-stock-destination.vue'
import RequestItemBatch from './delivery-plans/request-item-batch.vue'
import Tooltip from '../tooltip'
import {mapStores} from "pinia";
import {useProductRequestStore} from "../../stores/productRequestStore";

export default {
  inject: ['i18n'],
  components: {
    Tooltip,
    FlatPickr,
    SearchProducts,
    SearchVariants,
    SelectStockDestination,
    RequestItemBatch
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    productRequestId: {
      required: true,
      type: Number
    },
    supplierId: {
      required: true,
      type: Number
    },
    boxId: {
      required: true,
      type: Number
    },
    productRequestItem: {
      required: true,
      type: Object
    },
    deleteProductRequestItem: {
      required: true,
      type: Function
    },
    allDeliveryPlanBatches: {
      required: true,
      type: Array
    },
    removedPlannedBatchItems: {
      required: true,
      type: Array
    },
    errors: {
      required: false,
      type: Object
    }
  },
  computed: {
    config() {
      return {
        dateFormat: 'Y/m/d H:i',
        altFormat: 'Y/m/d H:i',
        altInput: true,
        enableTime: true,
        enableSeconds: false,
        time_24hr: true,
        defaultHour: 0,
        defaultMinute: 0
      }
    },
    quantityInBatches() {
      return this.productRequestStore.quantityInBatches(this.productRequestItem.id, this.index)
    },
    defaultBatchToAttach() {
      return this.productRequestStore.defaultBatchToAttach()
    },
    quantityValidationError() {
      return this.productRequestItem.requested_quantity !== this.quantityInBatches
    },
    productSupplierId() {
      return this.productRequestItem.product_supplier_id
    },
    requestedQuantity() {
      return this.productRequestItem.requested_quantity
    },
    taxRate() {
      return this.productRequestItem.tax_rate
    },
    comment() {
      return this.productRequestItem.comment
    },
    internalComment() {
      return this.productRequestItem.internal_comment
    },
    requestedDeliveryAt() {
      return this.productRequestItem.requested_delivery_at
    },
    ...mapStores(useProductRequestStore)
  },

  data() {
    return {
      t: (key, options) => this.i18n.t(key, options),
      selectedBox: [],
      boxes: [],
      canSplitToBatches: true,
      saving: false,
      plannedBatchItems: []
    }
  },
  mounted() {
    this.updatePricePerItemToLatest()
  },
  methods: {
    updatePricePerItemToLatest() {
      if (this.productRequestItem.product_supplier) {
        this.productRequestItem.price_per_item = this.productRequestItem.product_supplier.buy_price
      }
    },
    handleProductSelection(selectedProduct) {
      this.productRequestStore.applySelectedProduct(this.productRequestItem.id, this.index, selectedProduct)
    },
    handleVariantSelection(variant) {
      this.productRequestItem.product_variant_id = variant?.id
    },
    addNewPlannedBatchItem() {
      this.productRequestStore.addNewPlannedBatchItem(this.productRequestItem.id, this.index)
    },
    deletePlannedBatchItem(plannedBatchIndex, batchItem) {
      this.productRequestStore.deletePlannedBatchItem(this.productRequestItem.id, this.index, plannedBatchIndex, batchItem)
    }
  }
}
</script>
