$.onmount('[data-toggle=addon-type-select]', function () {
  var $el = $(this)

  $el.on('click', function (e) {
    e.preventDefault()

    if ( !$el.hasClass('active') ) {
      var $parent = $(this).parents('.nested-fields')

      $parent.find('.btn').removeClass('active')
      $el.addClass('active')

      $parent.find('.addon-type').addClass('d-none')
      $parent.find('.addon-type[role=' + this.dataset.type+ ']').removeClass('d-none')
      $parent.find('input.form-control').val('')
      $parent.find('select.form-control')[0].selectize.setValue('')
      $parent.find('select.form-control')[1].selectize.setValue('')
    }
  })
})
