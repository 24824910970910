import { createApp } from 'vue'
import ShipmentBoxFilters from '../../components/shipment-boxes/filters.vue'
import $bus from '../../../packs/event'

$.onmount('[data-vue=shipment-box-filters]', function () {
  const initialData = JSON.parse(this.attributes['data-initial-data'].value)
  const app = createApp(ShipmentBoxFilters, { initialData })
  app.config.globalProperties.$bus = $bus;

  app.mount(this)
})
