<template>
  <oms-filter-content :title='title' :is-filtering='modelValue && modelValue !== defaultValue'>
    {{ content }}
  </oms-filter-content>
</template>

<script>
import OmsFilterContent from '../filter-content.vue'

export default {
  props: {
    title: String,
    mapping: Array,
    modelValue: String,
    defaultValue: String
  },

  computed: {
    content() {
      if (!this.mapping) {
        return this.modelValue
      }

      const option = this.mapping.find(({ id }) => id === this.modelValue)
      return option && option.name
    }
  },

  components: {
    OmsFilterContent
  }
}
</script>
