import {defineStore} from 'pinia'
import {uniqueId} from "lodash/util";
import {format} from "date-fns";
import {isEqualWith, pickBy, uniq} from "lodash"
import {omsCsrfHeader} from "../fetch-utils";
import * as Yup from 'yup';
import {useForm} from "vee-validate";
import {computed, ref} from "vue";

// See https://vee-validate.logaretm.com/v4/examples/using-stores
// TODO: Add i18n translations for validations somehow
const deliveryPlanBatchSchema = Yup.object({
    expected_delivery_at: Yup.date().required()
})

const plannedBatchItemSchema = Yup.object({
    quantity: Yup.number().required().min(1)
})

const itemValidationSchema = Yup.object({
    product_supplier: Yup.object().required(),
    requested_delivery_at: Yup.date().required(),
    requested_quantity: Yup.number().required().min(1),
    tax_rate: Yup.number().required().min(0),
    stock_destination_id: Yup.number().required(),
    planned_batch_items: Yup.array().of(plannedBatchItemSchema)
})

const validationSchema = Yup.object({
    supplier: Yup.object().required(),
    productRequestItems: Yup.array().of(itemValidationSchema).min(1),
    deliveryPlanBatches: Yup.array().of(deliveryPlanBatchSchema)
})

export const useProductRequestStore = defineStore('productRequest',
    () => {
        const {errors, useFieldModel, handleSubmit} = useForm({
            validationSchema: validationSchema,
        })

        const [supplier, productRequestItems, deliveryPlanBatches] = useFieldModel(['supplier', 'productRequestItems', 'deliveryPlanBatches'])

        // https://pinia.vuejs.org/core-concepts/#setup-stores
        // Properties
        const states = {
            id: ref(null),
            status: ref('draft'),
            paymentStatus: ref(''),
            supplier: ref(supplier),
            directorEmails: ref(''),
            box: ref(null),
            shouldSubmitEmailToDirectors: ref(false),
            shouldSendEmail: ref(true),
            isEachTimePayment: ref(false),
            /** @type 'postpaid' | 'prepaid' **/
            paymentType: ref('postpaid'),
            additionalRecipients: ref(''),
            address: ref('warehouse_a'),
            comment: ref(''),
            productRequestItems: ref(productRequestItems),
            removedRequestItems: ref([]),
            deliveryPlanBatches: ref(deliveryPlanBatches),
            removedDeliveryPlanBatches: ref([]),
            removedPlannedBatchItems: ref([])
        }
        // Defaults for field models
        states.productRequestItems.value = []
        states.deliveryPlanBatches.value = []

        // Computes
        const computes = {
            supplierId: computed(() => {
                return states.supplier.value?.id
            }),
            statusBadge: computed(() => {
                switch (states.status.value) {
                    case "draft":
                        return 'badge-secondary'
                    case "submitted_to_director":
                        return 'badge-primary'
                    case "changes_requested":
                        return 'badge-warning'
                    case "cancelled":
                        return 'badge-danger'
                    case "approved":
                        return 'badge-info'
                    case "partially_delivered":
                        return 'bg-light-warning'
                    case "sent_to_supplier":
                        return 'badge-success'
                    case "delivered":
                        return 'bg-light-success'
                }
            }),
            paymentStatusBadge: computed(() => {
                switch (states.paymentStatus.value) {
                    case "unpaid":
                        return 'badge-secondary'
                    case "fully_paid":
                        return 'badge-success'
                    case "partially_paid":
                        return 'badge-info'
                }
            }),
            isUpdatable: computed(() => {
                return states.paymentStatus.value === 'unpaid'
            }),
            destinations: computed(() => {
                return [
                    {
                        address: '〒143-0006 東京都大田区平和島1-2-20 A棟2階 (Warehouse A)',
                        value: 'warehouse_a',
                        enabled: true
                    },
                    {
                        address: '〒108-0073 東京都港区三田3-7-16 第二御田八幡ビル2階 (Former Office)',
                        value: 'office',
                        enabled: true
                    },
                    {
                        address: '〒108-0014 東京都港区芝5丁目13-18 いちご三田ビル7F(Ichigo Office)',
                        value: 'ichigo_office',
                        enabled: true
                    },
                    {
                        address: '〒143-0006 東京都大田区平和島1-2-20 B棟3階背面 (Warehouse)',
                        value: 'warehouse',
                        enabled: false
                    }
                ]
            }),
            canSendToDirectors: computed(() => {
                switch (states.status.value) {
                    case "draft":
                    case "submitted_to_director":
                    case "changes_requested":
                    case "approved":
                    case "sent_to_supplier":
                        return true
                }

                return false
            }),
            removedProductRequestItemsParam: computed(() => {
                return states.removedRequestItems.value.map((item) => {
                    return {id: item.id}
                })
            }),
            productRequestParam: computed(() => {
                let status = 'draft'
                if (!actions.isNew()) status = states.shouldSubmitEmailToDirectors.value ? 'submitted_to_director' : undefined
                return {
                    status: status,
                    director_emails: states.directorEmails.value,
                    supplier_id: states.supplier.value?.id,
                    send_email: states.shouldSendEmail.value,
                    each_time_payment: states.isEachTimePayment.value,
                    payment_type: states.paymentType.value,
                    additional_recipients: states.additionalRecipients.value,
                    address: states.address.value,
                    comment: states.comment.value
                }
            }),
            deliveryPlanBatchesParams: computed(() => {
                return states.deliveryPlanBatches.value.map((planBatch) => {
                    return actions.deliveryPlanBatchParams(planBatch)
                })
            }),
            removedDeliveryPlanBatchesParam: computed(() => {
                return states.removedDeliveryPlanBatches.value.map((item) => {
                    return {id: item.id}
                })
            }),
            productRequestItemsParam: computed(() => {
                return states.productRequestItems.value.map((item) => {
                    return actions.productRequestItemParam(item)
                })
            }),
            removedPlannedBatchItemsParam: computed(() => {
                return states.removedPlannedBatchItems.value.map((item) => {
                    return {id: item.id}
                })
            }),
            requestedDeliveryDates: computed(() => {
                const requestedDeliveryDates = states.productRequestItems.value.map((requestItem) => {
                    return requestItem.requested_delivery_at
                })
                return uniq(requestedDeliveryDates)
            }),
            defaultBatchToAttach: computed(() => {
                if (states.deliveryPlanBatches.value.length > 0) return states.deliveryPlanBatches.value[0]
            })
        }

        // Actions
        const actions = {
            setDeliveryPlanBatches: function (planBatches) {
                states.deliveryPlanBatches.value = planBatches
                actions.addTempIdToDeliveryPlanBatches()
            },
            setProductRequestItems: function (items) {
                states.productRequestItems.value = items
                actions.addTempIdToDeliveryPlanBatchesForRequestItems()
            },
            addNewProductRequestItem: function () {
                const newItem = {
                    id: undefined,
                    requested_delivery_at: new Date(),
                    price_per_item: null,
                    requested_quantity: null,
                    tax_rate: 8.0, // Default 8% is for reduced tax rate.
                    product_supplier_id: null,
                    stock_destination_type: this.stockDestinationType,
                    stock_destination_id: this.stockDestinationId,
                    planned_batch_items: []
                }
                states.productRequestItems.value = [...states.productRequestItems.value, newItem]
            },
            selectExpectedDeliveryAt: function (selectedBatch, expectedDeliveryAt) {
                // Update delivery_plan_batch in productRequestItems
                states.productRequestItems.value.map((requestItem) => {
                    requestItem.planned_batch_items?.map((batchItem) => {
                        if (batchItem.delivery_plan_batch?.tmp_id === selectedBatch?.tmp_id) {
                            batchItem.expected_delivery_at = expectedDeliveryAt
                        }
                    })
                })
            },
            deleteProductRequestItem: function (productRequestItemIndex, productRequestItem) {
                if (!productRequestItem.id) {
                    states.productRequestItems.value =
                        states.productRequestItems.value.filter((item, index) => index !== productRequestItemIndex)
                } else {
                    actions.removeProductRequestItem(productRequestItem.id)
                }
            },
            removeProductRequestItem: function (productRequestItemId) {
                states.removedRequestItems.value.push(states.productRequestItems.value.find(
                    (item) => item.id === productRequestItemId
                ))
                states.productRequestItems.value = states.productRequestItems.value.filter(
                    (item) => item.id !== productRequestItemId)
            },
            deleteBatch: function (batch_index, batch) {
                if (!batch.id) {
                    states.deliveryPlanBatches.value = states.deliveryPlanBatches.value.filter((b, index) => index !== batch_index)
                } else {
                    actions.removeDeliveryPlanBatch(batch.id)
                }
            },
            removeDeliveryPlanBatch: function (deliveryPlanBatchId) {
                states.removedDeliveryPlanBatches.value.push(states.deliveryPlanBatches.value.find(
                    (batch) => batch.id === deliveryPlanBatchId
                ))
                states.deliveryPlanBatches.value = states.deliveryPlanBatches.value.filter(
                    (batch) => batch.id !== deliveryPlanBatchId
                )
            },
            addTempIdToDeliveryPlanBatches: function () {
                states.deliveryPlanBatches.value.forEach((batch) => {
                    batch.tmp_id = batch.id ? batch.id : uniqueId('delivery-plan-batch-')
                })
            },
            addTempIdToDeliveryPlanBatchesForRequestItems: function () {
                states.productRequestItems.value.forEach((requestItem) => {
                    requestItem.planned_batch_items?.map((batchItem) => {
                        batchItem.delivery_plan_batch.tmp_id = batchItem.delivery_plan_batch.id
                    })
                })
            },
            newDeliveryPlanBatch: function (dateTime = undefined) {
                return {
                    id: undefined,
                    product_request_id: states.id.value,
                    expected_delivery_at: format(dateTime || new Date(), "yyyy-MM-dd HH:mm"),
                    product_request_items: [],
                    supplier: states.supplier.value
                }
            },
            addNewDeliveryPlanBatch: function (dateTime = undefined) {
                states.deliveryPlanBatches.value.push(actions.newDeliveryPlanBatch(dateTime))
                actions.addTempIdToDeliveryPlanBatches()
            },
            canDeleteDeliveryPlanBatch: function (batchTmpId) {
                // batch items should be removed first.
                const batchItems = states.productRequestItems.value.flatMap((requestItem) => {
                    return requestItem.planned_batch_items?.filter((batchItem) =>
                        batchItem.delivery_plan_batch.tmp_id === batchTmpId
                    )
                })
                return batchItems.length === 0
            },
            productRequestItemParam: function (item) {
                return {
                    id: item.id,
                    requested_delivery_at: item.requested_delivery_at,
                    price_per_item: item.price_per_item,
                    requested_quantity: item.requested_quantity,
                    tax_rate: item.tax_rate,
                    product_supplier_id: item.product_supplier?.id,
                    product_variant_id: item.product_variant_id,
                    stock_destination_type: item.stock_destination_type,
                    stock_destination_id: item.stock_destination_id,
                    comment: item.comment,
                    internal_comment: item.internal_comment,
                    planned_batch_items_attributes: actions.plannedBatchItemsForRequestItemParams(item)
                }
            },
            plannedBatchItemsForRequestItemParams: function (requestItem) {
                return requestItem.planned_batch_items?.map((batchItem) => {
                    return {
                        id: batchItem.id,
                        quantity: batchItem.quantity,
                        delivery_plan_batch_id: batchItem.delivery_plan_batch.id,
                        delivery_plan_batch_tmp_id: batchItem.delivery_plan_batch.tmp_id,
                        product_request_item_id: batchItem.product_request_item_id
                    }
                })
            },
            deliveryPlanBatchParams: function (planBatch) {
                return {
                    id: planBatch.id,
                    tmp_id: planBatch.tmp_id,
                    product_request_id: states.id.value,
                    expected_delivery_at: planBatch.expected_delivery_at
                }
            },
            addDefaultQuantityToBatchItems: function (date) {
                actions.addNewDeliveryPlanBatch(date)
            },
            addDefaultDeliveryPlanFromRequestedDates: function () {
                if (states.deliveryPlanBatches.value.length > 0) return

                computes.requestedDeliveryDates?.value?.forEach((date) => {
                    actions.addNewDeliveryPlanBatch(new Date(date))
                })
                actions.addDefaultValuesToPlannedBatchItems()
            },
            addDefaultValuesToPlannedBatchItems: function () {
                states.deliveryPlanBatches.value.forEach((batch) => {
                    states.productRequestItems.value.forEach((requestItem) => {
                        if (!requestItem.requested_delivery_at) return
                        if (!actions.isSameDateTime(requestItem.requested_delivery_at, batch.expected_delivery_at)) return

                        const batchItem = actions.newDefaultPlannedBatchItem(batch, requestItem)
                        requestItem.planned_batch_items.push(batchItem)
                    })
                })
            },
            isSameDateTime: function (value, other) {
                const customizer = (objValue, othValue) => {
                    const dateFormat = "yyyy-MM-dd HH:mm"
                    return format(new Date(objValue), dateFormat) === format(new Date(othValue), dateFormat)
                }
                return isEqualWith(value, other, customizer)
            },
            newDefaultPlannedBatchItem: function (batch, productRequestItem) {
                return {
                    id: undefined,
                    product_request_item_id: productRequestItem.id,
                    quantity: productRequestItem.requested_quantity,
                    delivery_plan_batch: batch
                }
            },
            fillProductRequestItems: async function () {
                const items = await fetch(`/boxes/${this.box.id}/fill_product_request_items?supplier_id=${this.supplier.id}`, {
                    method: 'GET',
                    headers: omsCsrfHeader()
                })
                states.productRequestItems.value = [...states.productRequestItems.value, ...await items.json()]
            },
            isBoxSelected: function () {
                return !!states.box.value && !!states.box.value.id
            },
            isNew: function () {
                return !states.id.value
            },
            itemValidationErrors: function (index) {
                // Return only an object have specific request item's validation properties.
                const itemErrors = pickBy(errors.value, function (value, key) {
                    return _.startsWith(key, `productRequestItems[${index}]`)
                })
                // Shorten long property names and values.
                let errorObject = {}
                Object.entries(itemErrors).forEach(([k, v]) => {
                    const pattern = /productRequestItems\[[0-9]+\]\./
                    const abbreviatedKey = k.replace(new RegExp(pattern), '')
                    errorObject[abbreviatedKey] = v.replace(new RegExp(pattern), '')
                })
                return errorObject
            },
            batchItemValidationErrors: function (itemErrors, index) {
                const batchItemErrors = pickBy(itemErrors, function (value, key) {
                    return _.startsWith(key, `planned_batch_items[${index}]`)
                })
                let errorObject = {}
                Object.entries(batchItemErrors).forEach(([k, v]) => {
                    const pattern = /planned_batch_items\[[0-9]+\]\./
                    const abbreviatedKey = k.replace(new RegExp(pattern), '')
                    errorObject[abbreviatedKey] = v.replace(new RegExp(pattern), '')
                })
                return errorObject
            },
            defaultBatchToAttach: function () {
                if (states.deliveryPlanBatches.value.length > 0) return states.deliveryPlanBatches.value[0]
            },
            paramsToCreate: function () {
                return {
                    product_request: {
                        ...computes.productRequestParam.value,
                        product_request_items_attributes: computes.productRequestItemsParam.value
                    }
                }
            },
            paramsToUpdate: function () {
                return {
                    product_request: {
                        ...computes.productRequestParam.value,
                        product_request_items_attributes: computes.productRequestItemsParam.value,
                    },
                    remove_product_request_items: computes.removedProductRequestItemsParam.value,
                    remove_delivery_plan_batches: computes.removedDeliveryPlanBatchesParam.value,
                    remove_planned_batch_items: computes.removedPlannedBatchItemsParam.value,
                    delivery_plan_batches: computes.deliveryPlanBatchesParams.value
                }
            },
            newPlannedBatchItem: function (productRequestItem) {
                return {
                    id: undefined,
                    product_request_item_id: productRequestItem.id,
                    quantity: 0,
                    delivery_plan_batch: actions.defaultBatchToAttach()
                }
            },
            canRemoveProductRequestItem: function (itemId, index) {
                return actions.item(itemId, index).planned_batch_items.length === 0
            },
            quantityInBatches: function (itemId, index) {
                return actions.item(itemId, index).planned_batch_items
                    .map((batch) => batch.quantity)
                    .reduce((acc, b) => acc + b, 0)
            },
            item: function (itemId, index) {
                // New item doesn't have an item id, use an index instead.
                return states.productRequestItems.value.find((item, idx) => {
                        if (itemId) {
                            return item.id === itemId
                        } else {
                            return idx === index
                        }
                    }
                )
            },
            deletePlannedBatchItem: function (itemId, index, plannedBatchIndex, batchItem) {
                if (!batchItem.id) {
                    actions.item(itemId, index).planned_batch_items =
                        actions.item(itemId, index).planned_batch_items.filter(
                            (plannedBatchItem, index) => index !== plannedBatchIndex
                        )
                } else {
                    actions.removePlannedBatchItem(itemId, index, batchItem)
                }
            },
            removePlannedBatchItem: function (itemId, index, plannedBatchItem) {
                states.removedPlannedBatchItems.value.push(
                    actions.item(itemId, index).planned_batch_items.find((item) => item.id === plannedBatchItem.id)
                )
                actions.item(itemId, index).planned_batch_items =
                    actions.item(itemId, index).planned_batch_items.filter(
                        (item) => item.id !== plannedBatchItem.id
                    )
            },
            addNewPlannedBatchItem: function (itemId, index) {
                if (!actions.defaultBatchToAttach()) {
                    throw new Error('Need at least one batch to create a Planned Batch Item')
                }

                const batchItem = actions.newPlannedBatchItem(actions.item(itemId, index))
                if (actions.item(itemId, index).planned_batch_items) {
                    actions.item(itemId, index).planned_batch_items.push(batchItem)
                } else {
                    actions.item(itemId, index).planned_batch_items = [batchItem]
                }
            },
            applySelectedProduct: function (itemId, index, selectedProduct) {
                let productRequestItem = actions.item(itemId, index)
                productRequestItem.product_supplier_id = selectedProduct.id
                productRequestItem.price_per_item = selectedProduct.buy_price

                if (!productRequestItem.product) {
                    productRequestItem.product = {}
                }

                productRequestItem.product['name'] = selectedProduct.name
                productRequestItem.product['barcode'] = selectedProduct.barcode
                productRequestItem.product['sku'] = selectedProduct.sku
                productRequestItem.product_supplier = {
                    id: selectedProduct.id,
                    buy_price: selectedProduct.buy_price
                }
            },
            fetchProductRequest: async function (id) {
                let newData
                const response = await fetch(`/product_requests/${id}`, {
                    headers: omsCsrfHeader()
                })
                const result = await response.json()
                if (result?.error) {
                    throw new Error(result.error)
                }
                newData = {...this.data, ...result}

                states.id.value = newData.id
                states.supplier.value = newData.supplier
                states.directorEmails.value = newData.director_emails
                states.shouldSubmitEmailToDirectors.value = false
                states.box.value = newData.box
                states.shouldSendEmail.value = newData.send_email
                states.isEachTimePayment.value = newData.each_time_payment
                states.paymentType.value = newData.payment_type
                states.additionalRecipients.value = newData.additional_recipients
                states.address.value = newData.address
                states.comment.value = newData.comment
                states.status.value = newData.status
                states.paymentStatus.value = newData.payment_status
                actions.setDeliveryPlanBatches(newData.delivery_plan_batches)
                actions.setProductRequestItems(newData.product_request_items)
            }
        }

        const create = handleSubmit(async (values) => {
            const bodyParams = actions.paramsToCreate()
            const response = await fetch(`/product_requests`, {
                    method: 'POST',
                    body: JSON.stringify(bodyParams),
                    headers: omsCsrfHeader()
                }
            )
            return await response.json()
        })

        const update = handleSubmit(async (values) => {
            const bodyParams = actions.paramsToUpdate()

            const response = await fetch(`/product_requests/${states.id.value}`, {
                method: 'PUT',
                body: JSON.stringify(bodyParams),
                headers: omsCsrfHeader()
            })
            return await response.json()
        })

        return {...states, ...computes, ...actions, errors, create, update};
    }
)