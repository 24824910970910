/**
 * Build query string
 * @param object filter object
 * @param prefix param's prefix
 * @returns {string}
 */
export default function buildQueryString(object, prefix = '') {
    if (!object) return ''

    if (Array.isArray(object)) {
        return object.map((item) => buildQueryString(item, `${prefix}[]`)).filter(Boolean).join('&')
    } else if (typeof object === 'object') {
        return Object.entries(object)
            .map(([key, value]) => buildQueryString(value, prefix ? `${prefix}[${key}]` : key))
            .filter(Boolean)
            .join('&')
    } else {
        return `${prefix}=${encodeURIComponent(object)}`
    }
}

export function appendSearchParams(searchParams, object, prefix = '') {
    if (!object) return

    if (Array.isArray(object)) {
        object.forEach((item) => appendSearchParams(searchParams, item, `${prefix}[]`))
    } else if (typeof object === 'object') {
        Object.entries(object)
            .forEach(([key, value]) => appendSearchParams(searchParams, value, prefix ? `${prefix}[${key}]` : key))
    } else {
        searchParams.append(prefix, object)
    }
}
