<script setup>
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useOrdersStore } from '../../stores/orders';

const store = useOrdersStore()

const { isLoading, getBrandCounts, getVariantCounts, getFirstInvoiceCount, getRenewalCount } =
  storeToRefs(store)

const brandCounts = computed(() => getBrandCounts.value)
const variantCounts = computed(() => getVariantCounts.value)
const firstInvoiceCount = computed(() => getFirstInvoiceCount.value)
const renewalCount = computed(() => getRenewalCount.value)
</script>

<template>
  <div class="mb-5" role="search-count">
    <div class="d-md-flex loading-area" :class="isLoading ? '-loading' : ''">
      <div class="spinner-border text-secondary"></div>
      <div class="flex-1">
        <div class="d-flex flex-wrap">
          <div
            class="card flex-1 mb-3 mr-md-3"
            v-for="(value, index) in variantCounts"
            :key="index"
            :class="`bg-light-${value.color}`"
          >
            <div class="card-body p-1 p-md-3 text-center">
              <h3 class="h6">{{ value.title }}</h3>
              <p class="h3 mb-0">{{ value.count }}</p>
            </div>
          </div>
        </div>
        <div class="d-flex flex-wrap">
          <div
            class="card flex-1 mb-3 mr-md-3"
            v-for="(value, index) in brandCounts"
            :key="index"
            :class="'bg-light-' + value.color"
          >
            <div class="card-body p-1 p-md-3 text-center">
              <h3 class="h6">{{ value.title }}</h3>
              <p class="h3 mb-0">{{ value.count }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="firstInvoiceCount != null && renewalCount != null" class="card mb-3 bg-light-secondary">
        <div class="card-body p-3 d-flex flex-column justify-content-center text-center">
          <h3 class="h6">First Invoice</h3>
          <p class="h5 mb-1">{{ firstInvoiceCount }}</p>
          <hr class="w-100" />
          <h3 class="h6">Renewal</h3>
          <p class="h5 mb-1">{{ renewalCount }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
