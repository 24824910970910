import dragula from 'dragula'

$.onmount('[data-component=shopify_product_editor]', function () {
  var $el = $(this)
  var $variantName = $el.find('input[name="shopify_product[variant_name]"]')

  var recalculatePositions = function () {
    $el.find('.shopify-variant-editor').each(function (index) {
      $(this).find('input[name*="[position]"]').val(index + 1)
    })
  }

  $variantName.on('input', function () {
    $el.find('label.product-variant-name').text($variantName.val() || '(value)')
  })

  $el.on('cocoon:after-insert', function (e, $inserted) {
    $inserted.find('label.product-variant-name').text($variantName.val() || '(value)')
    recalculatePositions()
  })

  dragula([$el.find('#shopify_product_variants')[0]], {
    moves: function (el, container, handle) {
      return handle.closest('.card-header')
    }
  }).on('drop', recalculatePositions)
})
