<template>
  <tr class="nested-fields">
    <td>
      <search-products
        :supplierId="supplierId"
        :productRequestItem="productRequestItem"
        :handleProductSelection="handleProductSelection"
      />
      <tooltip :tooltip-text="errors['product_supplier']" :hidden="!errors['product_supplier']">
        <i class="ri-error-warning-fill text-danger" v-if="errors['product_supplier']"></i>
      </tooltip>
    </td>
    <td>
      <input
        class="form-control"
        type="number"
        readonly="readonly"
        v-model="productRequestItem.price_per_item"
      />
    </td>
    <td>
      <search-variants
        :productRequestItem="productRequestItem"
        :productSupplierId="
                productRequestItem.product_supplier_id || productRequestItem.product_supplier?.id
              "
        :handleVariantSelection="handleVariantSelection"
      />
    </td>
    <td>
      <select-stock-destination :productRequestItem="productRequestItem"
                                :productSupplierId.number="productRequestItem.product_supplier_id || productRequestItem.product_supplier?.id"
                                :productVariantId.number="productRequestItem.product_variant_id"
                                :stockDestinationId="productRequestItem.stock_destination_id"
                                :stockDestinationType="productRequestItem.stock_destination_type"
                                :boxId="boxId"/>


      <tooltip :tooltip-text="errors['stock_destination_id']" :hidden="!errors['stock_destination_id']">
        <i class="ri-error-warning-fill text-danger" v-if="errors['stock_destination_id']"></i>
      </tooltip>
    </td>
    <td>
      <input :class="{ 'form-control': true }" min="1"
             :style="{ 'background-color': 'white' }" type="number"
             v-model.number="productRequestItem.requested_quantity" />
      <tooltip :tooltip-text="errors['requested_quantity']" :hidden="!errors['requested_quantity']">
        <i class="ri-error-warning-fill text-danger" v-if="errors['requested_quantity']"></i>
      </tooltip>
    </td>
    <td>
      <flat-pickr class='form-control' name="requestedDeliveryAt" placeholder="Select requested delivery date"
                  v-model="productRequestItem.requested_delivery_at" :config='config' />
      <tooltip :tooltip-text="errors['requested_delivery_at']" :hidden="!errors['requested_delivery_at']">
        <i class="ri-error-warning-fill text-danger" v-if="errors['requested_delivery_at']"></i>
      </tooltip>
    </td>
    <td>
      <input class="form-control" type="number" v-model="productRequestItem.tax_rate" min="1.0" />
      <tooltip :tooltip-text="errors['tax_rate']" :hidden="!errors['tax_rate']">
        <i class="ri-error-warning-fill text-danger" v-if="errors['tax_rate']"></i>
      </tooltip>
    </td>
    <td>
      <textarea class="form-control" v-model="productRequestItem.comment" />
    </td>
    <td>
      <textarea class="form-control" v-model="productRequestItem.internal_comment" />
    </td>
    <td>
      <button class="btn btn-light text-danger"
              @click.prevent="deleteProductRequestItem(index, productRequestItem)">
        <i class="ri-close-line" />
      </button>
    </td>
  </tr>
</template>

<script>
import FlatPickr from 'vue-flatpickr-component'
import SearchProducts from './search-products.vue'
import SearchVariants from './search-variants.vue'
import SelectStockDestination from './select-stock-destination.vue'
import Tooltip from "../tooltip.vue";

export default {
  inject: ['i18n'],
  components: {Tooltip, FlatPickr, SearchProducts, SearchVariants, SelectStockDestination},
  props: {
    index: {
      type: Number,
      required: true
    },
    productRequestId: {
      required: false,
      type: Number
    },
    supplierId: {
      required: true,
      type: Number
    },
    boxId: {
      required: true,
      type: Number
    },
    productRequestItem: {
      required: true,
      type: Object
    },
    deleteProductRequestItem: {
      required: true,
      type: Function
    },
    errors: {
      required: false,
      type: Object
    }
  },

  computed: {
    config() {
      return {
        dateFormat: 'Y/m/d H:i',
        altFormat: 'Y/m/d H:i',
        altInput: true,
        enableTime: true,
        enableSeconds: false,
        time_24hr: true,
        defaultHour: 0,
        defaultMinute: 0,
      }
    },
    productSupplierId() {
      return this.productRequestItem.product_supplier_id
    },
    requestedQuantity() {
      return this.productRequestItem.requested_quantity
    },
    taxRate() {
      return this.productRequestItem.tax_rate
    },
    comment() {
      return this.productRequestItem.comment
    },
    internalComment() {
      return this.productRequestItem.internal_comment

    },
    requestedDeliveryAt() {
      return this.productRequestItem.requested_delivery_at
    }
  },

  methods: {
    handleProductSelection(selectedProduct) {
      this.productRequestItem.product_supplier_id = selectedProduct.id
      this.productRequestItem.price_per_item = selectedProduct.buy_price

      if (!this.productRequestItem.product) {
        this.productRequestItem.product = {}
      }

      this.productRequestItem.product["name"] = selectedProduct.name
      this.productRequestItem.product["barcode"] = selectedProduct.barcode
      this.productRequestItem.product["sku"] = selectedProduct.sku

      if (!this.productRequestItem.product_supplier) {
        this.productRequestItem.product_supplier = {
          id: selectedProduct.id,
          buy_price: selectedProduct.buy_price,
        }
      }
    },
    handleVariantSelection(variant) {
      this.productRequestItem.product_variant_id = variant?.id
    }
  }
}
</script>