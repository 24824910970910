<script setup>
import NewProductInvoiceForm from '../../components/product-invoices/new/form'
import { storeToRefs } from 'pinia'
import { toRefs, onMounted, inject, computed } from 'vue'
import { useProductInvoiceStore } from '../../stores/productInvoiceStore'

const i18n = inject('i18n')

const props = defineProps({
  initialData: {
    type: Object,
    required: true
  }
})

const { initialData } = toRefs(props)
const store = useProductInvoiceStore()

// Store getters
const { getErrors, hasErrors } = storeToRefs(store)
// Store actions
const { setInitialState } = store

const { title } = initialData.value

const errors = computed(() => getErrors.value)

const t = (key, options) => i18n.t(key, options)

onMounted(() => {
  setInitialState(initialData)
})
</script>

<template>
  <div role="new-product-invoice">
    <section class="section-wrapper w-full-page form-box">
      <div class="content">
        <div class="row">
          <div class="col-md-8 offset-md-2">
            <div class="header">
              <h2 class="title">{{ title }}</h2>
            </div>
            <div v-if="hasErrors" class="alert alert-danger">
              {{ t('errors.forms.request_fix_message') }}
              <ul>
                <template v-for="(error, key) in errors" :key="key">
                  <li v-if="error.length > 0">
                    {{ error }}
                  </li>
                </template>
              </ul>
            </div>
            <div class="card p-4">
              <NewProductInvoiceForm />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
