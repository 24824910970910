<template>
  <div class="dropdown">
    <button class="btn btn-secondary" :disabled="disabled" type="button" data-toggle="dropdown" aria-haspopup="true"
      aria-expanded="false">
      <i class='ri-more-fill'></i>
    </button>
    <div class="dropdown-menu" aria-labelledby="orderActions">
      <a class="dropdown-item" data-toggle="modal" data-target="#printLabelsModal" href='#'>Print Labels</a>
      <a class="dropdown-item" @click="exportCsv" href='#'>Export CSV</a>
      <a class="dropdown-item" @click="exportInvoiceRevenue" href='#'>Export Invoice Revenue</a>
      <a class="dropdown-item" data-toggle="modal" data-target="#holdOrdersModal" href='#'>Hold Orders</a>
      <a class="dropdown-item" data-toggle="modal" data-target="#adjustTagsModal" href='#'>Adjust Tags</a>
    </div>
    <print-labels-modal :filters='filters' :shipping-methods='shippingMethods' />
    <oms-hold-orders-modal :filters='filters' :hold-status-reasons='holdStatusReasons || []' :total-results='totalResults' />
    <oms-adjust-tags-modal :filters='filters' :current-tags='currentTags || []' :total-results='totalResults' />
  </div>
</template>

<script>
import buildQueryString from "../../helpers/query-builder";
import PrintLabelsModal from './print-labels-modal.vue';
import OmsHoldOrdersModal from './hold-orders-modal.vue';
import OmsAdjustTagsModal from './adjust-tags-modal.vue';

export default {
  props: {
    disabled: Boolean,
    filters: Object,
    shippingMethods: Array,
    holdStatusReasons: Array,
    currentTags: Array,
    totalResults: Number
  },

  methods: {
    exportCsv() {
      window.location.href = `orders/export?${buildQueryString(this.filters, 'q')}`
    },

    exportInvoiceRevenue() {
      window.location.href = `orders/export_invoice_revenue?${buildQueryString(this.filters, 'q')}`
    }
  },

  components: {
    PrintLabelsModal,
    OmsHoldOrdersModal,
    OmsAdjustTagsModal
  }
}

</script>
