<template>
  <ul class="pagination" v-if="total_count > per_page">
    <template v-if="prevPage">
      <li class="page-item">
        <a class="page-link" aria-disabled="false" href="#" @click.prevent="fetchOrdersPage()">«</a>
      </li>
      <li class="page-item">
        <a
          rel="prev"
          class="page-link"
          aria-disabled="false"
          href="#"
          @click.prevent="fetchOrdersPage(prevPage)"
          >‹</a
        >
      </li>
      <li v-if="current_page > 4" class="page-item disabled">
        <a class="page-link" aria-disabled="true">…</a>
      </li>
    </template>
    <li
      class="page-item"
      v-for="page in pages"
      :key="page"
      :class="current_page == page ? 'active' : ''"
    >
      <a
        rel="prev"
        class="page-link"
        aria-disabled="false"
        href="#"
        @click.prevent="fetchOrdersPage(page)"
      >
        {{ page }}
      </a>
    </li>
    <template v-if="nextPage">
      <li v-if="current_page < total_pages - 3" class="page-item disabled">
        <a class="page-link" aria-disabled="true">…</a>
      </li>
      <li class="page-item">
        <a
          rel="next"
          class="page-link"
          aria-disabled="false"
          href="#"
          @click.prevent="fetchOrdersPage(nextPage)"
          >›</a
        >
      </li>
      <li class="page-item">
        <a
          class="page-link"
          aria-disabled="false"
          href="#"
          @click.prevent="fetchOrdersPage(total_pages)"
          >»</a
        >
      </li>
    </template>
  </ul>
</template>

<script>
import buildQueryString from '../../../helpers/query-builder'

import { mapActions } from 'pinia'
import { useOrdersStore } from '../../stores/orders'

export default {
  props: {
    initialData: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      pages: [],
      params_query: '',
      total_count: 0,
      total_pages: 0,
      current_page: 1,
      per_page: 25,
      first_page: false,
      last_page: false,
      ...this.initialData
    }
  },

  watch: {
    initialData: {
      handler(newVal) {
        Object.keys(newVal).forEach((key) => {
          this[key] = newVal[key]
        })
        this.params_query = buildQueryString(newVal.params, 'q')
        this.paginate()
      },
      deep: true
    }
  },

  computed: {
    prevPage() {
      return this.current_page - 1 > 0 ? this.current_page - 1 : false
    },

    nextPage() {
      return this.current_page + 1 <= this.total_pages ? this.current_page + 1 : false
    }
  },

  methods: {
    ...mapActions(useOrdersStore, ['fetchOrders']),

    fetchOrdersPage(page = 1) {
      const query = `page=${page}${this.params_query ? `&${this.params_query}` : ''}`
      this.fetchOrders(query).then(() => {
        window.history.pushState('', '', `?${query}`)
      })
    },

    paginate() {
      // Calculate range of pages given a max of 6 pages before and after the current page
      let start = this.current_page - 3
      let end = this.current_page + 3
      if (start < 1) {
        start = 1
        end = 6
      }
      if (end > this.total_pages) {
        end = this.total_pages
        start = this.total_pages - 5
      }

      start = Math.max(start, 1)
      end = Math.min(end, this.total_pages)

      this.pages = Array.from(Array(end - start + 1).keys()).map((i) => start + i)
    }
  },

  mounted() {
    this.paginate()
  }
}
</script>
