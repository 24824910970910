import { createApp } from 'vue'
import { createPinia } from 'pinia'
import i18nPlugin, { i18n, loadTranslations } from '../../i18n'
import ShipmentsFilters from '../../components/shipments/filters.vue'

$.onmount('[data-vue=shipments-filters]', async function () {
  const initialData = JSON.parse(this.attributes['data-initial-data'].value)

  const pinia = createPinia()
  const app = createApp(ShipmentsFilters, { initialData })

  app.use(i18nPlugin)
  app.use(pinia)

  await loadTranslations(i18n)

  app.mount(this)
})
