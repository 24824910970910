$.onmount('[data-toggle=coupon-select]', function () {
  var $el = $(this)

  var onLoad = function (query, callback) {
    $.ajax({
      url: '/addons/search_coupon',
      type: 'GET',
      dataType: 'JSON',
      data: {
        keyword: query
      },
      success: function (resp) {
        callback(resp.coupons)
      },
      error: function () {
        callback()
      }
    })
  }

  var renderOption = function (item, escape) {
    return '<div>' +
      escape(item.id) +
    '</div>'
  }

  $el.selectize({
    load: onLoad,
    highlight: false,
    valueField: 'id',
    labelField: 'id',
    searchField: ['id'],
    options: [],
    create: false,
    preload: 'focus',
    placeholder: 'Search coupon here..',
    render: {
      option: renderOption
    }
  })

  var coupon = $el.data('coupon')
  if (coupon) {
    $el[0].selectize.addOption({ id: coupon })
    $el[0].selectize.setValue(coupon)
  }
})
