<script setup>
import { ref, watch } from 'vue'

const keyword = ref('')

const emit = defineEmits(['handleSearch'])

watch(keyword, (newKeyword) => {
  // If the keyword is empty, emit the event to reset the search results.
  if (newKeyword === '') {
    emit('handleSearch', newKeyword)
  }
})

function search() {
  emit('handleSearch', keyword.value)
}
</script>

<template>
  <div class="form-group">
    <h5 class="search-header">Search Products</h5>
    <div class="input-group">
      <input
        class="form-control mr-2 mb-2"
        v-model="keyword"
        type="text"
        placeholder="Search by product name, sku ..."
      />
      <button class="btn btn-primary mr-2 mb-2" @click.prevent="search">
        <span class="ri-search-line"></span> Search
      </button>
    </div>
  </div>
</template>

<style scoped></style>
