$.onmount('[data-component=ecms-delivery-duty]', function () {
  var $el = $(this)

	$el.on('change', function() {
    var $selectedOption = $(this).find('option:selected');
    var shippingMethodName = $selectedOption.text();
    var $associatedField = $(this).closest('.form-group').find('.ecms-delivery-duty');

    if (shippingMethodName === "ECMS") {
      $associatedField.removeClass("d-none");
      $associatedField.prop("disabled", false);
    } else {
      $associatedField.addClass("d-none");
      $associatedField.prop("disabled", true);
    }
  });
})
