import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'planTypeOption',
    'shippingMethodSelector',
    'shippingMethodOption',
    'countrySelector',
    'countryOption',
    'confirmation',
    'actualCountries'
  ]

  selectedCountryTargets = []

  selectPlanType({ currentTarget, params: { planType, orderType } }) {
    this._highlightActiveItem(this.planTypeOptionTargets, currentTarget)

    // Hide all shipping method options initially
    this.shippingMethodSelectorTargets.forEach((target) => target.classList.add('d-none'))
    // Show only available shipping methods for selected plan type
    this.shippingMethodSelectorTargets
      .find(
        (target) => target.dataset.planType === planType && target.dataset.orderType === orderType
      )
      .classList.remove('d-none')

    // Set plan type and order type hidden input values
    this.element.querySelector('[name=plan_type]').value = planType
    this.element.querySelector('[name=order_type]').value = orderType

    // Clear selected countries when changing plan type
    if (this.selectedCountryTargets.length > 0) {
      this._resetActiveCountryItems()
    }
    // Hide country selector when changing plan type
    if (!this.countrySelectorTarget.classList.contains('d-none')) {
      this.countrySelectorTarget.classList.add('d-none')
    }
  }

  selectShippingMethod({
    currentTarget,
    params: { shippingMethodId, actualCountries, ordersByCountry }
  }) {
    this._highlightActiveItem(this.shippingMethodOptionTargets, currentTarget)

    // Clear selected countries when changing shipping method
    this._resetActiveCountryItems()

    // Show country selector
    this.countrySelectorTarget.classList.remove('d-none')
    // Hide all country options initially
    this.countryOptionTargets.forEach((target) => target.classList.add('d-none'))
    // Convert actual countries string param to array and trim white spaces
    const actualCountriesArray = actualCountries.split(',').map((country) => country.trim())

    // Show only available country options for selected shipping method that are included in actual countries
    this.countryOptionTargets.find((target) => {
      const targetCountryCode = target.dataset.orderPrintOptionsCountryCodeParam

      if (actualCountriesArray.includes(targetCountryCode)) {
        // Set orders count for each country
        target.querySelector(
          '[data-order-print-options-target=countryOptionOrdersCount]'
        ).innerText = ordersByCountry[targetCountryCode]
        // Show country option
        target.classList.remove('d-none')
      }
    })

    // Set shipping method hidden input value to selected shipping method id
    this.element.querySelector('[name=shipping_method_id]').value = shippingMethodId

    // Select all countries by default
    this.selectAllCountries({ preventDefault: () => {} })
  }

  selectCountry({ currentTarget }) {
    // Toggle active / selected country options
    if (this.selectedCountryTargets.includes(currentTarget)) {
      this.selectedCountryTargets = this.selectedCountryTargets.filter(
        (target) => target !== currentTarget
      )
    } else {
      this.selectedCountryTargets.push(currentTarget)
    }

    this._highlightMultipleActiveItems(this.countryOptionTargets, this.selectedCountryTargets)

    // Create string of actual country codes from selected country options
    const actualCountries = this.selectedCountryTargets.map(
      (target) => target.dataset.orderPrintOptionsCountryCodeParam
    )

    // Set actual countries text to display in confirmation panel
    this.actualCountriesTarget.innerText = actualCountries.join(', ')

    // Set included countries input field value to actual countries / selected country options
    this.element.querySelector('[name=included_countries]').value = actualCountries

    this.confirmationTarget.classList.remove('d-none')
  }

  selectAllCountries(event) {
    event.preventDefault()
    // Set selected country options to all country options that are available for selected shipping method
    this.selectedCountryTargets = this.countryOptionTargets.filter(
      (target) => !target.classList.contains('d-none')
    )

    this._highlightMultipleActiveItems(this.countryOptionTargets, this.selectedCountryTargets)

    // Create string of actual country codes from selected country options
    const actualCountries = this.selectedCountryTargets.map(
      (target) => target.dataset.orderPrintOptionsCountryCodeParam
    )

    // Set actual countries text to display in confirmation panel
    this.actualCountriesTarget.innerText = actualCountries.join(', ')

    // Set included countries input field value to actual countries / selected country options
    this.element.querySelector('[name=included_countries]').value = actualCountries

    this.confirmationTarget.classList.remove('d-none')
  }

  _highlightMultipleActiveItems(collection, items) {
    collection.forEach((target) => {
      if (items.includes(target)) {
        target.style.boxShadow = 'inset 0 0 0 999px var(--dark)'
        target.style.color = 'white'
      } else {
        target.style.removeProperty('box-shadow')
        target.style.removeProperty('color')
      }
    })
  }

  _highlightActiveItem(collection, item) {
    collection.forEach((target) => {
      target.style.removeProperty('box-shadow')
      target.style.removeProperty('color')
    })
    item.style.boxShadow = 'inset 0 0 0 999px var(--dark)'
    item.style.color = 'white'
  }

  _resetActiveCountryItems() {
    // Clear selected countries
    this.selectedCountryTargets = []
    // Clear actual countries text
    this.actualCountriesTarget.innerText = ''
    // Clear included countries input field value
    this.element.querySelector('[name=included_countries]').value = ''
    // Clear active country options
    this._highlightMultipleActiveItems(this.countryOptionTargets, this.selectedCountryTargets)
    // Hide confirmation panel
    this.confirmationTarget.classList.add('d-none')
  }
}
