$.onmount('[data-toggle=datetimepicker]', function () {
  var $el = $(this)

  var input = $el.is('input') ? $el.get(0) : $el.find('input').get(0)

  var picker = flatpickr(input, {
    format: 'Y/m/d H:i',
    enableTime: true,
    enableSeconds: false,
    time_24hr: true,
    defaultHour: 0,
    defaultMinute: 0,
    minDate: this.dataset.minDate
  }, $el.data('options') || {})

  $el.find('[data-action=open]').on('click', function () {
    $el.find('input').focus()
  })

  $el.find('[data-action=clear]').on('click', function() {
    picker.clear()
  })
})

$.onmount('[data-toggle=datepicker]', function () {
  var $el = $(this)

  var input = $el.is('input') ? $el.get(0) : $el.find('input').get(0)

  flatpickr(input, { format: 'Y/m/d', ...$el.data('options') })
})

