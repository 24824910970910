$.onmount("[data-component='coupon-forms/select-discount-type']", function() {
  var $el = $(this)

  function selectDiscountType() {
    var discountType = $el.val()

    $("#discount-amount").toggleClass("d-none", discountType !== "fixed_amount")
    $("#discount-percentage").toggleClass(
      "d-none",
      discountType !== "percentage"
    )
  }

  $el.on("change", selectDiscountType)
})
